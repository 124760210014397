import useNavigateInApp from "@/hooks/useNavigateInApp";
import { useContext, useEffect } from "react";

import userContext from "@/contexts/userContext";
import { hasPermission } from "@/auth/utils";
import { PermissionKey } from "@/domains/user/types/Permission";

function PageAdmin() {
  const { navigateTo } = useNavigateInApp();
  const { permissions, isLoading: isUserLoading } = useContext(userContext);

  useEffect(
    function redirectToSpecificPage() {
      if (isUserLoading) return;

      if (
        hasPermission(permissions, [PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL])
      ) {
        navigateTo(`/admin/user/organizations`);
      } else if (hasPermission(permissions, [PermissionKey.PELOTE_ADMINISTRATION])) {
        navigateTo(`/admin/pelote/cesta-punta/competitions`);
      } else {
        console.error(
          "User does not have the required permissions to access any of the admin pages",
        );
        navigateTo("/unauthorized");
      }
    },
    [isUserLoading],
  );

  return null;
}

export default PageAdmin;
