import { SetsPatterns } from "../types/Competition";

export default function determineWinner(
  scoreTeam1Splited: number[],
  scoreTeam2Splited: number[],
  setsPattern: SetsPatterns,
) {
  let isWinnerTeam1: boolean = false;
  let isWinnerTeam2: boolean = false;
  if (setsPattern == SetsPatterns.forty) {
    if (scoreTeam1Splited[0] == 40) {
      isWinnerTeam1 = true;
    } else if (scoreTeam2Splited[0] == 40) {
      isWinnerTeam2 = true;
    }
  } else {
    let sumTeam1 = 0;
    let sumTeam2 = 0;
    for (let i = 0; i < scoreTeam1Splited.length; i++) {
      sumTeam1 += scoreTeam1Splited[i];
    }
    for (let i = 0; i < scoreTeam2Splited.length; i++) {
      sumTeam2 += scoreTeam2Splited[i];
    }
    // The game have not been played yet, so we let the winner as TBD
    if (sumTeam1 === 0 && sumTeam2 === 0) {
      return { isWinnerTeam1, isWinnerTeam2 };
    }

    if (
      scoreTeam1Splited[2] === 0 &&
      scoreTeam2Splited[2] === 0 &&
      (sumTeam1 === 30 || sumTeam2 === 30)
    ) {
      // Sum of the 2 first sets in order to know the winner
      if (sumTeam1 > sumTeam2) {
        isWinnerTeam1 = true;
      } else {
        isWinnerTeam2 = true;
      }
    } else {
      if (scoreTeam1Splited[2] > scoreTeam2Splited[2]) {
        isWinnerTeam1 = true;
      } else {
        isWinnerTeam2 = true;
      }
    }
  }
  return { isWinnerTeam1, isWinnerTeam2 };
}
