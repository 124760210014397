import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { SelectOption } from "@/ui-lib/select/Select";
import PoolsApi from "@pelote/api/PoolsApi";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedPools from "@pelote/hooks/usePaginatedPools";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Pool } from "@pelote/types/Pool";

function usePageAdminPoolList(): {
  sportPath: string | undefined;
  isLoading: boolean;
  seasonSelectOptions: SelectOption[];
  competitionSelectOptions: SelectOption[];
  paginatedPools: PaginatedModel<Pool> | undefined;
  handleDeletePool: (seasonId: string, competitionId: string, id: string) => void;
  handleChangeSeasonOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeCompetitionOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  const [selectedSeasonOption, setSelectedSeasonOption] = useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] = useState<SelectOption | null>(
    null,
  );

  // Fetch a list of pools
  const { paginatedPools } = usePaginatedPools({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page: 1,
    pageSize: 10,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } = usePaginatedCompetitions({
    page: 1,
    pageSize: 10,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );

  // Handle the deletion of a pool
  const queryClient = useQueryClient();
  const { mutate: deletePool } = useMutation({
    mutationFn: ({
      seasonId,
      competitionId,
      id,
    }: {
      seasonId: string;
      competitionId: string;
      id: string;
    }) => PoolsApi.delete(seasonId, competitionId, id),
    onSuccess: (_, { seasonId, competitionId, id }) => {
      queryClient.removeQueries({ queryKey: ["showPool", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexPools", seasonId, competitionId, 1, 10],
      });

      toast.success(t("admin.pelote.pool.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeletePool(seasonId: string, competitionId: string, id: string) {
    if (confirm(t("admin.pelote.pool.confirmDeletion"))) {
      deletePool({ seasonId, competitionId, id });
    }
  }

  const handleChangeSeasonOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };
  const handleChangeCompetitionOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };
  // Build the global isLoading flag
  const isLoading = isUserLoading || isSeasonsLoading || isCompetitionsLoading;

  useEffect(() => {
    const initialSeasonOption = seasonSelectOptions[0] || null;
    setSelectedSeasonOption(initialSeasonOption);
    const initialCompetitionOption = competitionSelectOptions[0] || null;
    setSelectedCompetitionOption(initialCompetitionOption);
  }, [isLoading]);

  return {
    sportPath,
    isLoading,
    seasonSelectOptions,
    competitionSelectOptions,
    paginatedPools,
    handleDeletePool,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}
export default usePageAdminPoolList;
