import GamesApi, { CreateOrEditGameActionInput } from "@pelote/api/GamesApi";
import { APIError } from "@pelote/types/ApiError";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { addAction } from "@pelote/pages/cesta-punta/user/codage/redux/features/actionsReducer";
import { setPreviousAction } from "@pelote/pages/cesta-punta/user/codage/redux/features/previousActionReducer";

function useCreateAction(): {
  handleCreateAction: (createActionGameInputs: CreateOrEditGameActionInput) => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    mutate: createActionGame,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newActionGame: CreateOrEditGameActionInput) =>
      GamesApi.createAction(newActionGame.game_id, newActionGame),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getActions", data.game_id] });
      dispatch(setPreviousAction({ action: data }));
      dispatch(addAction({ action: data }));
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error("Une erreur est survenue lors de la création.", {
        autoClose: 3000,
      });
    },
  });

  function handleCreateAction(createActionGameInputs: CreateOrEditGameActionInput) {
    if (createActionGameInputs.game_id === undefined) {
      return;
    }
    createActionGame(createActionGameInputs);
  }

  return {
    handleCreateAction: handleCreateAction,
    isCreationPending,
    isCreationSuccess,
  };
}

export default useCreateAction;
