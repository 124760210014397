import { Permission, PermissionKey } from "@user/types/Permission";
import { Sport, SportKey } from "@user/types/Sport";

/**
 * hasPermission checks if the user has at least one of the permissions
 *
 * @param userPermissions Permission[]
 * @param permissionKeys PermissionKey[]
 * @returns boolean
 *
 *
 **/
function hasPermission(userPermissions: Permission[], permissionKeys: PermissionKey[]) {
  return permissionKeys.some((permissionKey) =>
    userPermissions.some((userPermission) => userPermission.key === permissionKey),
  );
}

/**
 * hasSport checks if the user has the sport
 * @param userSports Sport[]
 * @param sportKey SportKey
 * @returns boolean
 */
function hasSport(userSports: Sport[], sportKey: SportKey) {
  return userSports.some((sport: Sport) => sport.key === sportKey);
}

export { hasPermission, hasSport };
