import "./adminTabs.scss";

export interface AdminTabButton {
  label: string;
  isActive: boolean;
  onClick?: () => void;
}

export interface AdminTabsProps {
  /**
   * The buttons to display
   */
  buttons: AdminTabButton[];
}

export const AdminTabs = ({ buttons }: AdminTabsProps) => {
  return (
    <div className={"admin-tab"}>
      {buttons.map((AdminTabButton, index) => (
        <button
          className={`button ${AdminTabButton.isActive && "active"}`}
          key={index}
          onClick={AdminTabButton.onClick}
          title={AdminTabButton.label}
        >
          {AdminTabButton.label.substring(0, 30)}
          {AdminTabButton.label.length > 30 && "..."}
        </button>
      ))}
    </div>
  );
};
