import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "@pelote/pages/cesta-punta/user/codage/redux/hooks";
import { useParams } from "react-router-dom";

import useGame from "@pelote/hooks/useGame";
import { VideoPlayer } from "@pelote/components/interface-codage/VideoPlayer/VideoPlayer";
import { Timeline } from "@pelote/components/interface-codage/Timeline/Timeline";
import { CodagePanel } from "@pelote/components/interface-codage/CodagePanel/CodagePanel";
import { FieldXY } from "@pelote/components/interface-codage/FieldXY/FieldXY";
import { CancelValidateAction } from "@pelote/components/interface-codage/CancelValidateActions/CancelValidateActions";
import {
  setIsCurrentTimeMounted,
  togglePlayPause,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/videoPlayerReducer";
import { getActions } from "@pelote/pages/cesta-punta/user/codage/redux/features/actionsReducer";
import { resetInitalScores } from "@pelote/pages/cesta-punta/user/codage/redux/features/scoreReducer";
import {
  setGame,
  setVideos,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/gameDatasReducer";
import { resetInitalStateCurrentAction } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import useGameEdit from "@pelote/hooks/useGameEdit";
import usePaginatedActions from "@pelote/hooks/usePaginatedActions";
import Loader from "@pelote/components/loader/Loader";
import { CodingStatus } from "@pelote/types/Game";
import NoElement from "@pelote/components/no-element/noElement";
import usePaginatedVideos from "@pelote/hooks/usePaginatedVideos";

import "./PageCodage.scss";

function PageCodage() {
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  // State to initialize the coding status of the game, if the status game has been changed
  // We will not reset the state of the current action and the automatization og the first action can work
  const [isCodingStatus, setIsCodingStatus] = useState<boolean>(false);

  const { gameId } = useParams();
  const { game, isLoading: isGameLoading } = useGame(gameId);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Perform actions before the component unloads
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Set the game and reset the score and the current action in the store when the game is fetched
  useEffect(() => {
    if (!game) return;
    dispatch(setGame({ game: game }));
    dispatch(resetInitalScores());
    if (isCodingStatus === false) {
      dispatch(resetInitalStateCurrentAction());
    }
    setIsLoading(false);
  }, [game]);

  // Get the video of the game, independent of the game in order to display the video or not
  const { paginatedVideos, isLoadingVideos } = usePaginatedVideos({ gameId });

  if (paginatedVideos) {
    if (paginatedVideos.items.length === 0) {
      dispatch(setVideos({ uriVideo: [] }));
      dispatch(setIsCurrentTimeMounted({ isMounted: false }));
    } else {
      dispatch(setIsCurrentTimeMounted({ isMounted: true }));
      dispatch(setVideos({ uriVideo: paginatedVideos.items }));
    }
  }

  const { handleEditStatusGame } = useGameEdit(gameId as string);

  const { paginatedActions, isLoadingPaginatedActions } = usePaginatedActions(gameId);
  if (paginatedActions) {
    dispatch(getActions({ actions: paginatedActions.items }));
  }

  // Handle Keyboard Shortcuts
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const player = videoPlayerRef.current;
      const currentTime = player?.getCurrentTime();
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        if (player && currentTime) {
          player.seekTo(currentTime - 5);
        }
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        if (player && currentTime) {
          player.seekTo(currentTime + 5);
        }
      } else if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
        if (player && currentTime) {
          dispatch(togglePlayPause());
        }
      } else if (event.key === "Enter" || event.key === "Return") {
        event.preventDefault();
        const elementToClick = document.getElementById("validate-action-button");
        if (elementToClick) {
          elementToClick.click();
        }
      } else if (event.key === "Escape") {
        event.preventDefault();
        const elementToClick = document.getElementById("cancel-action-button");
        if (elementToClick) {
          elementToClick.click();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // Update game status when game and paginatedActions change
  // If the game's coding status is 'todo' and there are actions to paginate, it changes the coding status to 'in_progress'.
  // If the coding status is 'in_progress' and there are no actions to paginate, it changes the coding status to 'todo'.
  useEffect(() => {
    if (
      game &&
      paginatedActions &&
      game.coding_status === CodingStatus.todo &&
      paginatedActions?.items.length > 0
    ) {
      const updatedGame = {
        coding_status: CodingStatus.in_progress,
      };
      setIsCodingStatus(true);
      handleEditStatusGame(updatedGame);
    } else if (
      game &&
      paginatedActions &&
      game.coding_status === CodingStatus.in_progress &&
      paginatedActions?.items.length === 0
    ) {
      const updatedGame = {
        coding_status: CodingStatus.todo,
      };
      setIsCodingStatus(true);
      handleEditStatusGame(updatedGame);
    }
  }, [game, paginatedActions]);

  if (isLoading || isGameLoading || isLoadingPaginatedActions || isLoadingVideos) {
    return <Loader />;
  }

  return (
    <>
      <div className="parent-interface">
        <div className="videoplayer-div">
          {paginatedVideos && paginatedVideos?.items.length > 0 ? (
            <VideoPlayer videoPlayerRef={videoPlayerRef} />
          ) : (
            <NoElement message="noVideo" />
          )}
        </div>
        <div className="timeline-div">
          <Timeline videoPlayerRef={videoPlayerRef} />
        </div>
        <div className="codagepanel-div">
          <CodagePanel videoPlayerRef={videoPlayerRef} />
        </div>
        <div className="field-div">
          <FieldXY />
          <CancelValidateAction />
        </div>
      </div>
    </>
  );
}

export default PageCodage;
