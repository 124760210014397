import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Round } from "@pelote/types/Round";
import { getDefaultHeaders } from "./utils";

export interface CreateRoundInputs {
  name: string;
  display_order: number;
  sport_id: string; // UUID v4
  season_id: string; // UUID v4
  competition_id: string; // UUID v4
  stage_id?: string; // UUID v4
  pool_id?: string; // UUID v4
}

export interface UpdateRoundInputs {
  name?: string;
  display_order?: number;
}

export default class RoundsApi {
  static ressource: string = "rounds";

  static async create(createRoundInputs: CreateRoundInputs): Promise<Round> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/seasons/${createRoundInputs.season_id}/competitions/${createRoundInputs.competition_id}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createRoundInputs,
    });
    return response.data;
  }

  static async index(
    season_id: string,
    competition_id: string,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<PaginatedModel<Round>> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(season_id: string, competition_id: string, round_id: string): Promise<Round> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${round_id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(
    season_id: string,
    competition_id: string,
    round_id: string,
    updateRoundInputs: UpdateRoundInputs,
  ): Promise<Round> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${round_id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateRoundInputs,
    });
    return response.data;
  }

  static async delete(season_id: string, competition_id: string, round_id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${round_id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
