import { Action } from "../types/Action";

export function focusOnAction(
  trIndex: number,
  action: Action,
  previousActionToFocusOn: Action | null,
) {
  // When there is no previousAction in reducer, it means that the page has been reloaded
  if (previousActionToFocusOn === null) {
    // When page has been reloaded, the user get the last action on the first line of the timeline
    setTimeout(() => {
      const trElements = document.getElementsByTagName("tr");
      const tdLastAction = trElements[1];
      const tdToFind = tdLastAction.getElementsByTagName("td");
      // this is column with the actions, column with index 1 because it is placed 2 (must be improved when responsive will be active)
      const tdWithActions = tdToFind[1];
      const divButtons = tdWithActions.getElementsByClassName("buttons-timeline");
      // Get the index of the last child
      const lastChildIndex = divButtons[0].childNodes.length - 1;
      const lastChild = divButtons[0].childNodes[lastChildIndex] as HTMLElement;
      lastChild.scrollIntoView({ behavior: "auto", block: "nearest" });
    }, 500);
  } else {
    setTimeout(() => {
      if (trIndex >= 0 && action.id) {
        const trElements = document.getElementsByTagName("tr");
        const tdLastAction = trElements[trIndex + 1];
        const tdToFind = tdLastAction.getElementsByTagName("td");
        // this is column with the actions, column with index 1 because it is placed 2 (must be improved when responsive will be active)
        const tdWithActions = tdToFind[1];
        const divButtons = tdWithActions.getElementsByClassName("buttons-timeline");
        // We are looking for the childNode that have the samed id as the previous action
        divButtons[0].childNodes.forEach((childNode: any) => {
          if (childNode.id === action.id + "-button") {
            childNode.scrollIntoView({ behavior: "auto", block: "nearest" });
          }
        });
      }
    }, 300);
  }
}
