import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiTokenOperationToTokenOperation,
  getDefaultHeaders,
} from "./utils";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { TokenOperation } from "@user/types/TokenOperation";
import { APIPaginatedModel, APITokenOperation } from "./apiTypes";

export interface IndexApiTokenOperationsOptions {
  organizationId?: string; // UUID v4
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export default class TokenOperationsApi {
  static domain: string = "user";

  static async index(
    options: IndexApiTokenOperationsOptions,
  ): Promise<PaginatedModel<TokenOperation>> {
    const { organizationId, page = 1, pageSize = 10, sortBy = "created_at:desc" } = options;

    const defaultHeaders = await getDefaultHeaders();

    const url = new URL(`${multisportsApiUrl}/${this.domain}/token-operations`);
    // Add query params
    const queryParams = new URLSearchParams();
    if (organizationId) queryParams.append("organization_id", organizationId);
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (sortBy) queryParams.append("sort_by", sortBy);

    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedTokenOperations: APIPaginatedModel<APITokenOperation> = response.data;

    const paginatedTokenOperations: PaginatedModel<TokenOperation> = {
      items: apiPaginatedTokenOperations.items.map((apiTokenOperation) =>
        castApiTokenOperationToTokenOperation(apiTokenOperation),
      ),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedTokenOperations.pagination),
    };

    return paginatedTokenOperations;
  }
}
