import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateCompetitionInputs } from "@pelote/api/CompetitionsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminCompetitionCreate from "./usePageAdminCompetitionCreate";
import { SetsPatterns } from "@pelote/types/Competition";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "../competition/PageAdminCompetitionCreate.scss";

const PageAdminCompetitionCreate = () => {
  const { sportPath, handleCreateCompetition, isCreationSuccess, isLoading } =
    usePageAdminCompetitionCreate();

  const { t } = useTranslation();

  // Navigate to the list of competitions when the creation is successful
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/competitions`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateCompetitionInputs>({
    defaultValues: {
      name: "",
      description: "",
      sets_pattern: SetsPatterns.ten,
      logo: undefined,
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.pelote.competition.creation")} />
      <form onSubmit={handleSubmit(handleCreateCompetition)}>
        <div className="create-competition-form">
          <div className="create-competition-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="create-competition-description">
            <FormInput
              id="description"
              labelName={t("description")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="create-competition-logo_url">
            <p className="logo-title">{t("admin.pelote.competition.logoURLUpload")}</p>
            <input type="file" id="photo" accept="image/*" {...register("logo")} />
          </div>
          <div className="create-competition-FormSelect-sets_pattern">
            <FormSelect
              id="sets_pattern"
              labelName={t("admin.setsPattern")}
              options={Object.values(SetsPatterns).map((pattern) => ({
                value: pattern,
                label: pattern,
              }))}
              register={register}
            />
          </div>
        </div>
        <div className="create-competition-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/competitions`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminCompetitionCreate;
