import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdDeleteForever } from "react-icons/md";

import { UpdatePlayerInputs } from "@pelote/api/PlayersApi";
import { PlayerPosition } from "@pelote/types/Player";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminPlayerEdit from "./usePageAdminPlayerEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminPlayerEdit.scss";

const PageAdminPlayerEdit = () => {
  const {
    player,
    handleEditPlayer,
    handleDeletePhoto,
    isLoading,
    lateralitySelectOptions,
    genderSelectOptions,
    nationalitySelectOptions,
  } = usePageAdminPlayerEdit();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdatePlayerInputs>({
    defaultValues: {
      lastname: "",
      firstname: "",
      nationality: "",
      size: 0,
      weight: 0,
      favorite_position: PlayerPosition.front,
      laterality: "",
      gender: "",
      photo: undefined,
    },
  });

  useEffect(() => {
    setValue("lastname", player?.lastname || "");
    setValue("firstname", player?.firstname || "");
    setValue("birthdate", player?.birthdate || "");
    setValue("nationality", player?.nationality || "");
    setValue("size", player?.size || 0);
    setValue("weight", player?.weight || 0);
    setValue("favorite_position", player?.favorite_position || PlayerPosition.front);
    setValue("laterality", player?.laterality || "");
    setValue("gender", player?.gender || "");
  }, [player]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("player")} ${player?.lastname} ${player?.firstname}`} />
      <form onSubmit={handleSubmit(handleEditPlayer)}>
        <div className="edit-player-form-container">
          <div className="lastname">
            <FormInput
              id="lastname"
              labelName={t("last_name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.lastname?.message}
              register={register}
            />
          </div>
          <div className="firstname">
            <FormInput
              id="firstname"
              labelName={t("firstname")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.firstname")}
              errorMessage={errors.firstname?.message}
              register={register}
            />
          </div>
          <div className="nationality">
            <FormSelect
              id="nationality"
              labelName={t("nationality")}
              options={nationalitySelectOptions}
              register={register}
            />
          </div>
          <div className="gender">
            <FormSelect
              id="gender"
              labelName={t("gender")}
              options={genderSelectOptions}
              register={register}
            />
          </div>
          <div className="favorite_position">
            <FormSelect
              id="favorite_position"
              labelName={t("position")}
              options={Object.values(PlayerPosition).map((position) => ({
                value: position,
                label: position,
              }))}
              register={register}
            />
          </div>
          <div className="laterality">
            <FormSelect
              id="laterality"
              labelName={t("laterality")}
              options={lateralitySelectOptions}
              register={register}
            />
          </div>
          <div className="birthdate">
            <FormInput
              id="birthdate"
              labelName={t("birthdate")}
              type={InputType.date}
              register={register}
              registerValueAsDate
            />
          </div>
          <div className="size">
            <FormInput id="size" labelName={t("size")} type={InputType.text} register={register} />
          </div>
          <div className="weight">
            <FormInput
              id="weight"
              labelName={t("weight")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="photo">
            <input type="file" id="photo" accept="image/*" {...register("photo")} />
          </div>
          {player?.photo_url && (
            <>
              <img className="photo-preview" src={player?.photo_url || ""} />
              <div className="photo-preview">
                <button
                  type="button"
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    handleDeletePhoto(player?.id || "", event!);
                  }}
                >
                  <MdDeleteForever size={25} />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="edit-player-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/players`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminPlayerEdit;
