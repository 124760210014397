import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import userContext from "@/contexts/userContext";
import VideosApi, { CreateVideoInputs } from "@pelote/api/VideosApi";
import { APIError } from "@pelote/types/ApiError";
import { Video, VideoProcessingStatus } from "@/domains/pelote/types/Video";
import { UploadStatus, uploadVideo } from "@/utils/videoUploadTus";
import { CreateVideoFormInputs } from "./PageAdminGameCreateVideo.";

function usePageAdminGameCreateVideo(): {
  sportPath: string | undefined;
  handleCreateVideo: (createVideoFormInputs: CreateVideoFormInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  uploadStatus: UploadStatus | undefined;
  isUploadSuccess: boolean;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  // Get the id match from the path
  const { id: gameId } = useParams();

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Handle the upload of a video
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>();
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);

  // Handle the creation of a video
  const queryClient = useQueryClient();
  const {
    mutateAsync: createVideo,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newVideo: CreateVideoInputs) => VideosApi.create(newVideo),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexVideos"] });
      toast.warning(t("admin.pelote.game.videoUploadingMessage"), {
        autoClose: false,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });

  // Remove the toast when the upload is successful
  useEffect(() => {
    if (isUploadSuccess) {
      toast.dismiss();
    }
  }, [isUploadSuccess]);

  async function handleCreateVideo(createVideoFormInputs: CreateVideoFormInputs) {
    if (
      currentSport === undefined ||
      gameId === undefined ||
      createVideoFormInputs.videoFiles === undefined
    )
      return;

    try {
      // Create the video in database
      const createVideoInputs: CreateVideoInputs = {
        name: createVideoFormInputs.name,
        processing_status: VideoProcessingStatus.empty,
        sport_id: currentSport.id,
        game_id: gameId,
      };
      const createdVideoInAPIResponse: Video = await createVideo(createVideoInputs);

      // Upload the video file
      const upload = await uploadVideo(
        createdVideoInAPIResponse.processing_id,
        createdVideoInAPIResponse.name,
        createVideoFormInputs.videoFiles[0],
        setUploadStatus,
        handleUploadSuccess,
      );
      upload.start();
    } catch (error) {
      console.error("Error on creation / upload of the video", error);
    }
  }

  function handleUploadSuccess(status?: UploadStatus): void {
    setUploadStatus(status);
    setIsUploadSuccess(true);
  }

  return {
    sportPath,
    handleCreateVideo,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
    uploadStatus,
    isUploadSuccess,
  };
}

export default usePageAdminGameCreateVideo;
