import "./pagination.scss";

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onClick: (page: number) => void;
};
/**
 * Pagination component (including the calculation of the number of pages)
 * @param totalItems - Total number of items to be paginated
 * @param itemsPerPage - Number of items per page
 * @param currentPage - Current page number
 * @param onClick - Function to handle page click
 * @returns Pagination component
 */

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onClick,
}) => {
  // Calculate the number of pages depending on the total number of items and the number of items per page
  const pageNumber = Math.ceil(totalItems / itemsPerPage);

  return (
    <ul className="pagination">
      <li onClick={() => currentPage !== 1 && onClick(currentPage - 1)}>
        <button>«</button>
      </li>
      {Array.from({ length: pageNumber }, (_, i) => (
        <li key={i} onClick={() => onClick(i + 1)}>
          <button className={`${i === currentPage - 1 ? "active" : ""}`}>{i + 1}</button>
        </li>
      ))}
      <li onClick={() => currentPage !== pageNumber && onClick(currentPage + 1)}>
        <button>»</button>
      </li>
    </ul>
  );
};

export default Pagination;
