import { useQuery } from "@tanstack/react-query";

import RoundsApi from "@pelote/api/RoundsApi";
import { Round } from "@pelote/types/Round";

function useRound(
  seasonId: string,
  competitionId: string,
  roundId: string,
): {
  round?: Round;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a round (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showRound", seasonId, competitionId, roundId],
    queryFn: () => {
      if (!seasonId || !competitionId || !roundId) return;
      return RoundsApi.show(seasonId, competitionId, roundId);
    },
  });

  return { round: data, isLoading, isError };
}

export default useRound;
