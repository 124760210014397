import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import SeasonsApi, { CreateSeasonInputs } from "@pelote/api/SeasonsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminSeasonCreate(): {
  sportPath: string | undefined;
  handleCreateSeason: (createSeasonInputs: CreateSeasonInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Handle the creation of a playground
  const queryClient = useQueryClient();
  const {
    mutate: createSeason,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newSeason: CreateSeasonInputs) => SeasonsApi.create(newSeason),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexSeasons"] });
      toast.success(t("admin.pelote.season.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreateSeason(createSeasonInputs: CreateSeasonInputs) {
    if (currentSport === undefined) return;
    createSeasonInputs.sport_id = currentSport.id;
    createSeason(createSeasonInputs);
  }

  return {
    sportPath,
    handleCreateSeason,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminSeasonCreate;
