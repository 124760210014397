import ReactDOM from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";

import { ThemeProvider } from "@/providers/ThemeProvider";
import QueryProvider, { QueryProviderDevTools } from "@/providers/QueryProvider";
import TranslationProvider from "@/providers/TranslationProvider";
import UserProvider from "@/providers/UserProvider";
import msalManager from "@/auth/msalManager";
import { App } from "@/App";
import smartlookManager from "@/utils/smartlook";
import sentryManager from "@/utils/sentry";

import "./main.scss";

// Init Sentry manager
sentryManager.init();

// Init smartlook manager
smartlookManager.init();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <QueryProvider>
    <MsalProvider instance={msalManager.getInstance()}>
      <UserProvider>
        <TranslationProvider>
          <ThemeProvider>
            <App />
            <QueryProviderDevTools />
          </ThemeProvider>
        </TranslationProvider>
      </UserProvider>
    </MsalProvider>
  </QueryProvider>,
);
