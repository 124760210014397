import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PlaygroundsApi, { CreatePlaygroundInputs } from "@pelote/api/PlaygroundsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminPlaygroundCreate(): {
  sportPath: string | undefined;
  handleCreatePlayground: (createPlaygroundInputs: CreatePlaygroundInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Handle the creation of a playground
  const queryClient = useQueryClient();
  const {
    mutate: createPlayground,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newPlayground: CreatePlaygroundInputs) => PlaygroundsApi.create(newPlayground),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexPlaygrounds"] });
      toast.success(t("admin.pelote.playground.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreatePlayground(createPlaygroundInputs: CreatePlaygroundInputs) {
    if (currentSport === undefined) return;
    createPlayground(createPlaygroundInputs);
  }

  return {
    sportPath,
    handleCreatePlayground,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminPlaygroundCreate;
