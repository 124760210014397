import "./modal.scss";

type ModalProps = {
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
};

const Modal = ({ title, onClose, children }: ModalProps) => {
  return (
    <div className="ui-lib-modal">
      <div className="close-button" onClick={onClose}>
        x
      </div>
      <div className="content">
        <div className="title">{title}</div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
