import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "@/providers/i18n";

// Hooks
import useMetricsGame from "@pelote/hooks/useMetricsGame";
import useGame from "@pelote/hooks/useGame";
import useSeason from "@pelote/hooks/useSeason";
import useCompetition from "@pelote/hooks/useCompetition";
import usePlayground from "@pelote/hooks/usePlayground";
import useRound from "@pelote/hooks/useRound";

// CSS
import "./PageGamePdf.scss";

// HTML to PDF
import generatePDF from "@/utils/htmlToPdfConverter";

// Models
import { MetricsGame } from "@pelote/types/MetricsGame";
import { MetricsGameTeam } from "@pelote/types/MetricsGameTeam";
import { MetricsGamePlayer } from "@pelote/types/MetricsGamePlayer";
import { MetricsGameActionsList } from "@pelote/types/MetricsGameActionsList";

// Components
import GamePdfGeneral from "@pelote/components/GamePdf/GamePdfGeneral";
import GamePdfReceptions from "@pelote/components/GamePdf/GamePdfReceptions";
import GamePdfShots from "@pelote/components/GamePdf/GamePdfShots";
import GamePdfPlayer from "@pelote/components/GamePdf/GamePdfPlayer";

const PageGamePdf = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const { gameId } = useParams();
  const {
    metricsGameDataResponse,
    metricsGameTeamDataResponse,
    metricsGamePlayerDataResponse,
    metricsGameActionsListDataResponse,
    isLoading,
  } = useMetricsGame({ gameId });
  const { game, isLoading: isGameLoading } = useGame(gameId || "");
  const { playground } = usePlayground(game?.playground.id || "");
  const { season } = useSeason(game?.season.id || "");
  const { competition } = useCompetition(game?.competition.id || "");
  const { round } = useRound(
    game?.season.id || "",
    game?.competition.id || "",
    game?.round.id || "",
  );

  const generalData = {
    season: season?.name,
    competition: competition?.name,
    competition_logo: competition?.logo_url,
    round: round?.name,
    playground: playground?.name,
  };

  let metricsGameData: MetricsGame | undefined;
  let metricsGameTeam1Data: MetricsGameTeam | undefined;
  let metricsGameTeam2Data: MetricsGameTeam | undefined;
  let metricsGameTeam1PlayerFrontData: MetricsGamePlayer | undefined;
  let metricsGameTeam1PlayerBackData: MetricsGamePlayer | undefined;
  let metricsGameTeam2PlayerFrontData: MetricsGamePlayer | undefined;
  let metricsGameTeam2PlayerBackData: MetricsGamePlayer | undefined;
  let metricsGameActionsListData: MetricsGameActionsList | undefined;

  if (
    metricsGameDataResponse &&
    metricsGameDataResponse.items &&
    metricsGameDataResponse.items.length > 0
  ) {
    metricsGameData = metricsGameDataResponse.items[0];
  }
  if (
    metricsGameTeamDataResponse &&
    metricsGameTeamDataResponse.items &&
    metricsGameTeamDataResponse.items.length > 0
  ) {
    metricsGameTeam1Data = metricsGameTeamDataResponse.items[0];
  }
  if (
    metricsGameTeamDataResponse &&
    metricsGameTeamDataResponse.items &&
    metricsGameTeamDataResponse.items.length > 1
  ) {
    metricsGameTeam2Data = metricsGameTeamDataResponse.items[1];
  }
  if (
    metricsGameActionsListDataResponse &&
    metricsGameActionsListDataResponse.items &&
    metricsGameActionsListDataResponse.items.length > 0
  ) {
    metricsGameActionsListData = metricsGameActionsListDataResponse.items[0];
  }
  if (
    metricsGamePlayerDataResponse &&
    metricsGamePlayerDataResponse.items &&
    metricsGamePlayerDataResponse.items.length > 3
  ) {
    for (let i = 0; i < metricsGamePlayerDataResponse.items.length; i++) {
      if (metricsGamePlayerDataResponse.items[i].team_number === 1) {
        if (metricsGamePlayerDataResponse.items[i].position === "front") {
          metricsGameTeam1PlayerFrontData = metricsGamePlayerDataResponse.items[i];
        } else {
          metricsGameTeam1PlayerBackData = metricsGamePlayerDataResponse.items[i];
        }
      } else {
        if (metricsGamePlayerDataResponse.items[i].position === "front") {
          metricsGameTeam2PlayerFrontData = metricsGamePlayerDataResponse.items[i];
        } else {
          metricsGameTeam2PlayerBackData = metricsGamePlayerDataResponse.items[i];
        }
      }
    }
  }

  // Keep only the day/month/year of the start_time (or month/day/year if the locale is en-US)
  let gameDate = "";
  if (game && game.start_time) {
    const date = new Date(game.start_time);
    const day: string = date.getDate().toString();
    const monthNumber: number = date.getMonth();
    let month: string = "";
    if (monthNumber < 9) {
      month = `0${monthNumber + 1}`;
    } else {
      month = (monthNumber + 1).toString();
    }
    const year: string = date.getFullYear().toString();

    if (game && game.start_time && i18n.language === "en") {
      gameDate = `${year}${day}${month}`;
    } else if (game && game.start_time) {
      gameDate = `${year}${month}${day}`;
    }
  }
  let reportTitle = `${t("pelote.cesta-punta.game-pdf.game-report")}`;

  useEffect(() => {
    reportTitle = `${t("pelote.cesta-punta.game-pdf.game-report")}_${generalData.competition}_${generalData.round}_${metricsGameTeam1PlayerFrontData?.lastname}_${metricsGameTeam1PlayerBackData?.lastname}_${metricsGameTeam2PlayerFrontData?.lastname}_${metricsGameTeam1PlayerBackData?.lastname}_${gameDate}`;
    reportTitle = reportTitle.replace(/ /g, "_");
    reportTitle = reportTitle.replace("'", "");
  }, [
    generalData,
    metricsGameTeam1PlayerFrontData,
    metricsGameTeam1PlayerBackData,
    metricsGameTeam2PlayerFrontData,
    metricsGameTeam1PlayerBackData,
    gameDate,
  ]);

  useEffect(() => {
    if (isLoading) return;
    if (isGameLoading) return;

    setTimeout(async () => {
      await generatePDF(reportTitle);
      setLoading(false);
    }, 2000);
  }, [isLoading, isGameLoading]);

  return (
    <div>
      <div className="overlay">
        <div style={{ marginTop: 40, textAlign: "center", zIndex: 2 }}>
          {loading ? (
            <Fragment>
              <div className="small-loader"></div>
              <p>{t("downloadingPDF")}</p>
            </Fragment>
          ) : (
            <Fragment>
              <p>{t("downloadOK")}</p>
              <button type="button" onClick={() => navigate(-1)}>
                {t("common.back-to-previous-page")}
              </button>
            </Fragment>
          )}
        </div>
      </div>
      <GamePdfGeneral
        generalData={generalData}
        game={game}
        metricsGameData={metricsGameData}
        metricsGameTeam1Data={metricsGameTeam1Data}
        metricsGameTeam2Data={metricsGameTeam2Data}
        metricsGameActionsListData={metricsGameActionsListData}
      />
      <GamePdfReceptions
        game={game}
        playground={playground}
        metricsGameData={metricsGameData}
        metricsGameTeam1Data={metricsGameTeam1Data}
        metricsGameTeam2Data={metricsGameTeam2Data}
        metricsGameActionsListData={metricsGameActionsListData}
      />
      <GamePdfShots
        game={game}
        playground={playground}
        metricsGameData={metricsGameData}
        metricsGameTeam1Data={metricsGameTeam1Data}
        metricsGameTeam2Data={metricsGameTeam2Data}
        metricsGameActionsListData={metricsGameActionsListData}
      />
      <GamePdfPlayer
        type="front"
        game={game}
        playground={playground}
        metricsGamePlayer={metricsGameTeam1PlayerFrontData}
        metricsGameActionsListData={metricsGameActionsListData}
        pageNumber={5}
      />
      <GamePdfPlayer
        type="back"
        game={game}
        playground={playground}
        metricsGamePlayer={metricsGameTeam1PlayerBackData}
        metricsGameActionsListData={metricsGameActionsListData}
        pageNumber={6}
      />
      <GamePdfPlayer
        type="front"
        game={game}
        playground={playground}
        metricsGamePlayer={metricsGameTeam2PlayerFrontData}
        metricsGameActionsListData={metricsGameActionsListData}
        pageNumber={7}
      />
      <GamePdfPlayer
        type="back"
        game={game}
        playground={playground}
        metricsGamePlayer={metricsGameTeam2PlayerBackData}
        metricsGameActionsListData={metricsGameActionsListData}
        pageNumber={8}
      />
    </div>
  );
};

export default PageGamePdf;
