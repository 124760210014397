import { Label } from "@pelote/types/Label";

export function getFilteredLabels(
  serviceLabels: Label[],
  receptionLabels: Label[],
  shotLabels: Label[],
) {
  // SERVICE LABELS
  // Sort options type
  let serviceLabelsOrdered: Label[] = [];
  // Sort Labels to get "wall_zone" first
  serviceLabelsOrdered = serviceLabels.sort((a, b) => {
    if (a.name === "wall_zone") return -1;
    if (b.name === "wall_zone") return 1;
    return 0;
  });

  // Sort options
  const serviceOptionsOrder = ["avant", "arriere", "pasa", "falta", "ace"];
  serviceLabelsOrdered = serviceLabelsOrdered.map((label) => {
    if (label.name === "type_service") {
      label.options = label.options.sort((a, b) => {
        return serviceOptionsOrder.indexOf(a.name) - serviceOptionsOrder.indexOf(b.name);
      });
    }
    return label;
  });

  // Reception positive and shot Labels
  const receptionPositiveShotLabels: Label[] = [];
  // Sort Labels [side, type_reception, type_shot, wall_zone, result_shot]
  receptionLabels.forEach((label) => {
    if (label.name === "side") {
      receptionPositiveShotLabels.push(label);
    }
  });
  receptionLabels.forEach((label) => {
    if (label.name === "type_reception") {
      receptionPositiveShotLabels.push(label);
    }
  });
  shotLabels.forEach((label) => {
    if (label.name === "type_shot") {
      receptionPositiveShotLabels.push(label);
    }
  });
  shotLabels.forEach((label) => {
    if (label.name === "wall_zone") {
      receptionPositiveShotLabels.push(label);
    }
  });
  shotLabels.forEach((label) => {
    if (label.name === "result_shot") {
      receptionPositiveShotLabels.push(label);
    }
  });

  // Reception negative labels
  const receptionNegativeLabels: Label[] = [];
  // Sort Labels [result, side], for the label result_reception i only want option that is Negative
  receptionLabels.forEach((label) => {
    if (label.name === "result_reception") {
      const newLabel: Label = {
        ...label,
        options: label.options.filter((option) => option.name === "negative"),
      };
      receptionNegativeLabels.push(newLabel);
    }
  });

  receptionLabels.forEach((label) => {
    if (label.name === "side") {
      receptionNegativeLabels.push(label);
    }
  });
  receptionLabels.forEach((label) => {
    if (label.name === "type_reception") {
      receptionNegativeLabels.push(label);
    }
  });

  return {
    serviceLabelsOrdered,
    receptionPositiveShotLabels,
    receptionNegativeLabels,
  };
}
