import { useQuery } from "@tanstack/react-query";
import GamesApi from "@pelote/api/GamesApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Game } from "@pelote/types/Game";

interface usePaginatedGamesOptions {
  seasonId?: string;
  competitionId?: string;
  stageId?: string;
  poolId?: string;
  roundId?: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
  name?: string;
  sortBy?: string;
  playgroundId?: string;
}

function usePaginatedGames(options: Partial<usePaginatedGamesOptions> = {}): {
  paginatedGames: PaginatedModel<Game> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const {
    name,
    seasonId,
    competitionId,
    stageId,
    poolId,
    roundId,
    playgroundId,
    page = 1,
    pageSize = 10,
    skip = false,
    sortBy,
  } = options;

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "indexGames",
      name,
      seasonId,
      competitionId,
      stageId,
      poolId,
      roundId,
      playgroundId,
      page,
      pageSize,
      sortBy,
    ],
    queryFn: () =>
      GamesApi.index(
        name,
        seasonId,
        competitionId,
        stageId,
        poolId,
        roundId,
        playgroundId,
        page,
        pageSize,
        sortBy,
      ),
    enabled: !skip,
  });

  return { paginatedGames: data, isLoading, isError };
}

export default usePaginatedGames;
