import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Loader from "@pelote/components/loader/Loader";
import { Round } from "@pelote/types/Round";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminRoundList from "./usePageAdminRoundList";
import { useForm } from "react-hook-form";
import FormButton from "@/ui-lib/form-button/FormButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";

const PageAdminRoundList = () => {
  const {
    sportPath,
    isLoading,
    seasonSelectOptions,
    competitionSelectOptions,
    paginatedRounds,
    handleChangeCompetitionOption,
    handleChangeSeasonOption,
  } = usePageAdminRoundList();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register } = useForm({
    defaultValues: {
      season_id: seasonSelectOptions?.[0]?.value,
      competition_id: competitionSelectOptions?.[0]?.value,
    },
  });

  const columlHelper = createColumnHelper<Round>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("display_order", {
      cell: (info) => info.getValue(),
      header: () => t("admin.displayOrder"),
    }),
    columlHelper.accessor("stage", {
      cell: (info) => info.getValue()?.name,
      header: () => t("stage"),
    }),
    columlHelper.accessor("pool", {
      cell: (info) => info.getValue()?.name,
      header: () => t("pool"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(
                `/admin/pelote/${sportPath}/seasons/${info.row.original.season_id}/competitions/${info.row.original.competition_id}/rounds/${info.row.original.id}`,
              )
            }
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.pelote.round.rounds")} />
      <FormButton
        labelName={t("admin.pelote.round.create")}
        onClick={() => {
          navigate(`/admin/pelote/${sportPath}/rounds/create`);
        }}
      />
      <br />
      <br />
      <div className="round-list-filters">
        {seasonSelectOptions && (
          <FormSelect
            id="season_id"
            labelName={t("season")}
            options={seasonSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeSeasonOption}
          />
        )}
        {seasonSelectOptions && (
          <FormSelect
            id="competition_id"
            labelName={t("competition")}
            options={competitionSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeCompetitionOption}
          />
        )}
      </div>
      <Table columns={columns} data={paginatedRounds?.items ?? []} hasGlobalFilter={true} />
    </>
  );
};

export default PageAdminRoundList;
