import { useNavigate, useParams } from "react-router-dom";

import useCompetition from "@pelote/hooks/useCompetition";
import { CompetitionDescription } from "@pelote/components/competition-description/CompetitionDescription";
import CardGame from "@pelote/components/CardGame/CardGame";
import logo from "@assets/logos/aiacode_logo.png";
import { CodingStatus } from "@pelote/types/Game";
import usePageCompetitionDetails from "@pelote/pages/cesta-punta/user/pageCompetitionDetails/usePageCompetitionDetails";
import GameFilter from "@pelote/components/game-filter/GameFilter";
import { hasPermission } from "@/auth/utils";
import { PermissionKey } from "@user/types/Permission";

import "./PageCompetitionDetails.scss";

export default function PageCompetitionDetails() {
  const { competitionId } = useParams();
  const navigate = useNavigate();

  const { competition } = useCompetition(competitionId);
  const {
    userPermissions,
    games,
    seasonSelectOptions,
    stageSelectOptions,
    poolSelectOptions,
    roundSelectOptions,
    handleChangeStageOption,
    handleChangePoolOption,
    handleChangeRoundOption,
    handleChangeSeasonOption,
  } = usePageCompetitionDetails();

  function redirectToGamePdfPage(gameId: string) {
    navigate(`/cesta-punta/games/${gameId}/gamePdf`);
  }

  function redirectToCodingPage(gameId: string) {
    navigate(`/cesta-punta/games/${gameId}/codage`);
  }

  return (
    <div className="competition-component">
      <div className="competition-header">
        {competition ? (
          <CompetitionDescription
            // Add aiacode logo if there is no logo, maybe replace with a fake logo
            logoUrl={competition?.logo_url ? competition?.logo_url : logo}
            name={competition.name}
            description={competition?.description}
          />
        ) : (
          ""
        )}
        <GameFilter
          seasonSelectOptions={seasonSelectOptions}
          stageSelectOptions={stageSelectOptions}
          poolSelectOptions={poolSelectOptions}
          roundSelectOptions={roundSelectOptions}
          handleChangeSeasonOption={handleChangeSeasonOption}
          handleChangeStageOption={handleChangeStageOption}
          handleChangePoolOption={handleChangePoolOption}
          handleChangeRoundOption={handleChangeRoundOption}
        />
      </div>
      <div className="games-container">
        {games &&
          games.map((game) => (
            <CardGame
              game={game}
              key={game.id}
              onCodingButtonClick={() => redirectToCodingPage(game.id)}
              disableCodingButton={
                !hasPermission(userPermissions, [
                  PermissionKey.FULL_ACCESS,
                  PermissionKey.PELOTE_CODING,
                ])
              }
              onPDFButtonClick={() => redirectToGamePdfPage(game.id)}
              disableReportButton={
                game.coding_status !== CodingStatus.done ||
                (game.coding_status == CodingStatus.done &&
                  !hasPermission(userPermissions, [
                    PermissionKey.FULL_ACCESS,
                    PermissionKey.PELOTE_ANALYSIS,
                  ]))
              }
            />
          ))}
      </div>
    </div>
  );
}
