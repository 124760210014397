import { useQuery } from "@tanstack/react-query";

import RoundsApi from "@pelote/api/RoundsApi";
import { Round } from "@pelote/types/Round";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedRoundsOptions {
  seasonId: string;
  competitionId: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedRounds(options: Partial<usePaginatedRoundsOptions> = {}): {
  paginatedRounds: PaginatedModel<Round> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { seasonId = "", competitionId = "", page = 1, pageSize = 10, skip = false } = options;

  // Fetch a list of rounds based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexRounds", seasonId, competitionId, page, pageSize],
    queryFn: () => RoundsApi.index(seasonId, competitionId, page, pageSize),
    enabled: !skip,
  });

  return { paginatedRounds: data, isLoading, isError };
}

export default usePaginatedRounds;
