import { useQuery } from "@tanstack/react-query";

import PoolsApi from "@pelote/api/PoolsApi";
import { Pool } from "@pelote/types/Pool";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedPoolsOptions {
  seasonId: string;
  competitionId: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedPools(options: Partial<usePaginatedPoolsOptions> = {}): {
  paginatedPools: PaginatedModel<Pool> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { seasonId = "", competitionId = "", page = 1, pageSize = 10, skip = false } = options;

  // Fetch a list of pools based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexPools", seasonId, competitionId, page, pageSize],
    queryFn: () => PoolsApi.index(seasonId, competitionId, page, pageSize),
    enabled: !skip,
  });

  return { paginatedPools: data, isLoading, isError };
}

export default usePaginatedPools;
