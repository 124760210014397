import React from "react";
import { useTranslation } from "react-i18next";
import aiapelote_logo from "@assets/logos/aiapelote_logo.png";

// CSS
import "@pelote/pages/cesta-punta/gamePdf/PageGamePdf.scss";

interface GamePdfHeaderProps {
  pageTitle: string;
  team1_color: string | undefined;
  team1_name: string | undefined;
  team2_color: string | undefined;
  team2_name: string | undefined;
}

const GamePdfHeader: React.FC<GamePdfHeaderProps> = ({
  pageTitle,
  team1_color,
  team1_name,
  team2_color,
  team2_name,
}) => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="header-title">
        <h1>{t(pageTitle)}</h1>
        <img src={aiapelote_logo} alt="logo" />
      </div>
      <div className="header-teams">
        <div className="team" style={{ gridArea: "1 / 2 / 1 / 2", backgroundColor: team1_color }}>
          <h2>{team1_name}</h2>
        </div>
        <div className="team" style={{ gridArea: "1 / 4 / 1 / 4", backgroundColor: team2_color }}>
          <h2>{team2_name}</h2>
        </div>
      </div>
    </div>
  );
};

export default GamePdfHeader;
