export function getCanchaVariables(playgroundNumberAreas: number) {
  let playgroundNumberLines = 0;
  let cellClassArray: number[] = [];
  let shouldApplyRowBorderRedArray: number[] = [];
  let colIndexFalta = 0;
  let colIndexPasa = 0;
  let colIndexBut = 0;

  if (playgroundNumberAreas === 9) {
    playgroundNumberLines = 8;
    cellClassArray = [2, 5, 9];
    shouldApplyRowBorderRedArray = [0, 2, 5, 9];
    colIndexFalta = 4;
    colIndexPasa = 1;
    colIndexBut = 0;
  } else if (playgroundNumberAreas === 13) {
    playgroundNumberLines = 12;
    cellClassArray = [3, 6, 9];
    shouldApplyRowBorderRedArray = [0, 3, 6, 9];
    colIndexFalta = 8;
    colIndexPasa = 5;
    colIndexBut = 2;
  } else if (playgroundNumberAreas === 14) {
    playgroundNumberLines = 13;
    cellClassArray = [4, 7, 10];
    shouldApplyRowBorderRedArray = [0, 4, 7, 10];
    colIndexFalta = 9;
    colIndexPasa = 6;
    colIndexBut = 3;
  } else if (playgroundNumberAreas === 15) {
    playgroundNumberLines = 14;
    cellClassArray = [5, 8, 11];
    shouldApplyRowBorderRedArray = [0, 5, 8, 11];
    colIndexFalta = 10;
    colIndexPasa = 7;
    colIndexBut = 4;
  }

  return {
    playgroundNumberLines,
    cellClassArray,
    shouldApplyRowBorderRedArray,
    colIndexFalta,
    colIndexPasa,
    colIndexBut,
  };
}
