import { useQuery } from "@tanstack/react-query";

import GamesApi from "@pelote/api/GamesApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Action } from "@pelote/types/Action";

function usePaginatedActions(gameId?: string): {
  paginatedActions: PaginatedModel<Action> | undefined;
  isLoadingPaginatedActions: boolean;
  isError: boolean;
} {
  // Fetch actions (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["getActions", gameId],
    queryFn: () => GamesApi.getActions(gameId as string),
  });

  return {
    paginatedActions: data,
    isLoadingPaginatedActions: isLoading,
    isError,
  };
}
export default usePaginatedActions;
