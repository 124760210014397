import { useQuery } from "@tanstack/react-query";

import SeasonsApi from "@pelote/api/SeasonsApi";
import { Season } from "@pelote/types/Season";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedSeasonsOptions {
  page?: number;
  pageSize?: number;
  sortBy?: string;
  sportId?: string;
  skip?: boolean;
}

function usePaginatedSeasons(options: Partial<usePaginatedSeasonsOptions> = {}): {
  paginatedSeasons: PaginatedModel<Season> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, sortBy = "", sportId, skip = false } = options;

  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexSeasons", page, pageSize, sortBy, sportId],
    queryFn: () => SeasonsApi.index(page, pageSize, sortBy, sportId),
    enabled: !skip,
  });

  return { paginatedSeasons: data, isLoading, isError };
}

export default usePaginatedSeasons;
