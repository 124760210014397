import { useContext } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ThemeContext } from "@/providers/ThemeProvider";
import router from "./router/Router";

import "react-toastify/dist/ReactToastify.css";

export const App = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div data-theme={theme}>
      <ToastContainer />
      <RouterProvider router={router} future={{ v7_startTransition: true }} />
    </div>
  );
};
