import { BsFiletypePdf } from "react-icons/bs";
import { BsPencilSquare } from "react-icons/bs";

import { convertISODateToDisplayFormat } from "@/utils/convertISODateToDisplayFormat";
import { getTeamSizesAndSubstitutes } from "@pelote/utils/getTeamSizesAndSubstitutes";
import { CodingStatus, Game, GameTeamFormat } from "@pelote/types/Game";
import { hasTeamsComplete } from "@pelote/utils/hasTeamsComplete";
import PlayerDisplayDouble from "./PlayersDisplayDouble";
import PlayerDisplaySingle from "./PlayerDisplaySingle";

import "./CardGame.scss";

interface CardGameProps {
  game: Game;
  isPlayerDetails?: boolean;
  onCodingButtonClick: () => void;
  onPDFButtonClick: () => void;
  disableCodingButton?: boolean;
  disableReportButton?: boolean;
}

const CardGame = ({
  game,
  isPlayerDetails,
  onCodingButtonClick,
  onPDFButtonClick,
  disableCodingButton = false,
  disableReportButton = false,
}: CardGameProps) => {
  const name = game.name;
  const pool = game.pool?.name;
  const round = game.round.name;
  const playground = game.playground.name;
  const competition = game.competition.name;

  // GET SUBS
  const { team_1_size, team_2_size } = getTeamSizesAndSubstitutes(game.players);

  // Check if the match has complete teams
  const matchHasCompleteTeams: boolean = hasTeamsComplete(game.players);

  // this is used to have differences in the css depending on where the component is being displayed
  const containerStyle = {
    backgroundColor: isPlayerDetails ? "rgba(240, 240, 240, 0.8)" : "",
    height: isPlayerDetails ? `${6 + Math.max(team_1_size, team_2_size)}rem` : "8rem",
    margin: isPlayerDetails ? "0 0 1% 0" : "",
    width: isPlayerDetails ? "100%" : "",
    "--specific-font-size": isPlayerDetails ? "large" : "",
    "--custom-box-shadow": isPlayerDetails
      ? "rgba(0, 0, 0, 0.45) 1.95px 1.95px 2.6px"
      : "3px 5px 10px rgba(0, 0, 0, 0.75)",
    "--custom-border": isPlayerDetails ? "0" : "2px solid black",
  };

  return (
    <div className="game-card-container" style={containerStyle}>
      <div className="details-container">
        <p>
          {isPlayerDetails
            ? convertISODateToDisplayFormat(game.start_time).slice(0, -8)
            : convertISODateToDisplayFormat(game.start_time)}
        </p>
        <p>{!isPlayerDetails && playground}</p>
        <p>{!isPlayerDetails && name}</p>
        <p>{!isPlayerDetails && pool}</p>
        <p>{isPlayerDetails && competition}</p>
        <p>{round}</p>
      </div>
      {game.team_format === GameTeamFormat.double && (
        <PlayerDisplayDouble game={game} isPlayerDetails={isPlayerDetails} />
      )}
      {game.team_format === GameTeamFormat.single && <PlayerDisplaySingle game={game} />}
      <div className="buttons-container">
        <button
          type="button"
          onClick={onCodingButtonClick}
          disabled={!matchHasCompleteTeams || disableCodingButton}
          className={!matchHasCompleteTeams || disableCodingButton ? "disabled" : "enabled"}
        >
          {/* COLOR CODE FOR CODING STATUS TODO =BLUE / INPROGRESS = ORANGE / DONE = GREEN */}
          <BsPencilSquare
            size={30}
            fill={
              !matchHasCompleteTeams || disableCodingButton
                ? "grey"
                : game.coding_status === CodingStatus.in_progress
                  ? "orange"
                  : game.coding_status === CodingStatus.done
                    ? "green"
                    : "blue"
            }
          />
        </button>
        <button
          type="button"
          onClick={onPDFButtonClick}
          disabled={disableReportButton}
          className={disableReportButton ? "disabled" : "enabled"}
        >
          <BsFiletypePdf size={30} fill={disableReportButton ? "grey" : "black"} />
        </button>
      </div>
    </div>
  );
};

export default CardGame;
