import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import { MenuItem } from "@/ui-lib/vertical-menu/VerticalMenu";
import { hasPermission } from "@/auth/utils";
import { PermissionKey } from "@/domains/user/types/Permission";
import { rugbyAppUrl } from "@/config";
import icon_users from "@/assets/icons/icon_users_white.png";
import icon_pelote_basque from "@/assets/icons/icon_pelote_basque_white.png";
import icon_rugby from "@/assets/icons/icon_rugby_white.png";
import icon_arrow_back from "@/assets/icons/icon_arrow_back_white.png";

function useLayoutAdmin(): {
  menuItems: MenuItem[];
  isLoading: boolean;
} {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { permissions, isLoading: isUserLoading } = useContext(userContext);

  const menuItems: MenuItem[] = [
    {
      key: "1",
      label: "Gestion des utilisateurs",
      icon: icon_users,
      children: [
        {
          key: "1-1",
          label: t("admin.user-management.organization.organizations"),
          onClick: () => navigate("/admin/user/organizations"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.USER_MANAGEMENT_FULL,
          ]),
        },
        {
          key: "1-2",
          label: t("admin.user-management.user.users"),
          onClick: () => navigate("/admin/user/users"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.USER_MANAGEMENT_FULL,
            PermissionKey.USER_MANAGEMENT_ORGANIZATION,
          ]),
        },
        {
          key: "1-3",
          label: t("admin.user-management.permission.permissions"),
          onClick: () => navigate("/admin/user/permissions"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.USER_MANAGEMENT_FULL,
            PermissionKey.USER_MANAGEMENT_ORGANIZATION,
          ]),
        },
        {
          key: "1-4",
          label: t("admin.user-management.sport.sports"),
          onClick: () => navigate("/admin/user/sports"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.USER_MANAGEMENT_FULL,
            PermissionKey.USER_MANAGEMENT_ORGANIZATION,
          ]),
        },
        {
          key: "1-5",
          label: t("admin.user-management.api-route.api-routes"),
          onClick: () => navigate("/admin/user/api-routes"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.USER_MANAGEMENT_FULL,
            PermissionKey.USER_MANAGEMENT_ORGANIZATION,
          ]),
        },
      ],
    },
    {
      key: "2",
      label: "Pelote Basque",
      icon: icon_pelote_basque,
      children: [
        {
          key: "2-1",
          label: t("admin.pelote.competition.competitions"),
          onClick: () => navigate("/admin/pelote/cesta-punta/competitions"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-2",
          label: t("admin.pelote.season.seasons"),
          onClick: () => navigate("/admin/pelote/cesta-punta/seasons"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-3",
          label: t("admin.pelote.stage.stages"),
          onClick: () => navigate("/admin/pelote/cesta-punta/stages"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-4",
          label: t("admin.pelote.pool.pools"),
          onClick: () => navigate("/admin/pelote/cesta-punta/pools"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-5",
          label: t("admin.pelote.round.rounds"),
          onClick: () => navigate("/admin/pelote/cesta-punta/rounds"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-6",
          label: t("admin.pelote.game.games"),
          onClick: () => navigate("/admin/pelote/cesta-punta/games"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-7",
          label: t("admin.pelote.video.videos"),
          onClick: () => navigate("/admin/pelote/cesta-punta/videos"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-8",
          label: t("admin.pelote.player.players"),
          onClick: () => navigate("/admin/pelote/cesta-punta/players"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-9",
          label: t("admin.pelote.playground.playgrounds"),
          onClick: () => navigate("/admin/pelote/cesta-punta/playgrounds"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_ADMINISTRATION,
          ]),
        },
        {
          key: "2-10",
          label: t("admin.pelote.actiontype.actiontypes"),
          onClick: () => navigate("/admin/pelote/cesta-punta/action-types"),
          isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
        },
        {
          key: "2-11",
          label: t("admin.pelote.label.labels"),
          onClick: () => navigate("/admin/pelote/cesta-punta/labels"),
          isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
        },
        {
          key: "2-12",
          label: t("admin.pelote.option.options"),
          onClick: () => navigate("/admin/pelote/cesta-punta/options"),
          isDisabled: !hasPermission(permissions, [PermissionKey.FULL_ACCESS]),
        },
      ],
    },
    {
      key: "3",
      label: "Rugby",
      icon: icon_rugby,
      children: [
        {
          key: "3-1",
          label: t("admin.pelote.competition.competitions"),
          onClick: () => navigate("/admin/rugby/competitions"),
          isDisabled: true,
        },
        {
          key: "3-2",
          label: t("admin.pelote.season.seasons"),
          onClick: () => navigate("/admin/rugby/seasons"),
          isDisabled: true,
        },
      ],
    },
    {
      key: "4",
      label: "Retour client",
      icon: icon_arrow_back,
      children: [
        {
          key: "4-1",
          label: "Pelote Basque",
          onClick: () => navigate("/cesta-punta/competitions"),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.PELOTE_BASE,
          ]),
        },
        {
          key: "4-2",
          label: "Rubgy",
          onClick: () => (window.location.href = rugbyAppUrl),
          isDisabled: !hasPermission(permissions, [
            PermissionKey.FULL_ACCESS,
            PermissionKey.RUGBY_BASE,
          ]),
        },
      ],
    },
  ];

  // Build the isLoading flag
  const isLoading = isUserLoading;

  return {
    menuItems: menuItems,
    isLoading,
  };
}

export default useLayoutAdmin;
