import { Outlet } from "react-router-dom";

import Loader from "@/ui-lib/loader/Loader";
import useLayoutAdmin from "./useLayoutAdmin";
import AdminNavBar from "@/ui-lib/admin-nav-bar/AdminNavBar";

import styles from "./LayoutAdmin.module.scss";

const LayoutAdmin = () => {
  const { menuItems, isLoading } = useLayoutAdmin();

  if (isLoading) {
    return <Loader rootClassName={styles.loader} />;
  }

  return (
    <div className={styles.layoutAdmin}>
      <AdminNavBar menuItems={menuItems} rootClassName={styles.navBar} />
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  );
};

export default LayoutAdmin;
