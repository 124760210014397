import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const UseScores = () => {
  const scores = useAppSelector((state: RootState) => state.score);

  return {
    scores,
  };
};

export default UseScores;
