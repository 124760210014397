import { useTranslation } from "react-i18next";

import "./_loader.scss";

function Loader() {
  const { t } = useTranslation();

  return (
    <div className="loading-wrapper">
      <div className="wrapper">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="wrapper">
        <h3> {t("dataLoading")}</h3>
      </div>
    </div>
  );
}

export default Loader;
