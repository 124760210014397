import { Action } from "@pelote/types/Action";
import { GamePlayer } from "@pelote/types/GamePlayer";
import { WinningAction } from "@pelote/types/TimelineType";
import { getWinningAction } from "@pelote/utils/getWinningAction";

export function determineNbWinningActions(actions: Action[], players: GamePlayer[]) {
  const countWinningActions: WinningAction[] = [];
  const winningActionId: string[] = [];
  // Check every action of the currentPoint, if a winningaction is present
  // Also check if the last action of the currentPoint is different of the winningAction
  actions.map((action) => {
    const winningAction = getWinningAction(action, players);
    if (winningAction.isWinningAction) {
      winningActionId.push(winningAction.winningActionId);
      countWinningActions.push(winningAction);
    }
  });
  const idLastActionOfTheCurrentPoint = actions[actions.length - 1]?.id;
  let isLastActionDifferentOfTheWinningAction: boolean = false;
  if (countWinningActions.length === 1) {
    isLastActionDifferentOfTheWinningAction = winningActionId[0] !== idLastActionOfTheCurrentPoint;
  }

  const results = {
    nbOfWinningActions: countWinningActions.length,
    isLastActionDifferentOfTheWinningAction: isLastActionDifferentOfTheWinningAction,
  };

  return results;
}
