import { Fragment } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import UseVideoPlayerData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/videoPlayerHook";
import UseVideoGameHook from "@pelote/pages/cesta-punta/user/codage/redux/hooks/videoHook";
import { VideoPlayerButtons } from "@pelote/components/interface-codage/VideoPlayerButtons/VideoPlayerButtons";

export function VideoPlayer({ videoPlayerRef }: { videoPlayerRef: React.RefObject<ReactPlayer> }) {
  const { t } = useTranslation();
  const { videos } = UseVideoGameHook();
  const { isMuted, isPlaying, currentSpeed } = UseVideoPlayerData();
  return (
    <Fragment>
      <ReactPlayer
        ref={videoPlayerRef}
        url={videos[0].uri}
        muted={isMuted}
        controls
        width="100%"
        height="95%"
        playing={isPlaying}
        playbackRate={currentSpeed}
        onError={() => {
          toast.error(t("videoPlayer.error"), {
            autoClose: 3000,
            position: "bottom-right",
          });
        }}
        config={{
          file: {
            hlsOptions: {
              debug: true,
              enableWorker: true,
              lowLatencyMode: true,
              backBufferLength: 90,
              enableSoftwareAES: true,
            },
          },
        }}
      />
      <VideoPlayerButtons videoPlayerRef={videoPlayerRef} />
    </Fragment>
  );
}
