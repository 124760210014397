import { Fragment } from "react";

import "./toggleButtons.scss";

export type ToggleButtonProps = {
  isToggled: boolean;
  isDisabled?: boolean;
  onClick: () => void;
  className?: string;
};

function ToggleButton({ isToggled, isDisabled = false, onClick }: ToggleButtonProps) {
  return (
    <Fragment>
      <div
        className={`flipBtn ${isDisabled ? "is-disabled" : ""}`}
        onClick={() => {
          !isDisabled && onClick();
        }}
      >
        <div className={`btn ${isToggled ? "move" : ""}`}></div>
        <span className={`circle ${isToggled ? "expand" : ""}`}></span>
      </div>
    </Fragment>
  );
}

export default ToggleButton;
