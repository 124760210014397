import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Permission } from "@user/types/Permission";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminPermissionList from "./usePageAdminPermissionList";
import Pagination from "@/ui-lib/pagination/Pagination";

import "./pageAdminPermissionList.scss";

const PageAdminPermissionList = () => {
  const { paginatedPermissions, setCurrentPage, currentPage, pageSize, isLoading } =
    usePageAdminPermissionList({
      pageSize: 25,
    });
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Permission>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user-management.permission.domain"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user-management.permission.name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.user-management.permission.description"),
    }),
  ];

  return (
    <div className="page-admin-permission-list">
      <AdminHeader text={t("admin.user-management.permission.permissions")} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="permissions-list-content">
          <Table
            columns={columns}
            data={paginatedPermissions?.items ?? []}
            hasGlobalFilter={true}
          />
          <div className="pagination">
            <Pagination
              totalItems={paginatedPermissions?.pagination.totalItems ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminPermissionList;
