import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./table.scss";

type TableProps<T extends object> = {
  // Hard to fix the type: https://github.com/TanStack/table/issues/4382
  columns: ColumnDef<T, any>[];
  data: T[];
  hasGlobalFilter?: boolean;
  title?: string;
  maxHeight?: string;
  className?: string;
};
const Table = <T extends object>({ columns, data, title, className, maxHeight }: TableProps<T>) => {
  const [filtering, setFiltering] = useState("");
  const { t } = useTranslation();
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter: filtering,
    },
    onGlobalFilterChange: setFiltering,
  });
  return (
    <div className={`table-component ${className}`}>
      <h1>{title}</h1>
      <br />
      {/* // TODO: Replace by  a 'real' filter, fetching the db */}
      {/* {hasGlobalFilter && (
        <input
          type="text"
          value={filtering}
          placeholder="Rechercher..."
          onChange={(e) => setFiltering(e.target.value)}
        />
      )} */}
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup, index) => (
            <tr key={index}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={index}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    width: header.column.getSize(),
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{ asc: "↑", desc: "↓" }[header.column.getIsSorted() as string] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{ maxHeight: maxHeight }}>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row, index) => (
              <tr key={index}>
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={index}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>{t("admin.noData")}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
