import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import ApiRoutesApi, { CreateApiRouteInputs } from "@user/api/ApiRoutesApi";
import { APIError } from "@pelote/types/ApiError";
import { ApiRoute } from "@user/types/ApiRoute";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedDomains from "@user/hooks/usePaginatedDomains";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { cacheKeyInvalidator } from "@user/api/apiCache";

function usePageAdminApiRouteCreate(): {
  domainOptions: SelectOption[];
  // changeDomain: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  methodOptions: SelectOption[];
  createApiRoute: (createApiRouteInputs: CreateApiRouteInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  createdApiRoute: ApiRoute | undefined;
} {
  const { t } = useTranslation();

  // Build the domain options
  const { paginatedDomains, isLoading: isDomainsLoading } = usePaginatedDomains({ pageSize: 100 });
  const domains = paginatedDomains?.items || [];
  const domainOptions = convertItemsToSelectOptions(domains, "id", "name");
  domainOptions.unshift({ label: "", value: "" });

  // Build the method options
  const methodOptions = [
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "PATCH", value: "PATCH" },
    { label: "DELETE", value: "DELETE" },
  ];

  // Handle the creation of a api-route
  const queryClient = useQueryClient();
  const {
    mutate: createApiRouteInApi,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
    data: createdApiRoute,
  } = useMutation({
    mutationFn: (newApiRoute: CreateApiRouteInputs) => {
      return ApiRoutesApi.create(newApiRoute);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: cacheKeyInvalidator.apiRoutes() });
      toast.success(t("admin.user-management.api-route.api-route-creation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user-management.api-route.api-route-creation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function createApiRoute(createApiRouteInputs: CreateApiRouteInputs) {
    createApiRouteInApi(createApiRouteInputs);
  }

  // Build gloabl isLoading flag
  const isLoading = isDomainsLoading;

  return {
    domainOptions,
    methodOptions,
    createApiRoute,
    isLoading,
    isCreationPending,
    isCreationSuccess,
    createdApiRoute,
  };
}

export default usePageAdminApiRouteCreate;
