import { GamePlayer, GamePlayerPosition } from "@pelote/types/GamePlayer";

/**
 * Check for a match if the teams are 'complete', meaning that there is at least one front and one back player for each team
 * @param players
 * @returns boolean
 */
export function hasTeamsComplete(players: GamePlayer[]): boolean {
  return (
    players.some(
      (player) => player.team_number === 1 && player.position === GamePlayerPosition.front,
    ) &&
    players.some(
      (player) => player.team_number === 1 && player.position === GamePlayerPosition.back,
    ) &&
    players.some(
      (player) => player.team_number === 2 && player.position === GamePlayerPosition.front,
    ) &&
    players.some(
      (player) => player.team_number === 2 && player.position === GamePlayerPosition.back,
    )
  );
}
