import { useQuery } from "@tanstack/react-query";

import { Sport, SportKey } from "@user/types/Sport";
import SportsApi from "@user/api/SportsApi";
import { cacheKeyBuilder } from "@user/api/apiCache";

interface useSportsOptions {
  skip?: boolean;
}

function useSports(options: Partial<useSportsOptions> = {}): {
  sports: Sport[];
  sportRugbyXV: Sport;
  sportRugbyVII: Sport;
  isLoading: boolean;
  isError: boolean;
} {
  const { skip } = options;

  // Fetch the list of all sports
  const {
    data: sports = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: cacheKeyBuilder.sports(),
    queryFn: () => SportsApi.getAll(),
    enabled: !skip,
  });

  // Build sportRugbyXV and sportRugbyVII
  const sportRugbyXV = sports.find((sport) => sport.key === SportKey.RUGBY_XV) as Sport;
  const sportRugbyVII = sports.find((sport) => sport.key === SportKey.RUGBY_VII) as Sport;

  return {
    sports,
    sportRugbyXV,
    sportRugbyVII,
    isLoading,
    isError,
  };
}

export default useSports;
