import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateRoundInputs } from "@pelote/api/RoundsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminRoundEdit from "./usePageAdminRoundEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminRound.scss";
import DeleteButton from "@/ui-lib/delete-button/DeleteButton";

const PageAdminRoundEdit = () => {
  const { round, handleEditRound, handleDeleteRound, disableDelete, isLoading } =
    usePageAdminRoundEdit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateRoundInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
    },
  });

  useEffect(() => {
    setValue("name", round?.name || "");
    setValue("display_order", round?.display_order as number);
  }, [round]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("round")} "${round?.name}"`} />
      <form onSubmit={handleSubmit(handleEditRound)}>
        <div className="page-round-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
        </div>
        <div className="page-round-buttons">
          {round != undefined && (
            <DeleteButton
              labelName={t("admin.delete")}
              onClick={() => handleDeleteRound(round.season_id, round.competition_id, round.id)}
              disabled={disableDelete}
            />
          )}
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/rounds`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminRoundEdit;
