import { useQuery } from "@tanstack/react-query";

import OptionsApi from "@pelote/api/OptionsApi";
import { Option } from "@pelote/types/Option";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface useOptionsOptions {
  page?: number;
  pageSize?: number;
  sportId?: string; // UUID v4
  skip?: boolean;
}

function usePaginatedOptions(options: Partial<useOptionsOptions> = {}): {
  paginatedOptions: PaginatedModel<Option> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, sportId, skip } = options;

  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexOptions", page, pageSize, sportId],
    queryFn: () => OptionsApi.index(page, pageSize, sportId),
    enabled: !skip,
  });

  return { paginatedOptions: data, isLoading, isError };
}

export default usePaginatedOptions;
