import { useQuery } from "@tanstack/react-query";

import CompetitionsApi from "@pelote/api/CompetitionsApi";
import { Competition } from "@pelote/types/Competition";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedCompetitionsOptions {
  page?: number;
  pageSize?: number;
  sportId?: string;
  skip?: boolean;
}

function usePaginatedCompetitions(options: Partial<usePaginatedCompetitionsOptions> = {}): {
  paginatedCompetitions: PaginatedModel<Competition> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, sportId, skip = false } = options;

  // Get the paginated competitions
  const {
    data: paginatedCompetitions,
    isLoading: isCompetitionsLoading,
    isError,
  } = useQuery({
    queryKey: ["indexCompetitions", page, pageSize, sportId],
    queryFn: () => CompetitionsApi.index(page, pageSize, sportId),
    enabled: !skip,
  });

  const isLoading = isCompetitionsLoading;

  return { paginatedCompetitions, isLoading, isError };
}

export default usePaginatedCompetitions;
