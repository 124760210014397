import { Action } from "@pelote/types/Action";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PreviousAction = {
  action: Action;
};

const initialState = {
  action: {
    id: "",
    game_id: "",
    action_type_id: "",
    player_id: "",
    click_time: 0,
    label_option_ids: [
      {
        label_id: "",
        option_id: "",
      },
    ],
    coordinates: {
      x: 0,
      y: 0,
    },
    set_number: 1,
  },
};

export const previousActionSlice = createSlice({
  name: "previousAction",
  initialState,
  reducers: {
    setPreviousAction: (state, action: PayloadAction<PreviousAction>) => {
      state.action = action.payload.action;
    },
  },
});

export const { setPreviousAction } = previousActionSlice.actions;
export default previousActionSlice.reducer;
