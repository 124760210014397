import "./checkbox.scss";

export type Checkbox = {
  defaultChecked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

/** Generic checkbox component
 * @param defaultChecked: boolean -- default checked state
 * @param disabled: boolean -- enable / disable the checkbox
 * @param onClick: () => void
 * @param className: string
 * @returns React.FC
 */
const Checkbox = ({ defaultChecked, disabled, onClick, className }: Checkbox) => {
  return (
    <div className={`ui-lib-checkbox ${className}`}>
      <input
        type="checkbox"
        onClick={onClick}
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
    </div>
  );
};

export default Checkbox;
