import { Action } from "@pelote/types/Action";
import { GamePlayer } from "@pelote/types/GamePlayer";
import { ConsecutivePasasByTeam } from "@pelote/utils/getConsecutivePasasPerTeam";

export function updateScoresAndPasas(
  action: Action,
  players: GamePlayer[],
  scoreTeam1Set1: number,
  scoreTeam1Set2: number,
  scoreTeam1Set3: number,
  scoreTeam2Set1: number,
  scoreTeam2Set2: number,
  scoreTeam2Set3: number,
  pasaTeam1: number,
  pasaTeam2: number,
  consecutivePasasByTeam: ConsecutivePasasByTeam,
) {
  const player = players.find((player) => player.id === action.player_id);
  const numberTeam = player?.team_number;

  const negativeshot = action.label_option_ids.some(
    (item) =>
      item.label_id === "7df681bd-6be4-4157-abbe-2f386cc35d79" &&
      item.option_id === "cf939239-37e5-489d-8be0-a14f9946d547",
  );
  const positiveshot = action.label_option_ids.some(
    (item) =>
      item.label_id === "7df681bd-6be4-4157-abbe-2f386cc35d79" &&
      item.option_id === "39699f36-4fdd-478a-984c-cddfbbf779d2",
  );
  const negativeReception = action.label_option_ids.some(
    (item) =>
      item.label_id === "c312590b-2358-4762-8b33-a30e10a06352" &&
      item.option_id === "cf939239-37e5-489d-8be0-a14f9946d547",
  );
  const ace = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "dae5bfb1-cbb7-48d1-a6d2-7343d2ffcf07",
  );
  const falta = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "484607bd-b6be-4297-9864-a29d499c281a",
  );

  const pasa = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "513c7fc9-b0e4-4993-9d76-6dbd0b2e752f",
  );

  if (numberTeam === 1) {
    if (negativeshot || falta || negativeReception) {
      if (action.set_number === 1) {
        scoreTeam2Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam2Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam2Set3 += 1;
      }
    }
    if (positiveshot || ace) {
      if (action.set_number === 1) {
        scoreTeam1Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam1Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam1Set3 += 1;
      }
    }
    if (pasa && pasaTeam1 >= 5) {
      // for every pasa in addition we need to increment score of the opposite team for each set
      if (action.set_number === 1) {
        scoreTeam2Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam2Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam2Set3 += 1;
      }
    } else if (
      pasa &&
      consecutivePasasByTeam.consecutivesPasasTeam1?.find((item) => item.actionId === action.id)
    ) {
      const index = consecutivePasasByTeam.consecutivesPasasTeam1?.findIndex(
        (item) => item.actionId === action.id,
      );
      if (
        action.set_number === 1 &&
        consecutivePasasByTeam.consecutivesPasasTeam1[index].setNumber === 1
      ) {
        scoreTeam2Set1 += 1;
      } else if (
        action.set_number === 2 &&
        consecutivePasasByTeam.consecutivesPasasTeam1[index].setNumber === 2
      ) {
        scoreTeam2Set2 += 1;
      } else if (
        action.set_number === 3 &&
        consecutivePasasByTeam.consecutivesPasasTeam1[index].setNumber === 3
      ) {
        scoreTeam2Set3 += 1;
      }
    } else if (pasa) {
      pasaTeam1 += 1;
    }
  }
  if (numberTeam === 2) {
    if (negativeshot || falta || negativeReception) {
      if (action.set_number === 1) {
        scoreTeam1Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam1Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam1Set3 += 1;
      }
    }
    if (positiveshot || ace) {
      if (action.set_number === 1) {
        scoreTeam2Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam2Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam2Set3 += 1;
      }
    }
    if (pasa && pasaTeam2 >= 5) {
      if (action.set_number === 1) {
        scoreTeam1Set1 += 1;
      } else if (action.set_number === 2) {
        scoreTeam1Set2 += 1;
      } else if (action.set_number === 3) {
        scoreTeam1Set3 += 1;
      } else if (pasa) {
        pasaTeam2 += 1;
      }
    } else if (
      pasa &&
      consecutivePasasByTeam.consecutivesPasasTeam2?.find((item) => item.actionId === action.id)
    ) {
      const index = consecutivePasasByTeam.consecutivesPasasTeam2?.findIndex(
        (item) => item.actionId === action.id,
      );
      if (
        action.set_number === 1 &&
        consecutivePasasByTeam.consecutivesPasasTeam2[index].setNumber === 1
      ) {
        scoreTeam1Set1 += 1;
      } else if (
        action.set_number === 2 &&
        consecutivePasasByTeam.consecutivesPasasTeam2[index].setNumber === 2
      ) {
        scoreTeam1Set2 += 1;
      } else if (
        action.set_number === 3 &&
        consecutivePasasByTeam.consecutivesPasasTeam2[index].setNumber === 3
      ) {
        scoreTeam1Set3 += 1;
      }
    } else if (pasa) {
      pasaTeam2 += 1;
    }
  }

  const scores = {
    scoreTeam1Set1,
    scoreTeam1Set2,
    scoreTeam1Set3,
    scoreTeam2Set1,
    scoreTeam2Set2,
    scoreTeam2Set3,
    pasaTeam1,
    pasaTeam2,
  };

  return scores;
}
