import Smartlook from "smartlook-client";

import { appEnv } from "@/config";
import { AppEnv } from "./constants";

class SmartlookManager {
  key: string;

  constructor() {
    this.key = "016af637bb52871b1fe0d4c040a121f5ccecad03";
  }

  init() {
    if (appEnv !== AppEnv.production) {
      console.info("Smartlook is disabled in non-production environments");
      return;
    } else {
      // Init smartlook client
      Smartlook.init(this.key, { region: "eu" });

      // Enable recording sensitive data
      Smartlook.record({
        emails: true,
        forms: true,
        ips: true,
        numbers: true,
      });
    }
  }

  addIdentity(email: string, firtname: string, lastname: string) {
    if (appEnv === AppEnv.production) {
      Smartlook.identify(email, {
        email: email,
        name: `${firtname} ${lastname}`,
        firtname: firtname,
        lastname: lastname,
      });
    }
  }
}

export default new SmartlookManager();
