import { useQuery } from "@tanstack/react-query";

import ActionTypesApi from "@pelote/api/ActionTypesApi";
import { ActionType } from "@pelote/types/ActionType";

function useActionType(actionTypeId: string): {
  actionType?: ActionType;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch an action type (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showActionType", actionTypeId],
    queryFn: () => ActionTypesApi.show(actionTypeId),
  });

  return { actionType: data, isLoading, isError };
}

export default useActionType;
