import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Sport = {
  sportId: string;
};

const initialState: Sport = {
  sportId: "",
};

export const sportSlice = createSlice({
  name: "sportReducer",
  initialState,
  reducers: {
    setSport(state, action: PayloadAction<string>) {
      state.sportId = action.payload;
    },
  },
});

export const { setSport } = sportSlice.actions;
export default sportSlice.reducer;
