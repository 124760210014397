import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UpdatePoolInputs } from "@pelote/api/PoolsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminPoolEdit from "./usePageAdminPoolEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

const PageAdminPoolEdit = () => {
  const { pool, handleEditPool, isLoading } = usePageAdminPoolEdit();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdatePoolInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
    },
  });

  useEffect(() => {
    setValue("name", pool?.name || "");
    setValue("display_order", pool?.display_order as number);
  }, [pool]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("pool")} ${pool?.name}`} />
      <form onSubmit={handleSubmit(handleEditPool)}>
        <div className="page-pool-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
          <div className="page-pool-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/pools`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminPoolEdit;
