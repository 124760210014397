import { configureStore } from "@reduxjs/toolkit";

import videoPlayerReducer from "./features/videoPlayerReducer";
import gameCestaReducer from "./features/gameDatasReducer";
import actionsReducer from "./features/actionsReducer";
import currentActionReducer from "./features/currentActionReducer";
import previousActionReducer from "./features/previousActionReducer";
import scoreReducer from "./features/scoreReducer";
import editModeReducer from "./features/editModeReducer";
import validationReducer from "./features/validationReducer";
import { sportSlice } from "./features/sport-reducers";

export const store = configureStore({
  reducer: {
    videoPlayer: videoPlayerReducer,
    game: gameCestaReducer,
    actions: actionsReducer,
    sportReducer: sportSlice.reducer,
    currentAction: currentActionReducer,
    previousAction: previousActionReducer,
    score: scoreReducer,
    editModeReducer: editModeReducer,
    validationReducer: validationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
