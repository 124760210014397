import { useQuery } from "@tanstack/react-query";

import LabelsApi from "@pelote/api/LabelsApi";
import { Label } from "@pelote/types/Label";

function useLabel(labelId: string): {
  label?: Label;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a label (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showLabel", labelId],
    queryFn: () => LabelsApi.show(labelId),
  });

  return { label: data, isLoading, isError };
}

export default useLabel;
