import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const UseGameDetails = () => {
  const game = useAppSelector((state: RootState) => state.game);
  const playgroundNumberAreas = game.game.playground.number_areas;
  const competitionId = game.game.competition.id;

  return {
    game,
    playgroundNumberAreas,
    competitionId,
  };
};

export default UseGameDetails;
