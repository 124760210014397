import { useNavigate } from "react-router-dom";
import Pagination from "@/ui-lib/pagination/Pagination";
import { useTranslation } from "react-i18next";

import { Player } from "@pelote/types/Player";
import CardPlayer from "@pelote/components/card-player/CardPlayer";
import usePageUserPlayers from "@pelote/pages/cesta-punta/user/players/usePageUserPlayers";
import StringInput from "@/ui-lib/input/StringInput";

import "@pelote/components/players-list/PlayersList.scss";
import "./PageUserPlayers.scss";

const PageUserPlayers = () => {
  const {
    paginatedPlayers,
    isLoading,
    pageSize,
    currentPage,
    searchPlayerInput,
    selectPositionInput,
    setCurrentPage,
    handleSearchChange,
    handlePositionChange,
  } = usePageUserPlayers({ pageSize: 30 });

  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="page-user-players">
      {/* Position Filter Section */}
      <div className="filter-section">
        <div className="filter-option">
          <label htmlFor="positionFilter">{t("position")} : </label>
          <select
            id="positionFilter"
            value={selectPositionInput || ""}
            onChange={handlePositionChange}
          >
            <option key="any" value="any">
              {t("all")}
            </option>
            <option key="front" value="front">
              {t("front")}
            </option>
            <option key="back" value="back">
              {t("back")}
            </option>
          </select>
        </div>
        <div className="search-bar">
          <StringInput
            placeholder={t("search")}
            value={searchPlayerInput}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {!isLoading && paginatedPlayers && (
        <>
          <div className="player-list-container">
            {paginatedPlayers.items.map((player: Player) => (
              <CardPlayer
                key={player.id}
                id={player.id}
                firstName={player.firstname}
                lastName={player.lastname}
                nationality={player.nationality || "France"}
                position={player.favorite_position}
                urlPhoto={player.photo_url}
                onClick={() => navigate(`/cesta-punta/players/${player.id}`)}
              />
            ))}
          </div>
          <div className="pagination">
            <Pagination
              totalItems={paginatedPlayers?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PageUserPlayers;
