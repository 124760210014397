export const parseDurationStringToInt = (time: string): number => {
  // Remove the single quotes from the string
  const cleanedTime = time.replace(/'/g, "-");

  // Split the time string into minutes and seconds parts
  const [minutesStr, secondsStr] = cleanedTime.split("-");

  if (secondsStr === "") {
    // SECONDS ONLY
    return parseInt(minutesStr, 10);
  }
  // Convert minutes and seconds to integers
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);

  // Calculate the total time in seconds
  const totalTimeInSeconds = minutes * 60 + seconds;

  return totalTimeInSeconds;
};

export const formatTime = (seconds: number): string => {
  const isoStringDate = new Date(seconds * 1000).toISOString();

  // Extract the time part from the ISO string (substring from index 11 to 19)
  const timePart = isoStringDate.substring(11, 19);

  // Check if the timePart includes hours (if it's greater than "00:00:00")
  if (seconds >= 3600) {
    return timePart;
  } else {
    // If there are no hours, return just minutes and seconds
    return timePart.substring(3);
  }
};

export const formatSecondsToMMSS = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours}h${remainingMinutes}min${remainingSeconds}s`;
  } else if (remainingMinutes > 0) {
    return `${remainingMinutes}min${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
};
