import { SelectionCodage } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";

// This function is used to set the button validate state when condition is met only for Cesta Punta
export function setButtonValidateState(isSelected: SelectionCodage) {
  // In future, we can add id_player corresponding to AVANT in order to attribute only service to AVANT
  const isServiceValid =
    isSelected.player &&
    isSelected.actionType &&
    isSelected.typeSevice &&
    isSelected.wallZone &&
    isSelected.coordinates;

  const isReceptionNegativeValid =
    isSelected.player &&
    isSelected.actionType &&
    isSelected.side &&
    isSelected.result &&
    isSelected.typeReception &&
    isSelected.coordinates;

  const isReceptionPositiveShotValid =
    isSelected.player &&
    isSelected.actionType &&
    isSelected.side &&
    isSelected.typeReception &&
    isSelected.typeShot &&
    isSelected.wallZone &&
    isSelected.result &&
    isSelected.coordinates;

  const isSelectedObject = {
    service: isServiceValid,
    receptionNegative: isReceptionNegativeValid,
    receptionPositiveShot: isReceptionPositiveShotValid,
  };

  return isSelectedObject;
}
