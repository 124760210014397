import { appEnv, multisportsApiGatewayKey } from "@/config";
import msalManager from "@/auth/msalManager";
import { APIGame } from "./apiTypes";
import { Game } from "../types/Game";

async function getDefaultHeaders() {
  const accessToken = await msalManager.getAccessToken();

  return {
    "Ocp-Apim-Subscription-Key": multisportsApiGatewayKey,
    Authorization: `Bearer ${accessToken}`,
    "x-application-name": "multisports-front-rugby",
    "x-env": appEnv,
  };
}

export { getDefaultHeaders };

export function castApiGameToGame(apiGameRugby: APIGame): Game {
  return {
    id: apiGameRugby.id,
    date: apiGameRugby.date,
    competition: apiGameRugby.competition,
    teamHome: apiGameRugby.team_home,
    teamAway: apiGameRugby.team_away,
  };
}
