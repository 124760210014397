import { FaTrophy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { getParsedScores } from "@pelote/utils/getParsedScores";
import determineWinner from "@pelote/utils/determineWinner";

import { Game } from "@pelote/types/Game";
import playerDefaultImage from "/src/assets/images/default_player_photo.png";
import ScoreDisplay from "./ScoreDisplay";
import { findPlayersSingle } from "../../utils/findPlayersSingle";
import { SetsPatterns } from "../../types/Competition";

import "./CardGame.scss";
interface PlayerDisplaySingleProps {
  game: Game;
}

const PlayerDisplaySingle = ({ game }: PlayerDisplaySingleProps) => {
  const navigate = useNavigate();

  // Find the index of the players in the array of players (not substitutes)
  const { player_1, player_2 } = findPlayersSingle(game.players);

  const scoreTeam1Splited = getParsedScores(game.score_team_1 || "0;0;0");
  const scoreTeam2Splited = getParsedScores(game.score_team_2 || "0;0;0");

  const isWinner = determineWinner(
    scoreTeam1Splited,
    scoreTeam2Splited,
    game.competition.sets_pattern || SetsPatterns.forty,
  );

  return (
    <div className="players-score-container">
      <div className="team-1" style={{ borderRight: `15px solid ${game.color_team_1}` }}>
        <div className="winner-logo">{isWinner.isWinnerTeam1 ? <FaTrophy size={30} /> : ""}</div>
        <div className="players-picture">
          {player_1 !== undefined ? (
            <img src={player_1.photo_url || playerDefaultImage} alt="player_front" />
          ) : (
            <img alt="player_front" src={playerDefaultImage} />
          )}
        </div>
        <div className="players-name">
          <div>
            {player_1 !== undefined ? (
              <p onClick={() => navigate(`/cesta-punta/players/${player_1.id}`)}>
                {player_1.lastname}
              </p>
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
        <ScoreDisplay score={game.score_team_1} game={game} />
      </div>
      <div className="team-2" style={{ borderRight: `15px solid ${game.color_team_2}` }}>
        <div className="winner-logo">{isWinner.isWinnerTeam2 ? <FaTrophy size={30} /> : ""}</div>
        <div className="players-picture">
          {player_2 !== undefined ? (
            <img src={player_2.photo_url || playerDefaultImage} alt="player_front" />
          ) : (
            <img alt="player_front" src={playerDefaultImage} />
          )}
        </div>
        <div className="players-name">
          {player_2 !== undefined ? (
            <p onClick={() => navigate(`/cesta-punta/players/${player_2.id}`)}>
              {player_2.lastname}
            </p>
          ) : (
            <p>-</p>
          )}
        </div>
        <ScoreDisplay score={game.score_team_2} game={game} />
      </div>
    </div>
  );
};

export default PlayerDisplaySingle;
