import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { APIError } from "../types/ApiError";

import GamesApi, { UpdateGameInputs } from "../api/GamesApi";
import { Game } from "../types/Game";

function useGameEdit(gameId: string): {
  handleEditStatusGame: (updatedGame: UpdateGameInputs) => void;
} {
  const queryClient = useQueryClient();
  const { mutate: updateGame } = useMutation({
    mutationFn: (updatedGame: UpdateGameInputs) => GamesApi.update(gameId, updatedGame),

    onSuccess: (data: Game) => {
      queryClient.invalidateQueries({
        queryKey: ["showGame", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexGames"],
      });
      toast.success("La partie a bien été modifiée.", {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
    onError: (error: APIError) => {
      // Deal with error message, what display to user ?
      console.error(error);
      toast.error("Une erreur est survenue lors de la modification.", {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
  });

  function handleEditStatusGame(updatedGame: UpdateGameInputs) {
    updateGame(updatedGame);
  }

  return {
    handleEditStatusGame,
  };
}

export default useGameEdit;
