import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiPermissionToPermission,
  castApiRoleToRole,
  castApiUserToUser,
  getDefaultHeaders,
} from "./utils";
import { Role } from "@user/types/Role";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { User } from "../types/User";
import { Permission } from "../types/Permission";
import { APIPaginatedModel, APIPermission, APIRole, APIUser } from "./apiTypes";
import { buildUrlWithParams } from "@/utils/URL";

export interface CreateRoleInputs {
  name: string;
  description?: string;
  organizationId: string; // UUID v4
}

export interface UpdateRoleInputs {
  name?: string;
  description?: string;
}

export interface IndexRoleOptions {
  page?: number;
  pageSize?: number;
  organizationId?: string;
}

export interface IndexRoleUsersOptions {
  page?: number;
  pageSize?: number;
}

export default class RolesApi {
  static domain: string = "user";
  static resource: string = "roles";

  static async create(createRoleInputs: CreateRoleInputs): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}`;

    const body = {
      name: createRoleInputs.name,
      description: createRoleInputs.description,
      organization_id: createRoleInputs.organizationId,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiRole: APIRole = response.data;

    const role = castApiRoleToRole(apiRole);

    return role;
  }

  static async show(id: string): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiRole: APIRole = response.data;

    const role = castApiRoleToRole(apiRole);

    return role;
  }

  static async index(options: Partial<IndexRoleOptions> = {}): Promise<PaginatedModel<Role>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
      organization_id: options.organizationId,
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedRoles: APIPaginatedModel<APIRole> = response.data;

    const paginatedRoles: PaginatedModel<Role> = {
      items: apiPaginatedRoles.items.map((apiRole) => castApiRoleToRole(apiRole)),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedRoles.pagination),
    };

    return paginatedRoles;
  }

  static async update(id: string, updateRoleInputs: UpdateRoleInputs): Promise<Role> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateRoleInputs,
    });

    const apiRole: APIRole = response.data;

    const role = castApiRoleToRole(apiRole);

    return role;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async indexRoleUsers(
    roleId: string,
    options: Partial<IndexRoleUsersOptions>,
  ): Promise<PaginatedModel<User>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}/${roleId}/users`;
    const queryParams = {
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedUsers: APIPaginatedModel<APIUser> = response.data;

    const paginatedUsers: PaginatedModel<User> = {
      items: apiPaginatedUsers.items.map((apiUser) => castApiUserToUser(apiUser)),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedUsers.pagination),
    };

    return paginatedUsers;
  }

  static async getAllRolePermissionsByRoleId(roleId: string): Promise<Permission[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${roleId}/permissions/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPermissions: APIPermission[] = response.data;

    const permissions = apiPermissions.map((apiPermission) =>
      castApiPermissionToPermission(apiPermission),
    );

    return permissions;
  }

  static async addPermission(roleId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${roleId}/permissions/${permissionId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removePermission(roleId: string, permissionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${roleId}/permissions/${permissionId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }
}
