import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { APIError } from "@pelote/types/ApiError";
import GamesApi, { CreateOrEditGameActionInput } from "@pelote/api/GamesApi";
import { setPreviousAction } from "../pages/cesta-punta/user/codage/redux/features/previousActionReducer";
import { Action } from "../types/Action";

function useActionId(gameId?: string): {
  handleDeleteAction: (
    actionToDelete: Action,
    idActionTypeShot?: string,
    currentReceptionId?: string,
  ) => void;
  handleEditAction: (id: string, updateGameActionInputs: CreateOrEditGameActionInput) => void;
} {
  const { t } = useTranslation();

  // Handle the deletion of an action
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: deleteAction } = useMutation({
    mutationFn: (id: string) => GamesApi.deleteAction(gameId as string, id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getActions", gameId],
      });
      toast.success(t("useAction.deleteAction"), {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("useAction.deleteActionError"), {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
  });

  function handleDeleteAction(
    actionToDelete: Action,
    idActionTypeShot?: string,
    currentReceptionId?: string,
  ) {
    // If the action deleted is a shot, we must delete the reception positive associated too
    if (confirm(t("useAction.confirmDeleteAction"))) {
      if (actionToDelete.action_type_id === idActionTypeShot && currentReceptionId) {
        deleteAction(currentReceptionId);
      }
      deleteAction(actionToDelete.id);
    }
  }

  const { mutate: editAction } = useMutation({
    mutationFn: ({
      id,
      editGameActionInputs: editGameActionInputs,
    }: {
      id: string;
      editGameActionInputs: CreateOrEditGameActionInput;
    }) => GamesApi.editAction(gameId as string, id, editGameActionInputs),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getActions", gameId],
      });
      toast.success(t("useAction.editSuccess"), {
        autoClose: 3000,
        position: "bottom-right",
      });
      dispatch(setPreviousAction({ action: data }));
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("useAction.editActionError"), {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
  });

  function handleEditAction(id: string, editGameActionInputs: CreateOrEditGameActionInput) {
    editAction({ id, editGameActionInputs: editGameActionInputs });
  }

  return {
    handleDeleteAction,
    handleEditAction,
  };
}

export default useActionId;
