import "./adminHeader.scss";

interface AdminHeaderProps {
  /**
   * Text to be displayed in the header
   */
  text: string;
}

const AdminHeader = ({ text }: AdminHeaderProps) => {
  return (
    <div className="admin-header">
      <div className="title">{text}</div>
    </div>
  );
};

export default AdminHeader;
