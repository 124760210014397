import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddPlayerToGameInputs } from "@pelote/api/GamesApi";
import { Player, PlayerPosition } from "@pelote/types/Player";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import { SelectOption } from "@/ui-lib/select/Select";
import { GamePlayer } from "@/domains/pelote/types/GamePlayer";
import { getTeamSizesAndSubstitutes } from "@/domains/pelote/utils/getTeamSizesAndSubstitutes";

import "./addPlayerToGame.scss";

interface AddPlayerToGameSingle {
  player: Player;
  gamePlayers: GamePlayer[] | undefined;
  onSubmit: (playerDataToAdd: AddPlayerToGameInputs) => void;
}
getTeamSizesAndSubstitutes;

function AddPlayerToGameSingle({ player, gamePlayers, onSubmit }: AddPlayerToGameSingle) {
  // if there is a player in team1, sets the default team to team2
  let defaultTeam = 1;
  if (gamePlayers != undefined && getTeamSizesAndSubstitutes(gamePlayers).team_1_size > 0) {
    defaultTeam = 2;
  }
  const { register, handleSubmit } = useForm<AddPlayerToGameInputs>({
    defaultValues: {
      player_id: player.id,
      position: PlayerPosition.single,
      team_number: defaultTeam,
    },
  });

  const { t } = useTranslation();

  const teamOptions: SelectOption[] = [
    { label: t("team") + " 1", value: "1" },
    { label: t("team") + " 2", value: "2" },
  ];
  return (
    <div>
      <form className="add-player-to-game" onSubmit={handleSubmit(onSubmit)}>
        <input key={player.id} type="hidden" {...register("player_id")} defaultValue={player.id} />
        <FormSelect
          id="team_number"
          register={register}
          registerValueAsNumber={true}
          options={teamOptions}
        />
        <FormSubmit value={t("admin.add")} />
      </form>
    </div>
  );
}

export default AddPlayerToGameSingle;
