import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import OptionsApi, { CreateOptionInputs } from "@pelote/api/OptionsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminOptionCreate(): {
  sportPath: string | undefined;
  handleCreateOption: (createOptionInputs: CreateOptionInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Handle the creation of a option
  const queryClient = useQueryClient();
  const {
    mutate: createOption,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newOption: CreateOptionInputs) => OptionsApi.create(newOption),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexOptions"] });
      toast.success(t("admin.pelote.option.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreateOption(createOptionInputs: CreateOptionInputs) {
    if (currentSport === undefined) return;

    createOptionInputs.sport_id = currentSport.id;
    createOption(createOptionInputs);
  }

  return {
    sportPath,
    handleCreateOption,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminOptionCreate;
