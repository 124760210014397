import { LabelIdOptionId } from "@pelote/types/Action";
import { ActionType } from "@pelote/types/ActionType";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface Selections {
  isActionTypeDetailsSelected: boolean;
  isSideSelected: boolean;
  isResultSelected: boolean;
  isWallZoneSelected: boolean;
}

export function getLabelsOptionsPairs(
  id: string,
  label_option_ids: LabelIdOptionId[],
  listActionTypes: PaginatedModel<ActionType>,
): Selections {
  const selections: Selections = {
    isActionTypeDetailsSelected: false,
    isSideSelected: false,
    isResultSelected: false,
    isWallZoneSelected: false,
  };

  const actionType = listActionTypes.items.find((type) => type.id === id);

  if (actionType) {
    label_option_ids.forEach((labelOption) => {
      const label = actionType.labels.find((label) => label.id === labelOption.label_id);

      if (label) {
        if (label.name.includes("type")) {
          selections.isActionTypeDetailsSelected = true;
        } else if (label.name.includes("side")) {
          selections.isSideSelected = true;
        } else if (label.name.includes("result")) {
          selections.isResultSelected = true;
        } else if (label.name.includes("wall_zone")) {
          selections.isWallZoneSelected = true;
        }
      }
    });
  }

  return selections;
}
