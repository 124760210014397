import { RootState } from "../store";
import { useAppSelector } from "../hooks";

const UseVideoGameHook = () => {
  const game = useAppSelector((state: RootState) => state.game);
  const videos = game.videos;

  return {
    videos,
  };
};

export default UseVideoGameHook;
