import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import StagesApi, { UpdateStageInputs } from "@pelote/api/StagesApi";
import useStage from "@pelote/hooks/useStage";
import { APIError } from "@pelote/types/ApiError";
import { Stage } from "@pelote/types/Stage";

function usePageAdminStageEdit(): {
  sportPath: string | undefined;
  stage: Stage | undefined;
  handleEditStage: (updatedStage: UpdateStageInputs) => void;
  isLoading: boolean;
} {
  // Get the stage id from the route params
  const {
    seasonId,
    competitionId,
    id: stageId,
  } = useParams<{
    seasonId: string;
    competitionId: string;
    id: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the stage
  const { stage, isLoading: isStageLoading } = useStage(
    seasonId || "",
    competitionId || "",
    stageId || "",
  );

  // Handle the edition of a stage
  const queryClient = useQueryClient();
  const { mutate: updateStage } = useMutation({
    mutationFn: stage
      ? (updatedStage: UpdateStageInputs) =>
          StagesApi.update(stage.season_id, stage.competition_id, stage.id, updatedStage)
      : undefined,

    onSuccess: (data: Stage) => {
      queryClient.invalidateQueries({
        queryKey: ["showStage", data.season_id, data.competition_id, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexStages", data.season_id, data.competition_id],
      });
      toast.success(t("admin.pelote.stage.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/stages`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditStage(updatedStage: UpdateStageInputs) {
    if (currentSport === undefined) return;
    updateStage(updatedStage);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isStageLoading;

  return {
    sportPath,
    stage,
    handleEditStage,
    isLoading,
  };
}

export default usePageAdminStageEdit;
