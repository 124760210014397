import "./LinearGauge.scss";

function LinearGauge({
  value = 0,
  color = "#000000",
  width = 100,
  leftToRight = true,
}: {
  value: number;
  color: string;
  width: number;
  leftToRight: boolean;
}) {
  if (leftToRight === false) {
    return (
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width="100%"
        height="30"
        viewBox={"0 0 " + width + " 30"}
      >
        <rect
          className="bar"
          style={{
            width: "100%",
          }}
          x="0"
          rx="10"
          ry="10"
          height="20"
          fill="#888"
        />
        <rect
          className="bar"
          style={{
            width: value + "%",
          }}
          x={100 - value + "%"}
          rx="10"
          ry="10"
          height="20"
          fill={color}
        />

        <text
          x="50%"
          y="10"
          fontSize="14"
          fontWeight="bold"
          fill={"#ffffff"}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {typeof value === "number" && value.toFixed(0) + "%"}
        </text>
      </svg>
    );
  } else {
    return (
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width="100%"
        height="30"
        viewBox={"0 0 " + width + " 30"}
      >
        <rect
          className="bar"
          style={{
            width: "100%",
          }}
          x="0"
          rx="10"
          ry="10"
          height="20"
          fill="#888"
        />
        <rect
          className="bar"
          style={{ width: value + "%" }}
          x="0"
          rx="10"
          ry="10"
          height="20"
          fill={color}
        />

        <text
          x="50%"
          y="10"
          fontSize="14"
          fontWeight="bold"
          fill={"#ffffff"}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {typeof value === "number" && value.toFixed(0) + "%"}
        </text>
      </svg>
    );
  }
}

export default LinearGauge;
