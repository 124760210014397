import { Game } from "../types/Game";
import { getParsedScores } from "./getParsedScores";
import determineWinner from "./determineWinner";
import { GamePlayerPosition } from "../types/GamePlayer";
import { SetsPatterns } from "../types/Competition";

function getPlayerTeamNumber(game: Game, playerId: String) {
  return game.players[game.players.findIndex((p) => p.id == playerId)].team_number;
}

export function calculateWinrate(games: Game[], playerId: String) {
  // validGames = games that have a correct score only, where the player was not substitute
  let validGames = 0;
  let numberOfWins = 0;
  let numberOf2setWins = 0;
  let numberOf3setWins = 0;
  let winrateGlobal: number | string = "--";
  let winrate2sets: number | string = "--";
  let winrate3sets: number | string = "--";
  games.forEach((game) => {
    if (game.players.find((p) => p.id == playerId)?.position != GamePlayerPosition.substitute) {
      if (
        determineWinner(
          getParsedScores(game.score_team_1 ?? "0;0;0"),
          getParsedScores(game.score_team_2 ?? "0;0;0"),
          game.competition.sets_pattern ?? SetsPatterns.five,
        ).isWinnerTeam1 == true
      ) {
        if (getPlayerTeamNumber(game, playerId) == 1) {
          if (getParsedScores(game.score_team_1 ?? "0;0;0")[2] != 0) numberOf3setWins += 1;
          else {
            numberOf2setWins += 1;
          }
          numberOfWins += 1;
        }
        validGames += 1;
      } else if (
        determineWinner(
          getParsedScores(game.score_team_1 ?? "0;0;0"),
          getParsedScores(game.score_team_2 ?? "0;0;0"),
          game.competition.sets_pattern ?? SetsPatterns.five,
        ).isWinnerTeam2 == true
      ) {
        if (getPlayerTeamNumber(game, playerId) == 2) {
          if (getParsedScores(game.score_team_2 ?? "0;0;0")[2] != 0) numberOf3setWins += 1;
          else {
            numberOf2setWins += 1;
          }
          numberOfWins += 1;
        }
        validGames += 1;
      }
    }
  });
  if (validGames > 0) {
    winrateGlobal = parseFloat(((numberOfWins / validGames) * 100).toFixed(2));
    winrate2sets = parseFloat(((numberOf2setWins / validGames) * 100).toFixed(2));
    winrate3sets = parseFloat(((numberOf3setWins / validGames) * 100).toFixed(2));
  }
  return {
    validGames,
    numberOfWins,
    winrateGlobal,
    numberOf2setWins,
    winrate2sets,
    numberOf3setWins,
    winrate3sets,
  };
}
