import { useQuery } from "@tanstack/react-query";
import PlaygroundsApi from "@pelote/api/PlaygroundsApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Playground } from "../types/Playground";

interface usePaginatedPlaygroundsOptions {
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedPlaygrounds(options: Partial<usePaginatedPlaygroundsOptions> = {}): {
  paginatedPlaygrounds: PaginatedModel<Playground> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, skip = false } = options;

  // Fetch a list of playgrounds based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexPlaygrounds", page, pageSize],
    queryFn: () => PlaygroundsApi.index(page, pageSize),
    enabled: !skip,
  });

  return { paginatedPlaygrounds: data, isLoading, isError };
}

export default usePaginatedPlaygrounds;
