import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PoolsApi, { CreatePoolInputs } from "@pelote/api/PoolsApi";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";

function usePageAdminPoolCreate(): {
  sportPath: string | undefined;
  handleCreatePool: (createPoolInputs: CreatePoolInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  seasonSelectOptions: SelectOption[];
  competitionSelectOptions: SelectOption[];
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page: 1,
    pageSize: 10,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } = usePaginatedCompetitions({
    page: 1,
    pageSize: 10,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );
  competitionSelectOptions.unshift({ label: "------", value: "" });

  // Handle the creation of a pool
  const queryClient = useQueryClient();
  const {
    mutate: createPool,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newPool: CreatePoolInputs) => PoolsApi.create(newPool),
    onSuccess: (variables) => {
      queryClient.invalidateQueries({
        queryKey: ["indexPools", variables.season_id, variables.competition_id],
      });
      toast.success(t("admin.pelote.pool.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreatePool(createPoolInputs: CreatePoolInputs) {
    if (currentSport === undefined) return;
    createPool(createPoolInputs);
  }

  // Build the global isLoading flag
  const isLoading = isUserLoading || isCompetitionsLoading || isSeasonsLoading;

  return {
    sportPath,
    handleCreatePool,
    isLoading,
    isCreationPending,
    isCreationSuccess,
    seasonSelectOptions,
    competitionSelectOptions,
  };
}

export default usePageAdminPoolCreate;
