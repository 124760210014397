import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiSportToSport,
  castApiUserFullToUserFull,
  castApiUserToUser,
  getDefaultHeaders,
} from "./utils";
import { User, UserFull } from "@user/types/User";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Sport } from "@user/types/Sport";
import { APISport, APIUser, APIUserFull } from "./apiTypes";
import { buildUrlWithParams } from "@/utils/URL";
import { UserSettingEntityType } from "@user/types/UserSetting";

export interface CreateUserInputs {
  firstName: string;
  lastName: string;
  email: string;
  organizationId: string; // UUID
  roleId: string; // UUID
}

export interface UpdateUserInputs {
  firstName?: string;
  lastName?: string;
  email?: string;
  providerId?: string; // UUID
  isActive?: boolean;
  organizationId?: string; // UUID;
  roleId?: string; // UUID;
}

export interface IndexUserOptions {
  lastName?: string;
  organizationName?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export interface IndexUserRoles {
  page?: number;
  pageSize?: number;
}

export interface IndexUserSports {
  page?: number;
  pageSize?: number;
}

export interface CreateUserSettingInputs {
  sportId: string;
  entityType: UserSettingEntityType;
  entityId?: string;
  settingValue: string;
}

export default class UsersApi {
  static domain: string = "user";
  static resource: string = "users";

  static async create(createUserInputs: CreateUserInputs): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}`;

    const body = {
      first_name: createUserInputs.firstName,
      last_name: createUserInputs.lastName,
      email: createUserInputs.email,
      organization_id: createUserInputs.organizationId,
      role_id: createUserInputs.roleId,
    };

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiUser: APIUser = response.data;

    const user = castApiUserToUser(apiUser);

    return user;
  }

  static async show(id: string): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiUser: APIUser = response.data;

    const user = castApiUserToUser(apiUser);

    return user;
  }

  static async showMe(): Promise<UserFull> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/me`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiUserFull: APIUserFull = response.data;

    const userFull = castApiUserFullToUserFull(apiUserFull);

    return userFull;
  }

  static async index(options: Partial<IndexUserOptions> = {}): Promise<PaginatedModel<User>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
      sort_by: options.sortBy ? options.sortBy : "last_name:asc",
      last_name: options.lastName,
      organization_name: options.organizationName,
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url.toString(), {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedUsers: PaginatedModel<APIUser> = response.data;

    const paginatedUsers: PaginatedModel<User> = {
      items: apiPaginatedUsers.items.map((apiUser) => castApiUserToUser(apiUser)),
      pagination: castApiPaginationInfoToPaginationInfo(response.data.pagination),
    };

    return paginatedUsers;
  }

  static async update(id: string, updateUserInputs: UpdateUserInputs): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    const body = {
      first_name: updateUserInputs.firstName,
      last_name: updateUserInputs.lastName,
      email: updateUserInputs.email,
      provider_id: updateUserInputs.providerId,
      is_active: updateUserInputs.isActive,
      organization_id: updateUserInputs.organizationId,
      role_id: updateUserInputs.roleId,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: body,
    });

    const apiUser: APIUser = response.data;

    const user = castApiUserToUser(apiUser);

    return user;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async getAllUserSports(userId: string): Promise<Sport[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${userId}/sports/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiSports: APISport[] = response.data;

    const userSports: Sport[] = apiSports.map((apiSport) => castApiSportToSport(apiSport));

    return userSports;
  }

  static async addSport(userId: string, sportId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${userId}/sports/${sportId}`;

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async removeSport(userId: string, sportId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${userId}/sports/${sportId}`;

    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async sendWelcomeEmail(userId: string): Promise<User> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${userId}/email/welcome`;

    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });

    return response.data;
  }

  static async createUserSetting(
    userId: string,
    createUserSettingInputs: CreateUserSettingInputs,
  ): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/${userId}/settings`;

    const body = {
      sport_id: createUserSettingInputs.sportId,
      entity_type: createUserSettingInputs.entityType,
      entity_id: createUserSettingInputs.entityId,
      setting_value: createUserSettingInputs.settingValue,
    };

    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: body,
    });
  }
}
