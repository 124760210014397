import { RouteObject } from "react-router-dom";

import PageCompetitions from "@pelote/pages/cesta-punta/user/pageCompetitions/PageCompetitions";
import PageCompetitionDetails from "@pelote/pages/cesta-punta/user/pageCompetitionDetails/PageCompetitionDetails";
import PageCodage from "@pelote/pages/cesta-punta/user/codage/PageCodage";
import PageGamePdf from "@pelote/pages/cesta-punta/gamePdf/PageGamePdf";
import ReduxProvider from "@pelote/pages/cesta-punta/user/codage/redux/ReduxCodageProvider";
import PageUserPlayers from "@pelote/pages/cesta-punta/user/players/PageUserPlayers";
import PagePlayerDetails from "@pelote/pages/cesta-punta/user/players/PagePlayerDetails";
import EnsureHasPermission from "@/auth/EnsureHasPermission";
import { PermissionKey } from "@user/types/Permission";

const routesCestaPunta: RouteObject[] = [
  {
    path: "competitions",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE]}>
        <PageCompetitions />
      </EnsureHasPermission>
    ),
  },
  {
    path: "competitions/:competitionId",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE]}>
        <PageCompetitionDetails />
      </EnsureHasPermission>
    ),
  },
  {
    path: "games/:gameId/codage",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_CODING]}
      >
        <ReduxProvider>
          <PageCodage />
        </ReduxProvider>
      </EnsureHasPermission>
    ),
  },
  {
    path: "games/:gameId/gamePdf",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ANALYSIS]}
      >
        <PageGamePdf />
      </EnsureHasPermission>
    ),
  },
  {
    path: "players",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE]}>
        <PageUserPlayers />
      </EnsureHasPermission>
    ),
  },
  {
    path: "players/:playerId",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE]}>
        <PagePlayerDetails />
      </EnsureHasPermission>
    ),
  },
];

export default routesCestaPunta;
