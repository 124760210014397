import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateOrganizationInputs } from "@user/api/OrganizationsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminOrganizationCreate from "./usePageAdminOrganizationCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminOrganizationCreate.scss";

const PageAdminOrganizationCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { createOrganization, isCreationSuccess, createdOrganization } =
    usePageAdminOrganizationCreate();

  // Navigate to the organization sports page when the creation is successful
  useEffect(() => {
    if (isCreationSuccess && createdOrganization)
      navigate(`/admin/user/organizations/${createdOrganization.id}/sports?isCreation=true`);
  }, [isCreationSuccess, createdOrganization]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrganizationInputs>({
    defaultValues: {
      name: "",
    },
  });

  return (
    <div className="page-admin-organization-create">
      <AdminHeader text={t("admin.user-management.organization.create-organization")} />
      <form onSubmit={handleSubmit(createOrganization)}>
        <div className="form-inputs">
          <div className="input-name">
            <FormInput
              id="name"
              labelName={t("admin.user-management.organization.name")}
              type={InputType.text}
              textToDisplayIfError={`${t("admin.user-management.organization.name")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/user/organizations`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminOrganizationCreate;
