import { useQuery } from "@tanstack/react-query";

import PlayersApi from "@pelote/api/PlayersApi";
import { Player } from "@pelote/types/Player";

function usePlayer(playgroundId: string): {
  player?: Player;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a playground (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showPlayer", playgroundId],
    queryFn: () => PlayersApi.show(playgroundId),
  });

  return { player: data, isLoading, isError };
}

export default usePlayer;
