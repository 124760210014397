export enum PlayerPosition {
  front = "front",
  back = "back",
  single = "single",
  substitute = "substitute",
}

export type Player = {
  id: string;
  firstname: string;
  lastname: string;
  birthdate?: Date;
  nationality?: string;
  size?: number;
  weight?: number;
  favorite_position: PlayerPosition;
  laterality?: string;
  gender?: string;
  photo_url?: string;
  sport_id: string;
  team_number?: number;
};
