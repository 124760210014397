import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import GamesApi from "@pelote/api/GamesApi";
import { APIError } from "@pelote/types/ApiError";

function useValidateCoding(gameId: string): {
  validateCoding: () => void;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const { t } = useTranslation();
  const {
    mutate: validateCodingMutation,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: () => GamesApi.createMetrics(gameId),
    onSuccess: () => {
      toast.success(t(`modalValidation.validateCoding.validateCodingSuccess`), {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t(`modalValidation.validateCoding.validateCodingError`), {
        autoClose: 3000,
        position: "bottom-right",
      });
    },
  });

  function validateCoding() {
    if (gameId === undefined) {
      return;
    }
    validateCodingMutation();
  }

  return {
    validateCoding: validateCoding,
    isCreationPending,
    isCreationSuccess,
  };
}

export default useValidateCoding;
