import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaginatedModel } from "@user/types/PaginatedModel";
import usePaginatedApiRoutes from "@user/hooks/usePaginatedApiRoutes";
import { ApiRoute } from "@user/types/ApiRoute";
import ApiRoutesApi from "@user/api/ApiRoutesApi";
import { APIError } from "@user/types/ApiError";
import { useDebounce } from "@/hooks/useDebounce";
import { cacheKeyInvalidator } from "@user/api/apiCache";

function usePageAdminApiRouteList(): {
  paginatedApiRoutes?: PaginatedModel<ApiRoute>;
  filterByPath: string;
  setFilterByPath: (name: string) => void;
  resetFilterByPath: () => void;
  isLoading: boolean;
  deleteApiRoute: (id: string) => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setCurrentPageSize: (pageSize: number) => void;
  pageSize: number;
} {
  const { t } = useTranslation();

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setCurrentPageSize] = useState(10);

  // Init the filters
  const [filterByPath, setFilterByPath] = useState("");
  const debouncedFilterByPath = useDebounce(filterByPath, 300);

  // Handle filters reset
  const resetFilterByPath = () => {
    setFilterByPath("");
    setCurrentPage(1);
  };

  // Get the paginated api routes
  const { paginatedApiRoutes, isLoadingApiRoutes } = usePaginatedApiRoutes({
    page: currentPage,
    pageSize,
    path: debouncedFilterByPath,
  });

  // Handle the deletion of a api-route
  const queryClient = useQueryClient();
  const { mutate: deleteApiRouteInApi } = useMutation({
    mutationFn: (id: string) => ApiRoutesApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: cacheKeyInvalidator.apiRoute(id) });
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.apiRoutes(),
      });
      queryClient.invalidateQueries({
        predicate: (query) => cacheKeyInvalidator.organizationRoutesAll(query),
      });
      toast.success(t("admin.user-management.api-route.api-route-deletion-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user-management.api-route.api-route-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteApiRoute(id: string) {
    if (confirm(t("admin.user-management.api-route.confirm-api-route-deletion"))) {
      deleteApiRouteInApi(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isLoadingApiRoutes;

  return {
    paginatedApiRoutes,
    filterByPath,
    setFilterByPath,
    resetFilterByPath,
    deleteApiRoute,
    setCurrentPage,
    currentPage,
    setCurrentPageSize,
    pageSize,
    isLoading,
  };
}

export default usePageAdminApiRouteList;
