import { Sport } from "@user/types/Sport";
import useSports from "@user/hooks/useSports";

function usePageAdminSportList(): {
  sports: Sport[];
  isLoading: boolean;
} {
  const { sports, isLoading: isSportsLoading } = useSports();

  // Build the global isLoading flag
  const isLoading = isSportsLoading;

  return {
    sports,
    isLoading,
  };
}

export default usePageAdminSportList;
