type ButtonCodage = {
  id?: string;
  text: string;
  isSelected?: boolean;
  onClick: () => void;
  customStyles?: object;
  customStylesDisabled?: object;
  className?: string;
  disabled?: boolean;
};

function ButtonCodage({
  id,
  text,
  isSelected,
  onClick,
  customStyles,
  customStylesDisabled,
  className,
  disabled,
}: ButtonCodage) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      id={id}
      style={{
        ...(isSelected ? customStyles : disabled ? customStylesDisabled : {}),
      }}
      onClick={handleClick}
      className={className}
      disabled={disabled}
    >
      {text.toUpperCase()}
    </button>
  );
}

export default ButtonCodage;
