import { Action } from "@pelote/types/Action";
import { GamePlayer } from "@pelote/types/GamePlayer";

export type ConsecutivePasa = {
  setNumber?: number;
  actionId?: string;
};

export type ConsecutivePasasByTeam = {
  consecutivesPasasTeam1: ConsecutivePasa[];
  consecutivesPasasTeam2: ConsecutivePasa[];
};

export function getConsecutivePasasPerTeam(
  allActions: Action[],
  players: GamePlayer[],
): ConsecutivePasasByTeam {
  const consecutivePasasPerTeam: ConsecutivePasasByTeam = {
    consecutivesPasasTeam1: [],
    consecutivesPasasTeam2: [],
  };

  // Check if the action is a pasa
  const isPasa = (action: Action): boolean =>
    action.label_option_ids.some(
      (item) =>
        item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
        item.option_id === "513c7fc9-b0e4-4993-9d76-6dbd0b2e752f",
    );

  //Check all the actions to find consecutive pasas
  allActions.forEach((currentAction, index) => {
    if (index < allActions.length - 1) {
      const nextAction = allActions[index + 1];

      if (isPasa(currentAction) && isPasa(nextAction)) {
        const player1 = players.find((player) => player.id === currentAction.player_id);
        const player2 = players.find((player) => player.id === nextAction.player_id);

        if (player1 && player2 && player1.team_number === player2.team_number) {
          const consecutivePasa: ConsecutivePasa = {
            setNumber: currentAction.set_number,
            actionId: nextAction.id,
          };

          if (player1.team_number === 1) {
            consecutivePasasPerTeam.consecutivesPasasTeam1.push(consecutivePasa);
          } else if (player1.team_number === 2) {
            consecutivePasasPerTeam.consecutivesPasasTeam2.push(consecutivePasa);
          }
        }
      }
    }
  });

  return consecutivePasasPerTeam;
}
