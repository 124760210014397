import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Competition } from "@pelote/types/Competition";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminCompetitionList from "./usePageAdminCompetitionList";
import FormButton from "@/ui-lib/form-button/FormButton";
import Pagination from "@/ui-lib/pagination/Pagination";

const PageAdminCompetitionList = () => {
  const { sportPath, paginatedCompetitions, isLoading, currentPage, pageSize, setCurrentPage } =
    usePageAdminCompetitionList({
      pageSize: 10,
    });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Competition>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("description"),
    }),
    columlHelper.accessor("sets_pattern", {
      cell: (info) => info.getValue(),
      header: () => t("admin.setsPattern"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(`/admin/pelote/${sportPath}/competitions/${info.row.original.id}`)
            }
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.pelote.competition.competitions")} />
      <FormButton
        labelName={t("admin.pelote.competition.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/competitions/create`)}
      />
      <div className="competitions">
        <Table columns={columns} data={paginatedCompetitions?.items ?? []} hasGlobalFilter={true} />
        <div className="pagination">
          <Pagination
            totalItems={paginatedCompetitions?.pagination.total_items ?? 0}
            itemsPerPage={pageSize}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default PageAdminCompetitionList;
