import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { Domain } from "@user/types/Domain";
import { PaginatedModel } from "@user/types/PaginatedModel";
import {
  castApiDomainToDomain,
  castApiPaginationInfoToPaginationInfo,
  getDefaultHeaders,
} from "./utils";
import { buildUrlWithParams } from "@/utils/URL";
import { APIDomain, APIPaginatedModel } from "./apiTypes";

export interface IndexDomainOptions {
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export default class DomainsApi {
  static domain: string = "user";
  static resource: string = "domains";

  static async index(options: Partial<IndexDomainOptions> = {}): Promise<PaginatedModel<Domain>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
      sort_by: options.sortBy ? options.sortBy : "name:asc",
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedDomains: APIPaginatedModel<APIDomain> = response.data;

    const paginatedDomains: PaginatedModel<Domain> = {
      items: apiPaginatedDomains.items.map((apiDomain) => castApiDomainToDomain(apiDomain)),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedDomains.pagination),
    };

    return paginatedDomains;
  }
}
