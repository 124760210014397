import { useQuery } from "@tanstack/react-query";

import LabelsApi from "@pelote/api/LabelsApi";
import { Label } from "@pelote/types/Label";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedLabelsOptions {
  page?: number;
  pageSize?: number;
  sportId?: string;
  skip?: boolean;
}

function usePaginatedLabels(options: Partial<usePaginatedLabelsOptions> = {}): {
  paginatedLabels: PaginatedModel<Label> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, sportId, skip = false } = options;
  // Fetch a list of labels based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexLabels", page, pageSize, sportId],
    queryFn: () => LabelsApi.index(page, pageSize, sportId),
    enabled: !skip,
  });

  return { paginatedLabels: data, isLoading, isError };
}

export default usePaginatedLabels;
