import { RouteObject } from "react-router-dom";

import PageAdminCompetitionList from "@/domains/pelote/pages/cesta-punta/admin/competition/PageAdminCompetitionList";
import PageAdminCompetitionCreate from "@/domains/pelote/pages/cesta-punta/admin/competition/PageAdminCompetitionCreate";
import PageAdminCompetitionEdit from "@/domains/pelote/pages/cesta-punta/admin/competition/PageAdminCompetitionEdit";
import PageAdminSeasonList from "@/domains/pelote/pages/cesta-punta/admin/season/PageAdminSeasonList";
import LayoutPeloteAdminGameDetails from "@/domains/pelote/layout/LayoutPeloteAdminGameDetails/LayoutPeloteAdminGameDetails";
import PageAdminActionTypeCreate from "@/domains/pelote/pages/cesta-punta/admin/action-type/PageAdminActionTypeCreate.";
import PageAdminActionTypeEdit from "@/domains/pelote/pages/cesta-punta/admin/action-type/PageAdminActionTypeEdit.";
import PageAdminActionTypeList from "@/domains/pelote/pages/cesta-punta/admin/action-type/PageAdminActionTypeList";
import PageAdminGameCreate from "@/domains/pelote/pages/cesta-punta/admin/game/general/PageAdminGameCreate";
import PageAdminGameEdit from "@/domains/pelote/pages/cesta-punta/admin/game/general/PageAdminGameEdit";
import PageAdminGameList from "@/domains/pelote/pages/cesta-punta/admin/game/general/PageAdminGameList";
import PageAdminGameAddPlayers from "@/domains/pelote/pages/cesta-punta/admin/game/players/PageAdminGameAddPlayers";
import PageAdminGameListPlayers from "@/domains/pelote/pages/cesta-punta/admin/game/players/PageAdminGameListPlayers";
import PageAdminGameCreateVideo from "@/domains/pelote/pages/cesta-punta/admin/game/videos/PageAdminGameCreateVideo.";
import PageAdminGameEditVideo from "@/domains/pelote/pages/cesta-punta/admin/game/videos/PageAdminGameEditVideo";
import PageAdminGameListVideos from "@/domains/pelote/pages/cesta-punta/admin/game/videos/PageAdminGameListVideos";
import PageAdminLabelCreate from "@/domains/pelote/pages/cesta-punta/admin/label/PageAdminLabelCreate.";
import PageAdminLabelEdit from "@/domains/pelote/pages/cesta-punta/admin/label/PageAdminLabelEdit.";
import PageAdminLabelList from "@/domains/pelote/pages/cesta-punta/admin/label/PageAdminLabelList";
import PageAdminOptionCreate from "@/domains/pelote/pages/cesta-punta/admin/option/PageAdminOptionCreate";
import PageAdminOptionEdit from "@/domains/pelote/pages/cesta-punta/admin/option/PageAdminOptionEdit";
import PageAdminOptionList from "@/domains/pelote/pages/cesta-punta/admin/option/PageAdminOptionList";
import PageAdminPlayerCreate from "@/domains/pelote/pages/cesta-punta/admin/player/PageAdminPlayerCreate";
import PageAdminPlayerEdit from "@/domains/pelote/pages/cesta-punta/admin/player/PageAdminPlayerEdit";
import PageAdminPlayerList from "@/domains/pelote/pages/cesta-punta/admin/player/PageAdminPlayerList";
import PageAdminPlaygroundCreate from "@/domains/pelote/pages/cesta-punta/admin/playground/PageAdminPlaygroundCreate";
import PageAdminPlaygroundEdit from "@/domains/pelote/pages/cesta-punta/admin/playground/PageAdminPlaygroundEdit";
import PageAdminPlaygroundList from "@/domains/pelote/pages/cesta-punta/admin/playground/PageAdminPlaygroundList";
import PageAdminPoolCreate from "@/domains/pelote/pages/cesta-punta/admin/pool/PageAdminPoolCreate";
import PageAdminPoolEdit from "@/domains/pelote/pages/cesta-punta/admin/pool/PageAdminPoolEdit";
import PageAdminPoolList from "@/domains/pelote/pages/cesta-punta/admin/pool/PageAdminPoolList";
import PageAdminRoundCreate from "@/domains/pelote/pages/cesta-punta/admin/round/PageAdminRoundCreate";
import PageAdminRoundEdit from "@/domains/pelote/pages/cesta-punta/admin/round/PageAdminRoundEdit";
import PageAdminRoundList from "@/domains/pelote/pages/cesta-punta/admin/round/PageAdminRoundList";
import PageAdminSeasonCreate from "@/domains/pelote/pages/cesta-punta/admin/season/PageAdminSeasonCreate";
import PageAdminSeasonEdit from "@/domains/pelote/pages/cesta-punta/admin/season/PageAdminSeasonEdit.";
import PageAdminStageCreate from "@/domains/pelote/pages/cesta-punta/admin/stage/PageAdminStageCreate";
import PageAdminStageEdit from "@/domains/pelote/pages/cesta-punta/admin/stage/PageAdminStageEdit";
import PageAdminStageList from "@/domains/pelote/pages/cesta-punta/admin/stage/PageAdminStageList";
import PageAdminVideoList from "@/domains/pelote/pages/cesta-punta/admin/video/PageAdminVideoList";
import EnsureHasPermission from "@/auth/EnsureHasPermission";
import { PermissionKey } from "@user/types/Permission";

const routesAdminPeloteCestaPunta: RouteObject[] = [
  {
    path: "competitions",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminCompetitionList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "competitions/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminCompetitionCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "competitions/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminCompetitionEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminSeasonList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminSeasonCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminSeasonEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "stages",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminStageList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "stages/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminStageCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons/:seasonId/competitions/:competitionId/stages/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminStageEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "pools",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPoolList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "pools/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPoolCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons/:seasonId/competitions/:competitionId/pools/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPoolEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "rounds",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminRoundList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "rounds/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminRoundCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "seasons/:seasonId/competitions/:competitionId/rounds/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminRoundEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "playgrounds",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlaygroundList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "playgrounds/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlaygroundCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "playgrounds/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlaygroundEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "players",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlayerList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "players/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlayerCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "players/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminPlayerEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "action-types",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminActionTypeList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "action-types/create",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminActionTypeCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "action-types/:id",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminActionTypeEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "labels",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminLabelList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "labels/create",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminLabelCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "labels/:id",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminLabelEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "options",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminOptionList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "options/create",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminOptionCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "options/:id",
    element: (
      <EnsureHasPermission permissionKeys={[PermissionKey.FULL_ACCESS]}>
        <PageAdminOptionEdit />
      </EnsureHasPermission>
    ),
  },
  {
    path: "games",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminGameList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "games/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminGameCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "games/:id",
    element: <LayoutPeloteAdminGameDetails />,
    children: [
      {
        index: true,
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameEdit />,
          </EnsureHasPermission>
        ),
      },
      {
        path: "players",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameListPlayers />
          </EnsureHasPermission>
        ),
      },
      {
        path: "players/add",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameAddPlayers />
          </EnsureHasPermission>
        ),
      },
      {
        path: "videos",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameListVideos />
          </EnsureHasPermission>
        ),
      },
      {
        path: "videos/create",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameCreateVideo />
          </EnsureHasPermission>
        ),
      },
      {
        path: "videos/:videoId",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
          >
            <PageAdminGameEditVideo />
          </EnsureHasPermission>
        ),
      },
    ],
  },
  {
    path: "videos",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_ADMINISTRATION]}
      >
        <PageAdminVideoList />
      </EnsureHasPermission>
    ),
  },
];

export default routesAdminPeloteCestaPunta;
