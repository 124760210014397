import "./formGoBackButton.scss";

type FormGoBackButtonProps = {
  labelName: string;
  onClick?: () => void;
  disabled?: boolean;
};

function FormGoBackButton({ labelName, onClick, disabled = false }: FormGoBackButtonProps) {
  return (
    <div className="form-go-back-button">
      <button onClick={onClick} disabled={disabled} type="button">
        {labelName}
      </button>
    </div>
  );
}

export default FormGoBackButton;
