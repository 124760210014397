import "./button.scss";

type ButtonProps = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  style?: object;
};

function Button({ text, onClick, disabled, className, style }: ButtonProps) {
  return (
    <button
      className={`ui-lib-button ${className}`}
      style={{ ...style }}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {text}
    </button>
  );
}

export default Button;
