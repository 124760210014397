import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import SeasonsApi, { UpdateSeasonInputs } from "@pelote/api/SeasonsApi";
import useSeason from "@pelote/hooks/useSeason";
import { APIError } from "@pelote/types/ApiError";
import { Season } from "@pelote/types/Season";
import usePaginatedGames from "@/domains/pelote/hooks/usePaginatedGames";

function usePageAdminSeasonEdit(): {
  sportPath: string | undefined;
  season: Season | undefined;
  handleEditSeason: (updatedSeason: UpdateSeasonInputs) => void;
  handleDeleteSeason: (id: string) => void;
  disableDelete: boolean;
  isLoading: boolean;
} {
  // Get the season id from the route params
  const { id: seasonId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the season
  const { season, isLoading: isSeasonLoading } = useSeason(seasonId || "");
  let disableDelete: boolean;
  const seasonGames = usePaginatedGames({ seasonId, pageSize: 1 });
  if (
    seasonGames?.paginatedGames?.items.length != undefined &&
    seasonGames.paginatedGames.items.length > 0
  ) {
    disableDelete = true;
  } else disableDelete = false;

  // Handle the edition of a season
  const queryClient = useQueryClient();
  const { mutate: updateSeason } = useMutation({
    mutationFn: seasonId
      ? (updatedSeason: UpdateSeasonInputs) => SeasonsApi.update(seasonId, updatedSeason)
      : undefined,

    onSuccess: (data: Season) => {
      queryClient.invalidateQueries({
        queryKey: ["showSeason", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexSeasons"],
      });
      toast.success(t("admin.pelote.season.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/seasons`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditSeason(updatedSeason: UpdateSeasonInputs) {
    if (currentSport === undefined) return;
    updateSeason(updatedSeason);
  }

  // Handle the deletion of a season
  const { mutate: deleteSeason } = useMutation({
    mutationFn: (id: string) => SeasonsApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showSeason", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexSeasons"],
      });
      navigate(`/admin/pelote/${sportPath}/seasons`);
      toast.success(t("admin.pelote.season.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteSeason(id: string) {
    if (confirm(t("admin.pelote.season.confirmDeletion"))) {
      deleteSeason(id);
    }
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isSeasonLoading;

  return {
    sportPath,
    season,
    handleEditSeason,
    isLoading,
    handleDeleteSeason,
    disableDelete,
  };
}

export default usePageAdminSeasonEdit;
