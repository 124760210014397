export enum GamePlayerPosition {
  front = "front",
  back = "back",
  single = "single",
  substitute = "substitute",
}

export type GamePlayer = {
  id: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  nationality: string;
  size: number;
  weight: number;
  favorite_position: string;
  laterality: string;
  gender: string;
  photo_url: string;
  position: GamePlayerPosition;
  team_number: number;
};
