import { Fragment, useContext } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

import userContext from "@/contexts/userContext";
import logo from "@assets/logos/aiapelote_logo.png";
import HorizontalMenu, { MenuLinksHorizontal } from "@ui-lib/horizontal-menu/HorizontalMenu";
// import SportSelect from "@pelote/components/sport-select/SportSelect";
import { TranslationFlags } from "@pelote/components/translation-flags/TranslationFlags";
import { PermissionKey } from "@user/types/Permission";
import { hasPermission } from "@/auth/utils";
import { SportKey } from "@user/types/Sport";

import "./LayoutPeloteUser.scss";

const LayoutPeloteUser = () => {
  const { currentSport, permissions } = useContext(userContext);
  const { t } = useTranslation();
  const { instance } = useMsal();

  const menuLinks: MenuLinksHorizontal[] = [];

  const cestaPuntaLinks: MenuLinksHorizontal[] = [
    {
      name: `${t("pelote.layout.competitions")}`,
      path: `/cesta-punta/competitions`,
    },
    {
      name: `${t("pelote.layout.players")}`,
      path: `/cesta-punta/players`,
    },
  ];

  const adminLinks: MenuLinksHorizontal[] = [
    {
      name: `${t("administration")}`,
      path: `/admin`,
      isDisabled: !hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
        PermissionKey.USER_MANAGEMENT_ORGANIZATION,
        PermissionKey.PELOTE_ADMINISTRATION,
      ]),
    },
  ];

  if (currentSport?.key === SportKey.CESTA_PUNTA) {
    menuLinks.push(...cestaPuntaLinks);
  }
  menuLinks.push(...adminLinks);

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <Fragment>
      <div className="navbar">
        <img className="logo" src={logo} height="60px" />
        <HorizontalMenu className="menu" links={menuLinks} />
        {/* <SportSelect className="sport-select" /> */}
        <TranslationFlags />
        <button style={{ color: "white", margin: "1em" }} onClick={handleLogoutRedirect}>
          Déconnexion
        </button>
      </div>
      <div className="margin-top-content">
        <Outlet />
      </div>
    </Fragment>
  );
};

export default LayoutPeloteUser;
