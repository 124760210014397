import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateOptionInputs } from "@pelote/api/OptionsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminOptionEdit from "./usePageAdminOptionEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

const PageAdminOptionEdit = () => {
  const { option, handleEditOption, isLoading } = usePageAdminOptionEdit();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateOptionInputs>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    setValue("name", option?.name || "");
  }, [option]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("option")} ${option?.name}`} />
      <form onSubmit={handleSubmit(handleEditOption)}>
        <div className="page-options-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="page-options-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/options`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminOptionEdit;
