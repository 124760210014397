import { useQuery } from "@tanstack/react-query";

import { Role } from "@user/types/Role";
import RolesApi from "@user/api/RolesApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKeyBuilder } from "../api/apiCache";

interface useRolesOptions {
  organizationId?: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedRoles(options: Partial<useRolesOptions> = {}): {
  paginatedRoles: PaginatedModel<Role> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, organizationId, skip } = options;

  // Fetch a list of Roles based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const {
    data: paginatedRoles,
    isLoading,
    isError,
  } = useQuery({
    queryKey: cacheKeyBuilder.paginatedRoles(page, pageSize, organizationId),
    queryFn: () => RolesApi.index({ page, pageSize, organizationId }),
    enabled: !skip,
  });

  return {
    paginatedRoles,
    isLoading,
    isError,
  };
}

export default usePaginatedRoles;
