import { SelectOption } from "@/ui-lib/select/Select";

/**
 * Converts an array of items into an array of SelectOption objects.
 *
 * @param items - The array of items to convert. Each item is an object.
 * @param valueKey - The key to use for the 'value' property of the SelectOption objects.
 * @param labelKey - The key to use for the 'label' property of the SelectOption objects.
 * @returns An array of SelectOption objects. Each SelectOption object has a 'value' property and a 'label' property.
 */
export function convertItemsToSelectOptions(
  items: any[],
  valueKey: string,
  labelKey: string,
): SelectOption[] {
  // Map over the items array. For each item, return a new object.
  return items.map((item) => ({
    // The 'value' property is set to the value of the item's property that has the key specified by valueKey.
    value: item[valueKey],
    // The 'label' property is set to the value of the item's property that has the key specified by labelKey.
    label: item[labelKey],
  }));
}
