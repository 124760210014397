/**
 * This file contains the MSAL.js configuration and the MSAL instance.
 *
 * Docs: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

import {
  adb2cSignIn,
  adb2cSignInAuthority,
  adb2cAuthorityDomain,
  adb2cMultisportsFrontClientId,
  multisportsApiUrl,
  apiCrudScopeUrl,
} from "@/config";
import { ErrorGetAccessToken } from "@/utils/error";
import { PublicClientApplication, Configuration, LogLevel } from "@azure/msal-browser";

const b2cPolicies = {
  names: {
    signIn: adb2cSignIn,
  },
  authorities: {
    signIn: {
      authority: adb2cSignInAuthority,
    },
  },
  authorityDomain: adb2cAuthorityDomain,
};

const msalConfig: Configuration = {
  auth: {
    clientId: adb2cMultisportsFrontClientId,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin, // The redirect URI has to be registered in the Azure portal
    postLogoutRedirectUri: window.location.origin, // The redirect URI has to be registered in the Azure portal
    navigateToLoginRequestUrl: false, // Workaround to prevent a redirect loop
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    // This is the logger configuration
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          default:
            return;
        }
      },
    },
  },
};

const protectedResources = {
  multisportsAPI: {
    endpoint: multisportsApiUrl,
    scopes: {
      crud: [apiCrudScopeUrl],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const apisScopes = [...protectedResources.multisportsAPI.scopes.crud];

class MSALManager {
  private instance: PublicClientApplication;

  public constructor() {
    this.instance = new PublicClientApplication(msalConfig);
  }

  public getInstance() {
    return this.instance;
  }

  public async getAccessToken() {
    const activeAccount = this.instance.getActiveAccount();
    const accounts = this.instance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      throw new ErrorGetAccessToken("User is not signed in");
    }

    const request = {
      scopes: protectedResources.multisportsAPI.scopes.crud,
      account: activeAccount || accounts[0],
    };

    const authResult = await this.instance.acquireTokenSilent(request).catch((error) => {
      throw new ErrorGetAccessToken("Error acquiring the token silently", error);
    });

    return authResult.accessToken;
  }
}

// Create an instance of MSAL.js handled as a singleton
const msalManager = new MSALManager();

export { apisScopes, msalManager as default };
