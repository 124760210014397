import { useQuery } from "@tanstack/react-query";

import CompetitionsApi from "@pelote/api/CompetitionsApi";
import { Competition } from "@pelote/types/Competition";

function useCompetition(competitionId?: string): {
  competition?: Competition;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a competition
  const {
    data: competitionData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["showCompetition", competitionId],
    queryFn: () => {
      if (!competitionId) return;
      return CompetitionsApi.show(competitionId);
    },
    enabled: !!competitionId,
  });

  return {
    competition: competitionData,
    isLoading,
    isError,
  };
}

export default useCompetition;
