import { useTranslation } from "react-i18next";

import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";

export function nationalityList() {
  const { t } = useTranslation();
  const nationalityOptions = convertItemsToSelectOptions(
    [
      { id: "french", name: t("admin.nationality.french") },
      { id: "spanish", name: t("admin.nationality.spanish") },
      { id: "american", name: t("admin.nationality.american") },
      { id: "argentinian", name: t("admin.nationality.argentinian") },
      { id: "other", name: t("admin.nationality.other") },
    ],
    "id",
    "name",
  );
  return nationalityOptions;
}
