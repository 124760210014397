import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateGameInputs } from "@pelote/api/GamesApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminGameCreate from "./usePageAdminGameCreate";
import Loader from "@pelote/components/loader/Loader";
import { CodingStatus, GameTeamFormat } from "@pelote/types/Game";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminGameCreate.scss";

const PageAdminGameCreate = () => {
  const {
    sportPath,
    handleCreateGame,
    isCreationSuccess,
    isLoading,
    seasonSelectOptions,
    stageSelectOptions,
    competitionSelectOptions,
    poolSelectOptions,
    roundSelectOptions,
    playgroundSelectOptions,
    createdGameId,
    colorSelectOptions,
    handleChangeCompetitionOption,
    handleChangeSeasonOption,
  } = usePageAdminGameCreate();

  // Navigate to the list of games when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess && createdGameId != null)
      navigate(`/admin/pelote/${sportPath}/games/${createdGameId}/players`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateGameInputs>({
    defaultValues: {
      name: "",
      description: "",
      team_format: GameTeamFormat.double,
      coding_status: CodingStatus.todo,
      start_time: new Date(),
      season_id: seasonSelectOptions?.[0]?.value as string,
      competition_id: competitionSelectOptions?.[0]?.value as string,
      stage_id: stageSelectOptions?.[0]?.value as string,
      pool_id: poolSelectOptions?.[0]?.value as string,
      round_id: roundSelectOptions?.[0]?.value as string,
      playground_id: playgroundSelectOptions?.[0]?.value as string,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.pelote.game.creation")} />
      <form onSubmit={handleSubmit(handleCreateGame)}>
        <div className="create-game-form-container">
          <div className="create-game-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="create-game-start_time">
            <FormInput
              id="start_time"
              labelName={t("date")}
              type={InputType.dateTimeLocal}
              textToDisplayIfError={t("admin.necessary.date")}
              errorMessage={errors.start_time?.message}
              register={register}
            />
          </div>
          <div className="create-game-team_format">
            <FormSelect
              id="team_format"
              labelName={t("format")}
              options={Object.values(GameTeamFormat).map((option) => ({
                value: option,
                label: option,
              }))}
              textToDisplayIfError={t("admin.necessary.format")}
              errorMessage={errors.team_format?.message}
              register={register}
            />
          </div>
          <div className="create-game-coding_status">
            <FormSelect
              id="coding_status"
              labelName={t("admin.codingStatus")}
              options={Object.values(CodingStatus).map((option) => ({
                value: option,
                label: option,
              }))}
              textToDisplayIfError={t("admin.necessary.codingStatus")}
              errorMessage={errors.coding_status?.message}
              register={register}
            />
          </div>
          <div className="create-game-playground_id">
            <FormSelect
              id="playground_id"
              labelName={t("playground")}
              options={playgroundSelectOptions}
              textToDisplayIfError={t("admin.necessary.playground")}
              errorMessage={errors.playground_id?.message}
              register={register}
            />
          </div>
          {seasonSelectOptions && (
            <div className="create-game-season_id">
              <FormSelect
                id="season_id"
                labelName={t("season")}
                options={seasonSelectOptions}
                textToDisplayIfError={t("admin.necessary.season")}
                errorMessage={errors.season_id?.message}
                register={register}
                onChange={handleChangeSeasonOption}
              />
            </div>
          )}
          {competitionSelectOptions && (
            <div className="create-game-competition_id">
              <FormSelect
                id="competition_id"
                labelName={t("competition")}
                options={competitionSelectOptions}
                textToDisplayIfError={t("admin.necessary.competition")}
                errorMessage={errors.competition_id?.message}
                register={register}
                onChange={handleChangeCompetitionOption}
              />
            </div>
          )}
          {roundSelectOptions && (
            <div className="create-game-round_id">
              <FormSelect
                id="round_id"
                labelName={t("round")}
                options={roundSelectOptions}
                textToDisplayIfError={t("admin.necessary.round")}
                errorMessage={errors.round_id?.message}
                register={register}
              />
            </div>
          )}
          <div className="create-game-stage-pool">
            {stageSelectOptions && stageSelectOptions.length > 1 && (
              <div>
                <FormSelect
                  id="stage_id"
                  labelName={t("stage")}
                  options={stageSelectOptions}
                  register={register}
                />
              </div>
            )}
            {poolSelectOptions && poolSelectOptions.length > 1 && (
              <div>
                <FormSelect
                  id="pool_id"
                  labelName={t("pool")}
                  options={poolSelectOptions}
                  register={register}
                />
              </div>
            )}
          </div>
          <div className="create-game-description">
            <FormInput
              id="description"
              labelName={t("description")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="create-game-score-1">
            <FormInput
              id="score_team_1"
              labelName={"Score " + t("team") + " 1"}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="create-game-score-2">
            <FormInput
              id="score_team_2"
              labelName={"Score " + t("team") + " 2"}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="create-game-color-1">
            <FormSelect
              id="color_team_1"
              labelName={t("admin.color.color") + " " + t("team") + " 1"}
              options={colorSelectOptions}
              register={register}
            />
          </div>
          <div className="create-game-color-2">
            <FormSelect
              id="color_team_2"
              labelName={t("admin.color.color") + " " + t("team") + " 2"}
              options={colorSelectOptions}
              register={register}
            />
          </div>
        </div>
        <div className="create-game-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/games`)}
          />
          <FormSubmit value={t("admin.pelote.game.saveAddPlayers")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminGameCreate;
