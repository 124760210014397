import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { OrganizationSetting } from "@user/types/OrganizationSetting";
import OrganizationsApi, { CreateOrganizationSettingInputs } from "@user/api/OrganizationsApi";
import { APIError } from "@user/types/ApiError";
import { cacheKeyInvalidator } from "../api/apiCache";

interface useOrganizationSettingOptions {
  skip?: boolean; // Deactivate fetching the organization setting
}

function useOrganizationSetting(
  // organizationSettingId?: string,
  options: Partial<useOrganizationSettingOptions> = {},
): {
  organizationSetting: OrganizationSetting | undefined;
  createOrganizationSetting: (
    organizationId: string,
    inputs: CreateOrganizationSettingInputs,
  ) => void;
  isCreateOrganizationSettingLoading: boolean;
  isCreateOrganizationSettingError: boolean;
  deleteOrganizationSetting: (organizationId: string, organizationSettingId: string) => void;
  isDeleteOrganizationSettingLoading: boolean;
  isDeleteOrganizationSettingError: boolean;
} {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { skip = false } = options;

  // Fetch the organization setting
  if (!skip) {
    // Not implemented yet
  }

  // Create the organization setting
  const {
    mutate: createOrganizationSettingInAPI,
    isPending: isCreateOrganizationSettingPending,
    isError: isCreateOrganizationSettingError,
  } = useMutation({
    mutationFn: (inputs: {
      organizationId: string;
      settingInputs: CreateOrganizationSettingInputs;
    }) => {
      return OrganizationsApi.createOrganizationSetting(
        inputs.organizationId,
        inputs.settingInputs,
      );
    },
    onSuccess: (data: OrganizationSetting) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationSettings(data.organizationId),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), { autoClose: 3000 });
    },
  });
  function createOrganizationSetting(
    organizationId: string,
    settingInputs: CreateOrganizationSettingInputs,
  ) {
    createOrganizationSettingInAPI({ organizationId, settingInputs });
  }

  // Delete the organization setting
  const {
    mutate: deleteOrganizationSettingInAPI,
    isPending: isDeleteOrganizationSettingPending,
    isError: isDeleteOrganizationSettingError,
  } = useMutation({
    mutationFn: (inputs: { organizationId: string; organizationSettingId: string }) => {
      return OrganizationsApi.deleteOrganizationSetting(
        inputs.organizationId,
        inputs.organizationSettingId,
      );
    },
    onSuccess: (_, variable) => {
      queryClient.invalidateQueries({
        queryKey: cacheKeyInvalidator.organizationSettings(variable.organizationId),
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("common.oops-the-operation-failed"), { autoClose: 3000 });
    },
  });
  function deleteOrganizationSetting(organizationId: string, organizationSettingId: string) {
    deleteOrganizationSettingInAPI({ organizationId, organizationSettingId });
  }

  return {
    organizationSetting: undefined,
    createOrganizationSetting,
    isCreateOrganizationSettingLoading: isCreateOrganizationSettingPending,
    isCreateOrganizationSettingError,
    deleteOrganizationSetting,
    isDeleteOrganizationSettingLoading: isDeleteOrganizationSettingPending,
    isDeleteOrganizationSettingError,
  };
}

export default useOrganizationSetting;
