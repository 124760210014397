/**
 * Base error class to extend from. Intended to be used as a base class for custom errors.
 */
class BaseError extends Error {
  originalError?: Error;

  constructor(message: string, originalError?: Error) {
    super(message); // Call the parent class constructor with the message
    this.originalError = originalError;

    // Attach the original error stack to the current one if available
    if (originalError) {
      this.stack += `\nCaused by: ${originalError.stack}`;
    }

    // Fix the prototype chain if using TypeScript's strict mode or targeting older JS engines
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Error thrown when the access token cannot be retrieved.
 *
 * It happens when the user is not signed in or when the token acquisition fails.
 */
class ErrorGetAccessToken extends BaseError {
  constructor(message: string, originalError?: Error) {
    super(message, originalError); // Call the parent class constructor with the message
    this.name = "ErrorGetAccessToken"; // Custom error name

    // Fix the prototype chain if using TypeScript's strict mode or targeting older JS engines
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export { ErrorGetAccessToken };
