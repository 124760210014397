import { appEnv, multisportsApiGatewayKey } from "@/config";
import msalManager from "@/auth/msalManager";

async function getDefaultHeaders() {
  const accessToken = await msalManager.getAccessToken();

  return {
    "Ocp-Apim-Subscription-Key": multisportsApiGatewayKey,
    Authorization: `Bearer ${accessToken}`,
    "x-application-name": "multisports-front-pelote",
    "x-env": appEnv,
  };
}

export { getDefaultHeaders };
