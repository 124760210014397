import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import ActionTypesApi, { UpdateActionTypeInputs } from "@pelote/api/ActionTypesApi";
import useActionType from "@pelote/hooks/useActionType";
import { APIError } from "@pelote/types/ApiError";
import { ActionType } from "@pelote/types/ActionType";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedLabels from "@pelote/hooks/usePaginatedLabels";

function usePageAdminActionTypeEdit(): {
  sportPath: string | undefined;
  actionType: ActionType | undefined;
  isLoading: boolean;
  nonAttachedLabels: SelectOption[];
  attachedLabels: SelectOption[];
  handleEditActionType: (updatedActionType: UpdateActionTypeInputs) => void;
  handleAddLabelToActionType: (label: SelectOption) => void;
  handleRemoveLabelFromActionType: (label: SelectOption) => void;
} {
  // Get the actionType id from the route params
  const { id: actionTypeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the actionType
  const { actionType, isLoading: isActionTypeLoading } = useActionType(actionTypeId || "");

  // Handle the edition of a actionType
  const queryClient = useQueryClient();
  const { mutate: updateActionType } = useMutation({
    mutationFn: actionTypeId
      ? (updatedActionType: UpdateActionTypeInputs) =>
          ActionTypesApi.update(actionTypeId, updatedActionType)
      : undefined,

    onSuccess: (data: ActionType) => {
      queryClient.invalidateQueries({
        queryKey: ["showActionType", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexActionTypes"],
      });
      toast.success(t("admin.pelote.actiontype.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/action-types`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edit"), {
        autoClose: 3000,
      });
    },
  });

  //Handle the addition of a label to an action type
  const QueryClientAddLabelToActionType = useQueryClient();
  const { mutate: addLabelToActionType } = useMutation({
    mutationFn: actionTypeId
      ? (labelId: string) => ActionTypesApi.addLabel(actionTypeId, labelId)
      : undefined,

    onSuccess: () => {
      QueryClientAddLabelToActionType.invalidateQueries({
        queryKey: ["showActionType", actionTypeId],
      });
      QueryClientAddLabelToActionType.invalidateQueries({
        queryKey: ["indexActionTypes"],
      });
      toast.success(t("admin.pelote.actiontype.labelAdded"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.addingLabel"), {
        autoClose: 3000,
      });
    },
  });

  // Handle the remove of a label from an action type
  const QueryClientRemoveLabelFromActionType = useQueryClient();
  const { mutate: removeLabelFromActionType } = useMutation({
    mutationFn: actionTypeId
      ? (labelId: string) => ActionTypesApi.removeLabel(actionTypeId, labelId)
      : undefined,

    onSuccess: () => {
      QueryClientRemoveLabelFromActionType.invalidateQueries({
        queryKey: ["showActionType", actionTypeId],
      });
      QueryClientRemoveLabelFromActionType.invalidateQueries({
        queryKey: ["indexActionTypes"],
      });
      toast.success(t("admin.pelote.actiontype.labelRemoved"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.removingLabel"), {
        autoClose: 3000,
      });
    },
  });

  // Fetch all the labels of the current sport
  const { paginatedLabels, isLoading: isLabelsLoading } = usePaginatedLabels({
    page: 1,
    pageSize: 100,
    sportId: currentSport?.id,
  });

  const allLabels = convertItemsToSelectOptions(paginatedLabels?.items || [], "id", "name");

  // Get the labels attached to the action type
  const attachedLabels = convertItemsToSelectOptions(actionType?.labels || [], "id", "name");

  // Filter the labels to keep only the non attached ones

  const nonAttachedLabels = allLabels.filter(
    (label) => !attachedLabels.some((attachedLabel) => attachedLabel.value === label.value),
  );

  function handleEditActionType(updatedActionType: UpdateActionTypeInputs) {
    if (currentSport === undefined) return;
    updateActionType(updatedActionType);
  }

  function handleAddLabelToActionType(label: SelectOption) {
    addLabelToActionType(label.value as string);
  }

  function handleRemoveLabelFromActionType(label: SelectOption) {
    removeLabelFromActionType(label.value as string);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isActionTypeLoading || isLabelsLoading;

  return {
    sportPath,
    actionType,
    isLoading,
    nonAttachedLabels,
    attachedLabels,
    handleEditActionType,
    handleAddLabelToActionType,
    handleRemoveLabelFromActionType,
  };
}

export default usePageAdminActionTypeEdit;
