import { useQuery } from "@tanstack/react-query";

import GamesApi from "@pelote/api/GamesApi";

export type ValidationErrors = {
  missing_labels: string[];
  validator: [
    {
      error_code: string;
      action_id: string;
      action_type: string;
      player_id: string;
      player_name: string;
      player_position: string;
      point_number: number;
      set_number: number;
    },
  ];
};

function useValidateActions(gameId: string): {
  validationErrors?: ValidationErrors | undefined;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
} {
  // Fetch a validationErrors (triggered at the opening of the hook)
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [],
    queryFn: () => GamesApi.validateActions(gameId),
  });

  return { validationErrors: data, isLoading, isError, refetch };
}

export default useValidateActions;
