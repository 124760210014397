import { useQuery } from "@tanstack/react-query";

import StagesApi from "@pelote/api/StagesApi";
import { Stage } from "@pelote/types/Stage";

function useStage(
  seasonId: string,
  competitionId: string,
  stageId: string,
): {
  stage?: Stage;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a stage (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showStage", seasonId, competitionId, stageId],
    queryFn: () => StagesApi.show(seasonId, competitionId, stageId),
  });

  return { stage: data, isLoading, isError };
}

export default useStage;
