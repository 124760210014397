import { useQuery } from "@tanstack/react-query";
import PlaygroundsApi from "@pelote/api/PlaygroundsApi";
import { Playground } from "@pelote/types/Playground";

function usePlayground(playgroundId: string): {
  playground?: Playground;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a playground (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showPlayground", playgroundId],
    queryFn: () => PlaygroundsApi.show(playgroundId),
  });

  return { playground: data, isLoading, isError };
}

export default usePlayground;
