import { Action } from "@pelote/types/Action";
import { ActionType } from "@pelote/types/ActionType";
import { GamePlayer, GamePlayerPosition } from "@pelote/types/GamePlayer";
import { Label } from "@pelote/types/Label";
import { Option } from "@pelote/types/Option";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { getLabelsOptionsPairs } from "@pelote/utils/getLabelsOptionsPairs";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Coordinate } from "recharts/types/util/types";

export type SelectionCodage = {
  player: boolean;
  actionType: boolean;
  typeReception: boolean;
  typeShot: boolean;
  typeSevice: boolean;
  side: boolean;
  result: boolean;
  wallZone: boolean;
  coordinates: boolean;
};

export type CurrentAction = {
  editMode: boolean;
  action: Action;
  actionReceptionPositive: Action;
  isSelected: SelectionCodage;
  player: GamePlayer;
  setNumberSelected: number;
};

const initialState = {
  editMode: false,
  action: {
    id: "",
    game_id: "",
    action_type_id: "",
    player_id: "",
    click_time: 0,
    label_option_ids: [
      {
        label_id: "",
        option_id: "",
      },
    ],
    coordinates: {
      x: 0,
      y: 0,
    },
    set_number: 1,
  },
  actionReceptionPositive: {
    id: "",
    game_id: "",
    action_type_id: "",
    player_id: "",
    click_time: 0,
    label_option_ids: [
      {
        label_id: "",
        option_id: "",
      },
    ],
    coordinates: {
      x: 0,
      y: 0,
    },
    set_number: 1,
  },
  isSelected: {
    player: false,
    actionType: false,
    typeReception: false,
    typeShot: false,
    typeSevice: false,
    side: false,
    result: false,
    wallZone: false,
    coordinates: false,
  },
  player: {
    id: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    nationality: "",
    size: 0,
    weight: 0,
    favorite_position: "",
    laterality: "",
    gender: "",
    photo_url: "",
    position: GamePlayerPosition.front,
    team_number: 0,
  },
  setNumberSelected: 1,
};

export const currentActionSlice = createSlice({
  name: "currentAction",
  initialState,
  reducers: {
    setPlayer: (
      state: CurrentAction,
      action: PayloadAction<{
        idPlayer: string;
        player: GamePlayer;
        currentTime: number;
      }>,
    ) => {
      const { idPlayer, player } = action.payload;
      state.action.player_id = idPlayer;
      state.actionReceptionPositive.player_id = idPlayer;
      state.player = player;
      state.action.click_time = action.payload.currentTime;
      state.actionReceptionPositive.click_time = action.payload.currentTime;
      state.isSelected.player = true;
    },
    setActionTypeIdAndLabelsOptions: (
      state: CurrentAction,
      action: PayloadAction<{
        actionType: ActionType;
        actionIdReception?: string;
      }>,
    ) => {
      if (action.payload.actionIdReception && action.payload.actionType.name === "shot") {
        // Here two actionId in order to post to cosmos two actions
        state.action.action_type_id = action.payload.actionType.id;
        state.actionReceptionPositive.action_type_id = action.payload.actionIdReception;
        state.action.label_option_ids = [];
        state.actionReceptionPositive.label_option_ids = [];
        state.isSelected = {
          player: state.isSelected.player,
          actionType: true,
          typeSevice: false,
          typeShot: false,
          typeReception: false,
          side: false,
          result: false,
          wallZone: false,
          coordinates: state.isSelected.coordinates,
        };
      } else if (
        action.payload.actionType.name === "reception" ||
        action.payload.actionType.name === "service"
      ) {
        // Here we initialize only the actionId of reception or service
        state.action.action_type_id = action.payload.actionType.id;
        state.actionReceptionPositive.action_type_id = "";
        state.action.label_option_ids = [];
        state.actionReceptionPositive.label_option_ids = [];
        state.isSelected = {
          player: state.isSelected.player,
          actionType: true,
          typeSevice: false,
          typeShot: false,
          typeReception: false,
          side: false,
          result: false,
          wallZone: false,
          coordinates: state.isSelected.coordinates,
        };
      }
    },
    setType: (
      state: CurrentAction,
      action: PayloadAction<{
        idType: string;
        option: Option;
        label: Label;
        isShot?: boolean;
      }>,
    ) => {
      const { option, label, idType, isShot } = action.payload;
      if (isShot) {
        if (label.name === "type_shot") {
          state.isSelected.typeShot = true;
          const existingItem = state.action.label_option_ids.find(
            (item) => item.label_id === idType,
          );
          if (existingItem) {
            existingItem.option_id = option.id;
          } else {
            state.action.label_option_ids.push({
              label_id: label.id,
              option_id: option.id,
            });
          }
        }
        if (label.name === "type_reception") {
          state.isSelected.typeReception = true;
          const existingItem = state.actionReceptionPositive.label_option_ids.find(
            (item) => item.label_id === idType,
          );
          if (existingItem) {
            existingItem.option_id = option.id;
          } else {
            state.actionReceptionPositive.label_option_ids.push({
              label_id: label.id,
              option_id: option.id,
            });
          }
        }
      } else {
        const existingItem = state.action.label_option_ids.find((item) => item.label_id === idType);
        // Affichage du résultat
        if (existingItem) {
          existingItem.option_id = option.id;
        } else {
          state.action.label_option_ids.push({
            label_id: label.id,
            option_id: option.id,
          });
        }
        if (label.name === "type_service") {
          state.isSelected.typeSevice = true;
        } else if (label.name === "type_reception") {
          state.isSelected.typeReception = true;
        }
      }
    },
    setSide: (
      state: CurrentAction,
      action: PayloadAction<{
        idSide: string;
        option: Option;
        label: Label;
        isShot?: boolean;
      }>,
    ) => {
      const { option, label, idSide, isShot } = action.payload;
      if (isShot) {
        const existingItem = state.actionReceptionPositive.label_option_ids.find(
          (item) => item.label_id === idSide,
        );
        // Affichage du résultat
        if (existingItem) {
          existingItem.option_id = option.id;
        } else {
          state.actionReceptionPositive.label_option_ids.push({
            label_id: label.id,
            option_id: option.id,
          });
        }
      }
      state.isSelected.side = true;
      const existingItem = state.action.label_option_ids.find((item) => item.label_id === idSide);
      // Affichage du résultat
      if (existingItem) {
        existingItem.option_id = option.id;
      } else {
        state.action.label_option_ids.push({
          label_id: label.id,
          option_id: option.id,
        });
      }
    },
    setResult: (
      state: CurrentAction,
      action: PayloadAction<{
        idResult: string;
        option: Option;
        label: Label;
        isShot?: boolean;
      }>,
    ) => {
      const { option, label, idResult, isShot } = action.payload;
      if (isShot) {
        state.isSelected.result = true;
        const existingItem = state.actionReceptionPositive.label_option_ids.find(
          (item) => item.label_id === idResult,
        );
        // Affichage du résultat
        if (existingItem) {
          existingItem.option_id = option.id;
        } else {
          state.actionReceptionPositive.label_option_ids.push({
            label_id: label.id,
            option_id: option.id,
          });
        }
      } else {
        state.isSelected.result = true;
        const existingItem = state.action.label_option_ids.find(
          (item) => item.label_id === idResult,
        );
        // Affichage du résultat
        if (existingItem) {
          existingItem.option_id = option.id;
        } else {
          state.action.label_option_ids.push({
            label_id: label.id,
            option_id: option.id,
          });
        }
      }
    },
    setWallZone: (
      state: CurrentAction,
      action: PayloadAction<{
        idWallZone: string;
        option: Option;
        label: Label;
      }>,
    ) => {
      const { option, label, idWallZone } = action.payload;
      const existingItem = state.action.label_option_ids.find(
        (item) => item.label_id === idWallZone,
      );
      // Affichage du résultat
      if (existingItem) {
        existingItem.option_id = option.id;
      } else {
        state.action.label_option_ids.push({
          label_id: label.id,
          option_id: option.id,
        });
      }
      state.isSelected.wallZone = true;
    },
    setCoordinates: (
      state: CurrentAction,
      action: PayloadAction<{
        coordinates: Coordinate;
      }>,
    ) => {
      const { coordinates } = action.payload;
      state.action.coordinates = coordinates;
      state.actionReceptionPositive.coordinates = coordinates;
      state.isSelected.coordinates = true;
    },
    setSetsGame: (
      state: CurrentAction,
      action: PayloadAction<{
        setNumber: number;
      }>,
    ) => {
      state.action.set_number = action.payload.setNumber;
      state.setNumberSelected = action.payload.setNumber;
    },
    setEditMode: (
      state: CurrentAction,
      action: PayloadAction<{
        editMode: boolean;
        actionToEditOrDelete: Action;
        listActionTypes: PaginatedModel<ActionType>;
      }>,
    ) => {
      const { editMode, actionToEditOrDelete, listActionTypes } = action.payload;
      // Convert EditMode to True
      state.editMode = editMode;

      state.action.id = actionToEditOrDelete.id;
      state.action.game_id = actionToEditOrDelete.game_id;
      state.action.click_time = actionToEditOrDelete.click_time;

      state.action.player_id = actionToEditOrDelete.player_id;
      state.isSelected.player = true;

      state.action.action_type_id = actionToEditOrDelete.action_type_id;
      state.isSelected.actionType = true;

      state.action.coordinates.x = actionToEditOrDelete.coordinates.x;
      state.action.coordinates.y = actionToEditOrDelete.coordinates.y;

      state.isSelected.coordinates = true;
      state.action.set_number = actionToEditOrDelete.set_number;
      state.setNumberSelected = actionToEditOrDelete.set_number;

      const foundLabelOptions = getLabelsOptionsPairs(
        actionToEditOrDelete.action_type_id,
        actionToEditOrDelete.label_option_ids,
        listActionTypes,
      );
      if (foundLabelOptions) {
        state.isSelected.typeShot = foundLabelOptions.isActionTypeDetailsSelected;
        state.isSelected.result = foundLabelOptions.isResultSelected;
        state.isSelected.side = foundLabelOptions.isSideSelected;
        state.isSelected.wallZone = foundLabelOptions.isWallZoneSelected;
        state.isSelected.typeSevice = foundLabelOptions.isActionTypeDetailsSelected;
        state.isSelected.typeReception = foundLabelOptions.isActionTypeDetailsSelected;
      }

      state.action.label_option_ids = actionToEditOrDelete.label_option_ids;
    },
    setEditModeReceptionPositive: (
      state: CurrentAction,
      action: PayloadAction<{
        actionReceptionPositiveToEditOrDelete: Action;
        listActionTypes: PaginatedModel<ActionType>;
      }>,
    ) => {
      // This dispatch will be only activated for reception Positive on edit of action shot
      const { actionReceptionPositiveToEditOrDelete, listActionTypes } = action.payload;
      state.actionReceptionPositive.id = actionReceptionPositiveToEditOrDelete.id;
      state.actionReceptionPositive.game_id = actionReceptionPositiveToEditOrDelete.game_id;
      state.actionReceptionPositive.click_time = actionReceptionPositiveToEditOrDelete.click_time;
      state.actionReceptionPositive.player_id = actionReceptionPositiveToEditOrDelete.player_id;
      state.actionReceptionPositive.action_type_id =
        actionReceptionPositiveToEditOrDelete.action_type_id;
      state.actionReceptionPositive.coordinates.x =
        actionReceptionPositiveToEditOrDelete.coordinates.x;
      state.actionReceptionPositive.coordinates.y =
        actionReceptionPositiveToEditOrDelete.coordinates.y;
      state.actionReceptionPositive.set_number = actionReceptionPositiveToEditOrDelete.set_number;
      const foundLabelOptions = getLabelsOptionsPairs(
        actionReceptionPositiveToEditOrDelete.action_type_id,
        actionReceptionPositiveToEditOrDelete.label_option_ids,
        listActionTypes,
      );
      if (foundLabelOptions) {
        state.isSelected.typeReception = foundLabelOptions.isActionTypeDetailsSelected;
        state.isSelected.side = foundLabelOptions.isSideSelected;
        state.isSelected.result = foundLabelOptions.isResultSelected;
      }
      state.actionReceptionPositive.label_option_ids =
        actionReceptionPositiveToEditOrDelete.label_option_ids;
    },
    resetPanelActionsState: (state: CurrentAction) => {
      // Reset all the state except the set that I want to be selected all the time
      // Probably work on previous action to keep the set number after editmode
      const stateInitialPlusSet = {
        ...initialState,
        setNumberSelected: state.setNumberSelected,
        action: {
          ...state.action,
          ...state.actionReceptionPositive,
          set_number: state.setNumberSelected,
        },
      };
      return stateInitialPlusSet;
    },
    resetInitalStateCurrentAction: () => {
      return initialState;
    },
  },
});

export const {
  setPlayer,
  setActionTypeIdAndLabelsOptions,
  setType,
  setSide,
  setResult,
  setWallZone,
  setCoordinates,
  setEditMode,
  setEditModeReceptionPositive,
  setSetsGame,
  resetPanelActionsState,
  resetInitalStateCurrentAction,
} = currentActionSlice.actions;
export default currentActionSlice.reducer;
