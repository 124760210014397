import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import "./PieChart.scss";

function PieChartGraph({
  categories,
  values,
  colors,
  height,
}: {
  categories: Array<string>;
  values: Array<number>;
  colors: Array<string>;
  height: number;
}) {
  const data: Array<any> = [];
  categories.map((category: string, index: number) => {
    data.push({ name: category, y: values[index] });
  });
  const options = {
    chart: {
      type: "pie",
      height: height,
      backgroundColor: "transparent",
      color: "white",
    },
    title: {
      text: "",
    },
    credits: { enabled: false },
    exporting: {
      buttons: {
        contextButton: {
          symbolStroke: "transparent",
          theme: {
            fill: "transparent",
          },
        },
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          style: {
            color: "white",
            "font-size": "0.8em",
          },
          format: "<b>{point.name}</b><br>{point.percentage:.0f} %",
          distance: -20,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        data: data,
        colors: colors,
      },
    ],
  };
  return (
    <div style={{ width: "100%", height: height }}>
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
}

export default PieChartGraph;
