import { useQuery } from "@tanstack/react-query";

import ActionTypesApi from "@pelote/api/ActionTypesApi";
import { ActionType } from "@pelote/types/ActionType";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Label } from "@pelote/types/Label";
import { Option } from "@pelote/types/Option";

interface usePaginatedActionTypesOptions {
  page?: number;
  pageSize?: number;
  sportId?: string;
  skip?: boolean;
}

interface AutomatizationLabels {
  labelTypeReceptionResult?: Label;
  labelTypeReception?: Label;
  labelTypeShot?: Label;
  labelResultShot?: Label;
  labelWallZoneShot?: Label;
}

interface AutomatizationOptions {
  optionPositiveTypeReceptionResult: Option | undefined;
  optionNegativeTypeReceptionResult: Option | undefined;
  optionTypeBote?: Option;
  optionTypeAire?: Option;
  optionTypeShot?: Option;
  optionWallZoneLeftShot?: Option;
  optionResultNeutralShot?: Option;
}

function usePaginatedActionTypes(options: Partial<usePaginatedActionTypesOptions> = {}): {
  paginatedActionTypes: PaginatedModel<ActionType> | undefined;
  isLoading: boolean;
  isError: boolean;
  service: ActionType | undefined;
  reception: ActionType | undefined;
  shot: ActionType | undefined;
  typeLabel: Label | undefined;
  serviceLabels: Label[] | undefined;
  receptionLabels: Label[] | undefined;
  shotLabels: Label[] | undefined;
  idActionTypeShot: string | undefined;
  automatizationLabels: AutomatizationLabels;
  automatizationOptions: AutomatizationOptions;
} {
  const { page = 1, pageSize = 10, sportId, skip = false } = options;

  // Fetch a list of action types based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexActionTypes", page, pageSize, sportId],
    queryFn: () => ActionTypesApi.index(page, pageSize, sportId),
    enabled: !skip,
  });
  const service = data?.items.find((item) => item.name === "service");
  const reception = data?.items.find((item) => item.name === "reception");
  const shot = data?.items.find((item) => item.name === "shot");
  const typeLabel = data?.items[0].labels.find((label) => label.name === "type_service");
  //Here we have all labels from DB for each action type
  const serviceLabels = service?.labels;
  const receptionLabels = reception?.labels;
  const shotLabels = shot?.labels;

  // Must get option Positive of result_reception in order to post an shot action and reception action
  const labelTypeReceptionResult = receptionLabels?.find(
    (label) => label.name === "result_reception",
  );
  const optionPositiveTypeReceptionResult = labelTypeReceptionResult?.options.find(
    (item) => item.name === "positive",
  );
  const optionNegativeTypeReceptionResult = labelTypeReceptionResult?.options.find(
    (item) => item.name === "negative",
  );
  const idActionTypeShot = shot?.id;

  // Labels Types Reception and options for bote and aire
  const labelTypeReception = receptionLabels?.find((label) => label.name === "type_reception");
  const optionTypeBote = labelTypeReception?.options.find((item) => item.name === "bote");
  const optionTypeAire = labelTypeReception?.options.find((item) => item.name === "aire");

  // Labels Types Shot and options for classic
  const labelTypeShot = shotLabels?.find((label) => label.name === "type_shot");
  const optionTypeShot = labelTypeShot?.options.find((item) => item.name === "classic");
  // Labels Wall Zone Shot and options for left
  const labelWallZoneShot = shotLabels?.find((label) => label.name === "wall_zone");
  const optionWallZoneLeftShot = labelWallZoneShot?.options.find((item) => item.name === "left");
  // Labels Result Shot and options for neutral
  const labelResultShot = shotLabels?.find((label) => label.name === "result_shot");
  const optionResultNeutralShot = labelResultShot?.options.find((item) => item.name === "neutral");

  const automatizationLabels: AutomatizationLabels = {
    labelTypeReceptionResult,
    labelTypeReception,
    labelTypeShot,
    labelResultShot,
    labelWallZoneShot,
  };

  const automatizationOptions: AutomatizationOptions = {
    optionPositiveTypeReceptionResult,
    optionNegativeTypeReceptionResult,
    optionTypeBote,
    optionTypeAire,
    optionTypeShot,
    optionWallZoneLeftShot,
    optionResultNeutralShot,
  };

  return {
    paginatedActionTypes: data,
    service,
    reception,
    shot,
    serviceLabels,
    receptionLabels,
    shotLabels,
    idActionTypeShot,
    automatizationLabels,
    automatizationOptions,
    isLoading,
    isError,
    typeLabel,
  };
}

export default usePaginatedActionTypes;
