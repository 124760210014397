import { Query } from "@tanstack/react-query";

const cacheKeyBuilder = {
  // Domain
  domainsPaginated: (page?: number, pageSize?: number, sortBy?: string) => ["domains", page, pageSize, sortBy],

  // Sport
  sports: () => ["sports"],

  // Permission
  permissionsPaginated: (domain_ids?: string[], page?: number, pageSize?: number, sortBy?: string) => ["permissions", domain_ids, page, pageSize, sortBy],

  // ApiRoute
  apiRoute: (apiRouteId?: string) => ["apiRoute", apiRouteId],

  apiRoutes: () => ["apiRoutes"],
  apiRoutesPaginated: (page?: number, pageSize?: number, sortBy?: string, domainId?: string, method?: string, path?: string) => ["apiRoutes", page, pageSize, sortBy, domainId, method, path],

  // Organization
  organizationDetails: (organizationId?: string) => ["organization", organizationId],
  organizationDomains: (organizationId?: string) => ["organization", organizationId, "domains"],
  organizationSports: (organizationId?: string) => ["organization", organizationId, "sports"],
  organizationPermissions: (organizationId?: string) => ["organization", organizationId, "permissions"],
  organizationRolesPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "roles", page, pageSize, sortBy],
  organizationUsersPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "users", page, pageSize, sortBy],
  organizationRoutes: (organizationId?: string) => ["organization", organizationId, "routes"],
  organizationRugbyCompetitions: (organizationId?: string) => ["organization", organizationId, "rugby", "competitions"],
  organizationRugbyGames: (organizationId?: string) => ["organization", organizationId, "rugby", "games"],
  organizationSettings: (organizationId?: string) => ["organization", organizationId, "settings"],
  organizationTokenOperationsPaginated: (organizationId?: string, page?: number, pageSize?: number, sortBy?: string) => ["organization", organizationId, "tokenOperations", page, pageSize, sortBy],

  organizationsPaginated: (page?: number, pageSize?: number, sortBy?: string, name?: string, isActive?: boolean) => ["organizations", page, pageSize, sortBy, name, isActive],

  // Role
  role: (roleId?: string) => ["role", roleId],
  rolePaginatedUsers: (roleId?: string, page?: number, pageSize?: number) => ["role", roleId, "users", page, pageSize],
  rolePermissions: (roleId?: string) => ["role", roleId, "permissions"],

  paginatedRoles: (page?: number, pageSize?: number,  organizationId?: string) => ["roles", page, pageSize,  organizationId],

  // User
  user: (userId?: string) => ["user", userId],
  userMe: () => ["user", "me"],
  userSports: (userId?: string) => ["user", userId, "sports"],

  usersPaginated: (page?: number, pageSize?: number, sortBy?: string, lastName?: string, organizationName?: string) => ["users", page, pageSize, sortBy, lastName, organizationName],
};

const cacheKeyInvalidator = {
  // Domain
  domains: () => ["domains"],

  // Sport
  sports: () => ["sports"],

  // Permission
  permissions: () => ["permissions"],

  // ApiRoute
  apiRoute: (apiRouteId?: string) => ["apiRoute", apiRouteId],

  apiRoutes: () => ["apiRoutes"],

  // Organization
  organization: (organizationId?: string) => ["organization", organizationId],
  organizationDomains: (organizationId?: string) => ["organization", organizationId, "domains"],
  organizationSports: (organizationId?: string) => ["organization", organizationId, "sports"],
  organizationUsers: (organizationId?: string) => ["organization", organizationId, "users"],
  organizationPermissions: (organizationId?: string) => ["organization", organizationId, "permissions"],
  organizationRoles: (organizationId?: string) => ["organization", organizationId, "roles"],
  organizationRoutes: (organizationId?: string) => ["organization", organizationId, "routes"],
  organizationRoutesAll: (query: Query) =>
    query.queryKey[0] === "organization" &&
    query.queryKey.length >= 3 &&
    query.queryKey[2] == "routes", // Use with "predicate" in queryClient.invalidateQueries
  organizationRugbyCompetitions: (organizationId?: string) => ["organization", organizationId, "rugby", "competitions"],
  organizationRugbyGames: (organizationId?: string) => ["organization", organizationId, "rugby", "games"],
  organizationSettings: (organizationId?: string) => ["organization", organizationId, "settings"],
  organizationTokenOperations: (organizationId?: string) => ["organization", organizationId, "tokenOperations"],

  organizations: () => ["organizations"],

  // Role
  role: (roleId?: string) => ["role", roleId],
  roleUsers: (roleId?: string) => ["role", roleId, "users"],
  rolePermissions: (roleId?: string) => ["role", roleId, "permissions"],
  rolePermissionsAll: (query: Query) =>
    query.queryKey[0] === "role" &&
    query.queryKey.length >= 3 &&
    query.queryKey[2] == "permissions", // Use with "predicate" in queryClient.invalidateQueries

  roles: () => ["roles"],

  // User
  user: (userId?: string) => ["user", userId],
  userMe: () => ["user", "me"],
  userSports: (userId?: string) => ["user", userId, "sports"],
  userSportsAll: (query: Query) =>
    query.queryKey[0] === "user" &&
    query.queryKey.length >= 3 &&
    query.queryKey[2] == "sports", // Use with "predicate" in queryClient.invalidateQueries

  users: () => ["users"],
};

export { cacheKeyBuilder, cacheKeyInvalidator };
