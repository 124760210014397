import { useTranslation } from "react-i18next";

import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";

export function lateralityList() {
  const { t } = useTranslation();
  const lateralitySelectOptions = convertItemsToSelectOptions(
    [
      { id: "right", name: t("right") },
      { id: "left", name: t("left") },
    ],
    "id",
    "name",
  );
  return lateralitySelectOptions;
}
