import { Link } from "react-router-dom";

import "./HorizontalMenu.scss";

export type MenuLinksHorizontal = {
  name: string;
  path: string;
  isDisabled?: boolean;
};

const HorizontalMenu = ({
  links,
  className,
}: {
  links: MenuLinksHorizontal[];
  className?: string;
}) => {
  return (
    <div className={`${className} horizontal-menu-component`}>
      <div>
        {links.map((link, index) =>
          // Could be improved
          link.name === "" ? (
            <br key={index} />
          ) : (
            !link.isDisabled && (
              <Link key={index} to={link.path}>
                <button className="button-links-navbar" type="button">
                  {link.name.toUpperCase()}
                </button>
              </Link>
            )
          ),
        )}
      </div>
    </div>
  );
};

export default HorizontalMenu;
