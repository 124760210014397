import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Player } from "@pelote/types/Player";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminPlayerList from "./usePageAdminPlayerList";
import FormButton from "@/ui-lib/form-button/FormButton";
import Pagination from "@/ui-lib/pagination/Pagination";
import StringInput from "@/ui-lib/input/StringInput";

import "./pageAdminPlayerList.scss";

const PageAdminPlayerList = () => {
  const {
    sportPath,
    paginatedPlayers,
    handleDeletePlayer,
    setCurrentPage,
    currentPage,
    pageSize,
    searchPlayerInput,
    handleSearchChange,
    isLoading,
  } = usePageAdminPlayerList({
    pageSize: 10,
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Player>();
  const columns = [
    columlHelper.accessor("lastname", {
      cell: (info) => info.getValue(),
      header: () => t("last_name"),
    }),
    columlHelper.accessor("firstname", {
      cell: (info) => info.getValue(),
      header: () => t("firstname"),
    }),
    columlHelper.accessor("birthdate", {
      cell: (info) => info.getValue(),
      header: () => t("birthdate"),
    }),
    columlHelper.accessor("nationality", {
      cell: (info) => (info.getValue() ? t("admin.nationality." + info.getValue()) : ""),
      header: () => t("nationality"),
    }),
    columlHelper.accessor("favorite_position", {
      cell: (info) => t(info.getValue()),
      header: () => t("position"),
    }),

    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigate(`/admin/pelote/${sportPath}/players/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeletePlayer(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <>
      <AdminHeader text={t("admin.pelote.player.players")} />
      <FormButton
        labelName={t("admin.pelote.player.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/players/create`)}
      />
      <div className="search-player-input">
        <StringInput
          placeholder={t("admin.pelote.player.search")}
          value={searchPlayerInput}
          onChange={handleSearchChange}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="players-list">
          <Table columns={columns} data={paginatedPlayers?.items ?? []} hasGlobalFilter={true} />
          <div className="pagination">
            <Pagination
              totalItems={paginatedPlayers?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PageAdminPlayerList;
