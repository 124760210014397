import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { AdminTabs, AdminTabsProps } from "@/ui-lib/admin-tabs/AdminTabs";
import useRole from "@user/hooks/useRole";
import useNavigateInApp from "@/hooks/useNavigateInApp";

const LayoutAdminRoleDetails = () => {
  const { id: roleId } = useParams();
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const { role } = useRole(roleId, {
    skip: !roleId,
  });

  const handleButtonClick = (path: string) => {
    navigateTo(path);
  };

  const adminRoletabs: AdminTabsProps = {
    buttons: [
      {
        label: t("admin.user-management.role.general"),
        onClick: () => handleButtonClick(`/admin/user/roles/${role?.id}`),
        isActive:
          !location.pathname.includes("permissions") && !location.pathname.includes("users"),
      },
      {
        label: t("admin.user-management.role.permissions"),
        onClick: () => handleButtonClick(`/admin/user/roles/${role?.id}/permissions`),
        isActive: location.pathname.includes("permissions"),
      },
      {
        label: t("admin.user-management.role.users"),
        onClick: () => handleButtonClick(`/admin/user/roles/${role?.id}/users`),
        isActive: location.pathname.includes("users"),
      },
    ],
  };

  return (
    <div>
      <AdminHeader
        text={`${role?.id ? t("admin.user-management.role.role") : t("admin.user-management.role.roles")} "${role?.name}"`}
      />
      <AdminTabs buttons={adminRoletabs.buttons} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutAdminRoleDetails;
