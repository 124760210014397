import { useQuery } from "@tanstack/react-query";

import SeasonsApi from "@pelote/api/SeasonsApi";
import { Season } from "@pelote/types/Season";

function useSeason(seasonId: string): {
  season?: Season;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a season (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showSeason", seasonId],
    queryFn: () => SeasonsApi.show(seasonId),
  });

  return { season: data, isLoading, isError };
}

export default useSeason;
