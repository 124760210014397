import { Fragment, useEffect, useState } from "react";
import RadioButton from "@/ui-lib/radio-button/RadioButton";
import { useDispatch } from "react-redux";
import { setSetsGame } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import UseCurrentActionCestaPunta from "@pelote/pages/cesta-punta/user/codage/redux/hooks/currentActionCestaPuntaHook";

import "./Sets.scss";

export function Sets() {
  const labels = ["Manche 1", "Manche 2", "Manche 3"];
  const [checkedIndex, setCheckedIndex] = useState(0);
  const dispatch = useDispatch();
  const { currentAction, isEditMode, currentSet } = UseCurrentActionCestaPunta();

  const handleRadioClick = (index: number) => {
    setCheckedIndex(index);
    dispatch(setSetsGame({ setNumber: index + 1 }));
  };

  useEffect(() => {
    if (isEditMode) {
      setCheckedIndex(currentAction.set_number - 1);
    } else {
      setCheckedIndex(currentSet - 1);
    }
  }, [isEditMode, currentAction.set_number, currentSet]);

  return (
    <Fragment>
      <RadioButton labels={labels} checkedIndex={checkedIndex} onClick={handleRadioClick} />
    </Fragment>
  );
}
