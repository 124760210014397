import axios from "axios";

import { multisportsApiUrl } from "@/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Label } from "@pelote/types/Label";
import { getDefaultHeaders } from "./utils";

export interface CreateLabelInputs {
  name: string;
  sport_id: string; // UUID v4
  is_optional: boolean;
}

export interface UpdateLabelInputs {
  name: string;
  is_optional: boolean;
}

export default class LabelsApi {
  static ressource: string = "labels";

  static async create(createLabelInputs: CreateLabelInputs): Promise<Label> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createLabelInputs,
    });
    return response.data;
  }

  static async index(
    page: number = 1,
    pageSize: number = 10,
    sport_id?: string,
  ): Promise<PaginatedModel<Label>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${multisportsApiUrl}/${domain}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async show(id: string): Promise<Label> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async update(id: string, updateLabelInputs: UpdateLabelInputs): Promise<Label> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}/${id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateLabelInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async addOption(labelId: string, optionId: string): Promise<Label> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}/${labelId}/options/${optionId}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async removeOption(labelId: string, optionId: string): Promise<Label> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.ressource}/${labelId}/options/${optionId}`;
    const response = await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }
}
