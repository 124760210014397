import { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function NoElement({ message }: { message?: string }) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <h4 style={{ textAlign: "center" }}>{t(`${message}`)}</h4>
      </div>
    </Fragment>
  );
}
