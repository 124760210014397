import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type VideoPlayerState = {
  currentSpeed: number;
  isPlaying: boolean;
  isMuted: boolean;
  isCurrentTimeMounted: boolean;
};

const initialState: VideoPlayerState = {
  currentSpeed: 1,
  isPlaying: false,
  isMuted: false,
  isCurrentTimeMounted: false,
};

export const videoPlayerSlice = createSlice({
  name: "videoPlayer",
  initialState,
  reducers: {
    setIsCurrentTimeMounted(state, action: PayloadAction<{ isMounted: boolean }>) {
      state.isCurrentTimeMounted = action.payload.isMounted;
    },
    togglePlayPause(state) {
      state.isPlaying = !state.isPlaying;
    },
    decreaseSpeed(state) {
      state.currentSpeed > 0.063 ? (state.currentSpeed /= 2) : state.currentSpeed;
    },
    increaseSpeed(state) {
      state.currentSpeed > 0.063 ? (state.currentSpeed *= 2) : state.currentSpeed;
    },
    resetSpeed(state) {
      state.currentSpeed = 1;
    },
    toggleIsMuted(state) {
      state.isMuted = !state.isMuted;
    },
  },
});

export const {
  decreaseSpeed,
  increaseSpeed,
  resetSpeed,
  togglePlayPause,
  toggleIsMuted,
  setIsCurrentTimeMounted,
} = videoPlayerSlice.actions;
export default videoPlayerSlice.reducer;
