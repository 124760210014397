import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import UsersApi, { CreateUserSettingInputs } from "@user/api/UsersApi";
import { APIError } from "@user/types/ApiError";

function useUserSetting(): {
  createUserSettingAsync: (userId: string, params: CreateUserSettingInputs) => Promise<void>;
  isCreateUserSettingPending: boolean;
} {
  const { t } = useTranslation();

  const { mutateAsync: createUserSettingInAPIAsync, isPending: isCreateUserSettingPending } =
    useMutation({
      mutationFn: (inputs: { userId: string; settingInputs: CreateUserSettingInputs }) => {
        return UsersApi.createUserSetting(inputs.userId, inputs.settingInputs);
      },
      onSuccess: () => {},
      onError: (error: APIError) => {
        console.error(error);
        toast.error(t("common.oops-the-operation-failed"), { autoClose: 3000 });
      },
    });
  async function createUserSettingAsync(
    userId: string,
    settingInputs: CreateUserSettingInputs,
  ): Promise<void> {
    await createUserSettingInAPIAsync({ userId, settingInputs });
  }

  return {
    createUserSettingAsync,
    isCreateUserSettingPending,
  };
}

export default useUserSetting;
