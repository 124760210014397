import { useTranslation } from "react-i18next";

import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";

export function colorList() {
  const { t } = useTranslation();
  const colorSelectOptions = convertItemsToSelectOptions(
    [
      { id: "black", name: t("admin.color.default") },
      { id: "green", name: t("admin.color.green") },
      { id: "white", name: t("admin.color.white") },
      { id: "red", name: t("admin.color.red") },
      { id: "blue", name: t("admin.color.blue") },
      { id: "black", name: t("admin.color.black") },
      { id: "yellow", name: t("admin.color.yellow") },
      { id: "purple", name: t("admin.color.purple") },
      { id: "pink", name: t("admin.color.pink") },
    ],
    "id",
    "name",
  );
  return colorSelectOptions;
}
