export function convertISODateToDisplayFormat(dateISO: string) {
  // Create an instance of the Date class using the provided ISO date
  const date = new Date(dateISO);

  // Extract date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months start from 0, so add 1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date into a string
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}H${minutes < 10 ? "0" : ""}${minutes}`;
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year} : ${formattedTime}`;

  return formattedDate;
}
export function convertISODateToDisplayFormatDateOnly(dateISO: string) {
  // Create an instance of the Date class using the provided ISO date
  const date = new Date(dateISO);

  // Extract date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months start from 0, so add 1
  const day = date.getDate();

  // Format the date into a string
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`;

  return formattedDate;
}
