import styles from "./Loader.module.scss";

type ILoaderProps = {
  size?: "small" | "medium" | "large";
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
};

const Loader = ({ rootClassName, rootStyle, size = "medium" }: ILoaderProps) => {
  return (
    <div style={rootStyle} className={`${styles.loader} ${rootClassName ? rootClassName : ""}`}>
      <div className={`${styles.spinner} ${styles[size]}`} />
    </div>
  );
};

export default Loader;
