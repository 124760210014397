import { useQuery } from "@tanstack/react-query";

import PoolsApi from "@pelote/api/PoolsApi";
import { Pool } from "@pelote/types/Pool";

function usePool(
  seasonId: string,
  competitionId: string,
  poolId: string,
): {
  pool?: Pool;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a pool (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showPool", seasonId, competitionId, poolId],
    queryFn: () => PoolsApi.show(seasonId, competitionId, poolId),
  });

  return { pool: data, isLoading, isError };
}

export default usePool;
