import React, { Fragment, useState } from "react";

import styles from "./VerticalMenu.module.scss";

export type MenuItem = {
  key: string;
  label: string;
  icon?: string;
  isDisabled?: boolean;
  children?: MenuItem[];
  onClick?: () => void;
};

interface IVerticalMenuProps {
  items: MenuItem[];
  defaultSelectedKey?: string;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
}

function VerticalMenu({ items, defaultSelectedKey, rootClassName, rootStyle }: IVerticalMenuProps) {
  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey || "");

  const handleClick = (item: MenuItem) => {
    setSelectedKey(item.key);
    if (item.onClick) item.onClick();
  };

  return (
    <div style={rootStyle} className={`${styles.verticalMenu} ${rootClassName}`}>
      {items.map((item) => (
        <Fragment key={item.key}>
          <div
            className={`
              ${styles.menuItem} 
              ${!item.children?.some((child) => !child.isDisabled) ? styles.isDisabled : ""} 
            `}
          >
            {item.icon && <img className={styles.icon} src={item.icon} alt="category icon" />}
            <span className={styles.label}>{item.label}</span>
          </div>
          {item.children && (
            <div className={styles.subMenu}>
              {item.children.map((child) => (
                <div
                  key={child.key}
                  className={`
                    ${styles.childItem}
                    ${child.isDisabled ? styles.isDisabled : ""}
                    ${child.key === selectedKey ? styles.isActive : ""}
                    `}
                  onClick={() => handleClick(child)}
                >
                  {child.icon && <span className={styles.icon}>{child.icon}</span>}
                  <span className={styles.label}>{child.label}</span>
                </div>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

export default VerticalMenu;
