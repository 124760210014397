import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface NavigateBackOptions {
  fallbackRoute?: string;
}

function useNavigateInApp(): {
  navigateTo: (route: string) => void;
  navigateBack: (options?: Partial<NavigateBackOptions>) => void;
  getSearchParam: (name: string) => string;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);

  /**
   * navigateTo navigates to a route and saves the current route in the location state
   * @param route
   */
  function navigateTo(route: string) {
    navigate(route, {
      state: { from: location.pathname },
    });
  }

  /**
   * navigateBack navigates back to the previous page
   * or to a fallback route if the previous page is not set in the location state
   * @param options
   */
  function navigateBack(options: Partial<NavigateBackOptions> = {}) {
    const { fallbackRoute = "/" } = options;

    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      navigate(fallbackRoute);
    }
  }

  /**
   * getSeachParam returns the value of a search parameter or null if it doesn't exist
   * @param name
   * @returns string
   */
  function getSearchParam(name: string) {
    return searchParams.get(name) || "";
  }

  return {
    navigateTo,
    navigateBack,
    getSearchParam,
  };
}

export default useNavigateInApp;
