import axios from "axios";
import moment from "moment-timezone";

import { multisportsApiUrl } from "@/config";
import { domain } from "@pelote/config";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { CodingStatus, Game, GameTeamFormat } from "@pelote/types/Game";
import { Action, Coordinates, LabelIdOptionId } from "@pelote/types/Action";
import { PlayerPosition } from "../types/Player";
import { MetricsGameResponse } from "../types/MetricsGame";
import { MetricsGameTeamResponse } from "../types/MetricsGameTeam";
import { MetricsGamePlayerResponse } from "../types/MetricsGamePlayer";
import { MetricsGameActionsListResponse } from "../types/MetricsGameActionsList";
import { ValidationErrors } from "../hooks/useValidateActions";
import { getDefaultHeaders } from "./utils";

export interface CreateGameInputs {
  name: string;
  description?: string;
  start_time: Date;
  team_format: GameTeamFormat;
  coding_status?: CodingStatus;
  score_team_1?: string;
  score_team_2?: string;
  color_team_1?: string;
  color_team_2?: string;
  sport_id: string;
  season_id: string;
  competition_id: string;
  stage_id?: string;
  pool_id?: string;
  round_id: string;
  playground_id: string;
}

export interface UpdateGameInputs {
  name?: string;
  description?: string;
  start_time?: Date | string;
  team_format?: GameTeamFormat;
  coding_status?: CodingStatus;
  score_team_1?: string;
  score_team_2?: string;
  color_team_1?: string;
  color_team_2?: string;
  playground_id?: string;
}

export interface CreateOrEditGameActionInput {
  game_id: string;
  action_type_id: string;
  player_id: string;
  click_time: number;
  labels_options_ids: LabelIdOptionId[];
  coordinates: Coordinates;
  set_number: number;
}

export interface AddPlayerToGameInputs {
  player_id: string;
  team_number: number;
  position: PlayerPosition;
}

export default class GamesApi {
  static resource = "games";

  static async create(createGameInputs: CreateGameInputs): Promise<Game> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}`;
    // Convert the date to UTC before sending it to the server
    if (createGameInputs.start_time) {
      createGameInputs.start_time = new Date(moment(createGameInputs.start_time).utc().format());
    }
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createGameInputs,
    });
    return response.data;
  }

  static async index(
    name?: string,
    season_id?: string,
    competition_id?: string,
    stage_id?: string,
    pool_id?: string,
    round_id?: string,
    playground_id?: string,
    page: number = 1,
    page_size: number = 10,
    sort_by?: string,
  ): Promise<PaginatedModel<Game>> {
    const defaultHeaders = await getDefaultHeaders();
    let url = `${multisportsApiUrl}/${domain}/${this.resource}?page=${page}&page_size=${page_size}`;
    if (name) {
      url += `&name=${name}`;
    }
    if (competition_id) {
      url += `&competition_id=${competition_id}`;
    }
    if (season_id) {
      url += `&season_id=${season_id}`;
    }
    if (stage_id) {
      url += `&stage_id=${stage_id}`;
    }
    if (pool_id) {
      url += `&pool_id=${pool_id}`;
    }
    if (round_id) {
      url += `&round_id=${round_id}`;
    }
    if (playground_id) {
      url += `&playground_id=${playground_id}`;
    }
    if (sort_by) {
      url += `&sort_by=${sort_by}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    // Convert dates from UTC to local time after receiving them from the server
    response.data.items.forEach((game: { start_time: string | number | Date }) => {
      game.start_time = new Date(moment(new Date(game.start_time)).utc(true).local().format());
    });
    return response.data;
  }

  static async show(id: string): Promise<Game> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    // Convert the date from UTC to local time after receiving it from the server
    if (response.data.start_time) {
      response.data.start_time = moment(response.data.start_time)
        .utc(true)
        .local()
        .format("YYYY-MM-DDTHH:mm:ss");
    }
    return response.data;
  }

  static async update(id: string, updateGameInputs: UpdateGameInputs): Promise<Game> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${id}`;
    // Convert the date to UTC before sending it to the server
    if (updateGameInputs.start_time) {
      updateGameInputs.start_time = new Date(moment(updateGameInputs.start_time).utc().format());
    }
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: updateGameInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async createAction(
    gameId: string,
    createGameInputs: CreateOrEditGameActionInput,
  ): Promise<Action> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/actions`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: createGameInputs,
    });
    return response.data;
  }

  static async getActions(gameId: string): Promise<PaginatedModel<Action>> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/actions`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async editAction(
    gameId: string,
    actionId: string,
    editGameActionInputs: CreateOrEditGameActionInput,
  ): Promise<Action> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/actions/${actionId}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: { ...defaultHeaders },
      data: editGameActionInputs,
    });
    return response.data;
  }

  static async deleteAction(gameId: string, actionId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/actions/${actionId}`;
    await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
  }

  static async addPlayer(gameId: string, playerDataToAdd: AddPlayerToGameInputs): Promise<Game> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/players/${playerDataToAdd.player_id}`;
    const response = await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
      data: playerDataToAdd,
    });
    return response.data;
  }

  static async removePlayer(gameId: string, playerId: string): Promise<Game> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/players/${playerId}`;
    const response = await axios(url, {
      method: "DELETE",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async validateActions(gameId: string): Promise<ValidationErrors> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/actions/validation-report`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async createMetrics(gameId: string): Promise<void> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/metrics`;
    await axios(url, {
      method: "POST",
      headers: { ...defaultHeaders },
    });
  }

  static async getMetricsGame(gameId: string): Promise<MetricsGameResponse> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/metrics_game`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async getMetricsGameTeam(gameId: string): Promise<MetricsGameTeamResponse> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/metrics_game_team`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async getMetricsGamePlayer(gameId: string): Promise<MetricsGamePlayerResponse> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/metrics_game_player`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }

  static async getMetricsGameActionsList(gameId: string): Promise<MetricsGameActionsListResponse> {
    const defaultHeaders = await getDefaultHeaders();
    const url = `${multisportsApiUrl}/${domain}/${this.resource}/${gameId}/metrics_game_actions_list`;
    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });
    return response.data;
  }
}
