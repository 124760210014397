import "./buttonFavorite.scss";

export type ButtonFavoriteProps = {
  isActive: boolean;
  onClick: () => void;
  className?: string;
};

function ButtonFavorite({ isActive, onClick, className }: ButtonFavoriteProps) {
  return (
    <div className={`${className} ui-lib-button-favorite`}>
      <button className={`button ${isActive && "is-active"}`} onClick={onClick}>
        {isActive ? "⭐" : "☆"}
      </button>
    </div>
  );
}

export default ButtonFavorite;
