import { Action } from "@pelote/types/Action";
import { GamePlayer } from "@pelote/types/GamePlayer";

export function getWinningAction(action: Action, players: GamePlayer[]) {
  const player = players.find((player) => player.id === action.player_id);
  const playerName = player?.lastname + " " + player?.firstname || "";
  const isNegativeshot = action.label_option_ids.some(
    (item) =>
      item.label_id === "7df681bd-6be4-4157-abbe-2f386cc35d79" &&
      item.option_id === "cf939239-37e5-489d-8be0-a14f9946d547",
  );
  const isPositiveshot = action.label_option_ids.some(
    (item) =>
      item.label_id === "7df681bd-6be4-4157-abbe-2f386cc35d79" &&
      item.option_id === "39699f36-4fdd-478a-984c-cddfbbf779d2",
  );
  const isReceptionnegative = action.label_option_ids.some(
    (item) =>
      item.label_id === "c312590b-2358-4762-8b33-a30e10a06352" &&
      item.option_id === "cf939239-37e5-489d-8be0-a14f9946d547",
  );
  const isAce = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "dae5bfb1-cbb7-48d1-a6d2-7343d2ffcf07",
  );
  const isFalta = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "484607bd-b6be-4297-9864-a29d499c281a",
  );

  const isPasa = action.label_option_ids.some(
    (item) =>
      item.label_id === "01c1846b-bb01-420d-baa5-8c59549ce1c8" &&
      item.option_id === "513c7fc9-b0e4-4993-9d76-6dbd0b2e752f",
  );
  let winningActionSentence = "";
  if (isNegativeshot) {
    winningActionSentence = "Frappe négative " + playerName;
  } else if (isPositiveshot) {
    winningActionSentence = "Frappe Positive " + playerName;
  } else if (isReceptionnegative) {
    winningActionSentence = "Réception négative " + playerName;
  } else if (isAce) {
    winningActionSentence = "Ace " + playerName;
  } else if (isFalta) {
    winningActionSentence = "Falta " + playerName;
  } else if (isPasa) {
    winningActionSentence = "Pasa " + playerName;
  }

  const isWinningAction = winningActionSentence !== "";
  const winningAction = {
    isWinningAction: isWinningAction,
    sentence: winningActionSentence,
    winningActionId: action.id,
  };

  return winningAction;
}
