import { useTranslation } from "react-i18next";

import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";

export function genderList() {
  const { t } = useTranslation();
  const genderSelectOptions = convertItemsToSelectOptions(
    [
      { id: "male", name: t("male") },
      { id: "female", name: t("female") },
    ],
    "id",
    "name",
  );
  return genderSelectOptions;
}
