import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PoolsApi, { UpdatePoolInputs } from "@pelote/api/PoolsApi";
import usePool from "@pelote/hooks/usePool";
import { APIError } from "@pelote/types/ApiError";
import { Pool } from "@pelote/types/Pool";

function usePageAdminPoolEdit(): {
  sportPath: string | undefined;
  pool: Pool | undefined;
  handleEditPool: (updatedPool: UpdatePoolInputs) => void;
  isLoading: boolean;
} {
  // Get the pool id from the route params
  const {
    seasonId,
    competitionId,
    id: poolId,
  } = useParams<{
    seasonId: string;
    competitionId: string;
    id: string;
  }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the pool
  const { pool, isLoading: isPoolLoading } = usePool(
    seasonId || "",
    competitionId || "",
    poolId || "",
  );

  // Handle the edition of a pool
  const queryClient = useQueryClient();
  const { mutate: updatePool } = useMutation({
    mutationFn: pool
      ? (updatedPool: UpdatePoolInputs) =>
          PoolsApi.update(pool.season_id, pool.competition_id, pool.id, updatedPool)
      : undefined,

    onSuccess: (data: Pool) => {
      queryClient.invalidateQueries({
        queryKey: ["showPool", data.season_id, data.competition_id, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexPools", data.season_id, data.competition_id],
      });
      toast.success(t("admi.pool.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/pools`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditPool(updatedPool: UpdatePoolInputs) {
    if (currentSport === undefined) return;
    updatePool(updatedPool);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isPoolLoading;

  return {
    sportPath,
    pool,
    handleEditPool,
    isLoading,
  };
}

export default usePageAdminPoolEdit;
