import { useQuery } from "@tanstack/react-query";

import { Game } from "@pelote/types/Game";
import GamesApi from "@pelote/api/GamesApi";

function useGame(gameId?: string): {
  game: Game | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a competition (triggered at the opening of the hook)
  const {
    data: gameData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["showGame", gameId],
    queryFn: () => {
      if (!gameId) return;

      return GamesApi.show(gameId);
    },
    enabled: !!gameId,
  });

  return {
    game: gameData,
    isLoading,
    isError,
  };
}

export default useGame;
