import { useContext, useEffect } from "react";

import useNavigateInApp from "@/hooks/useNavigateInApp";
import userContext from "@/contexts/userContext";
import { SportKey } from "@user/types/Sport";
import { hasPermission, hasSport } from "@/auth/utils";
import { PermissionKey } from "@user/types/Permission";

const PageHome = () => {
  const { navigateTo } = useNavigateInApp();
  const {
    permissions,
    sports,
    isLoading: isUserLoading,
    currentSport,
    changeSport,
  } = useContext(userContext);

  // Until we have created a proper home page, we redirect the user to another page depending on its sports and permissions
  useEffect(() => {
    if (isUserLoading) return;

    if (
      hasSport(sports, SportKey.CESTA_PUNTA) &&
      hasPermission(permissions, [PermissionKey.FULL_ACCESS, PermissionKey.PELOTE_BASE])
    ) {
      if (!currentSport || currentSport.key !== SportKey.CESTA_PUNTA) {
        const sportCestaPunta = sports.find((sport) => sport.key === SportKey.CESTA_PUNTA);
        if (!sportCestaPunta) throw new Error("Sport CESTA_PUNTA not found");
        changeSport(sportCestaPunta.id);
      }
      navigateTo(`/cesta-punta/competitions`);
    } else if (
      hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
        PermissionKey.USER_MANAGEMENT_ORGANIZATION,
      ])
    ) {
      navigateTo(`/admin/user/organizations`);
    } else {
      navigateTo("/unauthorized");
    }
  }, [isUserLoading, permissions, sports, currentSport]);

  return null;
};

export default PageHome;
