import { FaTrophy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { getParsedScores } from "@pelote/utils/getParsedScores";
import determineWinner from "@pelote/utils/determineWinner";
import { findPlayersDouble } from "@pelote/utils/findPlayersDouble";
import { getTeamSizesAndSubstitutes } from "@pelote/utils/getTeamSizesAndSubstitutes";
import { Game } from "@pelote/types/Game";
import playerDefaultImage from "/src/assets/images/default_player_photo.png";
import ScoreDisplay from "./ScoreDisplay";
import { SetsPatterns } from "../../types/Competition";

import "./CardGame.scss";

interface PlayerDisplayDoubleProps {
  game: Game;
  isPlayerDetails?: boolean;
}

const PlayerDisplayDouble = ({ game, isPlayerDetails }: PlayerDisplayDoubleProps) => {
  const navigate = useNavigate();

  // Find the index of the players in the array of players (not substitutes)
  const { player_front_team_1, player_front_team_2, player_back_team_1, player_back_team_2 } =
    findPlayersDouble(game.players);
  // GET SUBS
  const { subs_team1, subs_team2 } = getTeamSizesAndSubstitutes(game.players);

  const scoreTeam1Splited = getParsedScores(game.score_team_1 ?? "0;0;0");
  const scoreTeam2Splited = getParsedScores(game.score_team_2 ?? "0;0;0");

  const isWinner = determineWinner(
    scoreTeam1Splited,
    scoreTeam2Splited,
    game.competition.sets_pattern ?? SetsPatterns.five,
  );

  return (
    <div className="players-score-container">
      <div className="team-1" style={{ borderRight: `15px solid ${game.color_team_1}` }}>
        <div className="winner-logo">{isWinner.isWinnerTeam1 ? <FaTrophy size={30} /> : ""}</div>
        <div className="players-picture">
          {player_front_team_1 !== undefined ? (
            <img src={player_front_team_1.photo_url || playerDefaultImage} alt="player_front" />
          ) : (
            <img alt="player_front" src={playerDefaultImage} />
          )}
          {player_back_team_1 !== undefined ? (
            <img src={player_back_team_1.photo_url || playerDefaultImage} alt="player_back" />
          ) : (
            <img alt="player_back" src={playerDefaultImage} />
          )}
        </div>
        <div className="players-name">
          <div>
            {player_front_team_1 !== undefined ? (
              <p onClick={() => navigate(`/cesta-punta/players/${player_front_team_1.id}`)}>
                {player_front_team_1.lastname}
              </p>
            ) : (
              <p>-</p>
            )}
            {player_back_team_1 !== undefined ? (
              <p onClick={() => navigate(`/cesta-punta/players/${player_back_team_1.id}`)}>
                {player_back_team_1.lastname}
              </p>
            ) : (
              <p>-</p>
            )}
            {isPlayerDetails && subs_team1 !== undefined && (
              <p
                className="substitute-name"
                onClick={() => navigate(`/cesta-punta/players/${subs_team1[0].id}`)}
              >
                ({subs_team1[0].lastname})
              </p>
            )}
            {isPlayerDetails && subs_team1 !== undefined && subs_team1.length > 1 && (
              <p
                className="substitute-name"
                onClick={() => navigate(`/cesta-punta/players/${subs_team1[1].id}`)}
              >
                ({subs_team1[1].lastname})
              </p>
            )}
          </div>
        </div>
        <ScoreDisplay score={game.score_team_1} game={game} />
      </div>
      <div className="team-2" style={{ borderRight: `15px solid ${game.color_team_2}` }}>
        <div className="winner-logo">{isWinner.isWinnerTeam2 ? <FaTrophy size={30} /> : ""}</div>
        <div className="players-picture">
          {player_front_team_2 !== undefined ? (
            <img src={player_front_team_2.photo_url || playerDefaultImage} alt="player_front" />
          ) : (
            <img alt="player_front" src={playerDefaultImage} />
          )}
          {player_back_team_2 !== undefined ? (
            <img src={player_back_team_2.photo_url || playerDefaultImage} alt="player_back" />
          ) : (
            <img alt="player_back" src={playerDefaultImage} />
          )}
        </div>
        <div className="players-name">
          {player_front_team_2 !== undefined ? (
            <p onClick={() => navigate(`/cesta-punta/players/${player_front_team_2.id}`)}>
              {player_front_team_2.lastname}
            </p>
          ) : (
            <p>-</p>
          )}
          {player_back_team_2 !== undefined ? (
            <p onClick={() => navigate(`/cesta-punta/players/${player_back_team_2.id}`)}>
              {player_back_team_2.lastname}
            </p>
          ) : (
            <p>-</p>
          )}
          {isPlayerDetails && subs_team2 !== undefined && (
            <p
              className="substitute-name"
              onClick={() => navigate(`/cesta-punta/players/${subs_team2[0].id}`)}
            >
              ({subs_team2[0].lastname})
            </p>
          )}
          {isPlayerDetails && subs_team2 !== undefined && subs_team2.length > 1 && (
            <p
              className="substitute-name"
              onClick={() => navigate(`/cesta-punta/players/${subs_team2[1].id}`)}
            >
              ({subs_team2[1].lastname})
            </p>
          )}
        </div>
        <ScoreDisplay score={game.score_team_2} game={game} />
      </div>
    </div>
  );
};

export default PlayerDisplayDouble;
