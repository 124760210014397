import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Modal from "@/ui-lib/modal/Modal";
import Table from "@/ui-lib/table/Table";
import { OrganizationRugbyGame } from "@user/types/OrganizationRugbyGame";
import { convertISODateToDisplayFormatDateOnly } from "@/utils/convertISODateToDisplayFormat";

type Props = {
  organizationGamesBought: OrganizationRugbyGame[];
  onClose?: () => void;
};

const ModalOrganizationGamesBoughtByCompetition = ({ organizationGamesBought, onClose }: Props) => {
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<OrganizationRugbyGame>();

  const columns = [
    columlHelper.accessor("organization", {
      cell: (info) => (
        <>
          <p>{info.row.original.game.competition.name}</p>
        </>
      ),
      header: () =>
        t("admin.user-management.organization-competitions.organization-competition-name"),

      size: 30,
    }),
    columlHelper.accessor("game", {
      cell: (info) => (
        <div>
          <p>{convertISODateToDisplayFormatDateOnly(info.row.original.game.date)}</p>
        </div>
      ),
      header: () => t("admin.user-management.organization-competitions.date"),
      size: 20,
    }),
    columlHelper.accessor("game", {
      cell: (info) => (
        <div>
          <p>
            {info.row.original.game.teamHome.name}VS {info.row.original.game.teamAway.name}{" "}
          </p>
        </div>
      ),
      header: () => t("admin.user-management.organization-competitions.games"),
      size: 100,
    }),
  ];

  return (
    <Modal
      title={t("admin.user-management.organization-competitions.list-of-games")}
      onClose={onClose}
    >
      <div className="modal-organization-list-of-games">
        <Table
          columns={columns}
          data={organizationGamesBought ?? []}
          hasGlobalFilter={true}
          maxHeight="60vh"
        />
      </div>
    </Modal>
  );
};

export default ModalOrganizationGamesBoughtByCompetition;
