import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import Loader from "@pelote/components/loader/Loader";

function EnsureAuth({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const { isConnected, isConnectionLoading } = useContext(userContext);

  if (isConnectionLoading) {
    return <Loader />;
  }

  if (!isConnected) {
    console.info("User is not connected, redirecting to login");
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
}

export default EnsureAuth;
