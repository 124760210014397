interface urlBuilderQueryParams {
  [key: string]: string | Array<string> | boolean | undefined;
}

/**
 * Build a URL with query parameters
 * @param baseUrl string
 * @param queryParams object (string: string) ex: { page_size: "10" }
 * @returns string URL with query parameters
 * @example
 *  buildUrlWithParams("https://example.com", { page_size: "10" })
 *  // returns "https://example.com?page_size=10"
 */
function buildUrlWithParams(baseUrl: string, queryParams: urlBuilderQueryParams) {
  const url = new URL(baseUrl);
  for (const [key, value] of Object.entries(queryParams)) {
    // Skip undefined or empty values
    if (value === undefined || (typeof value === "string" && value.trim() === "")) {
      continue;
    }

    // Handle array values
    if (Array.isArray(value)) {
      value.forEach((v) => url.searchParams.append(key, v)); // Add a query parameter for each value in the array
      continue;
    }

    // Handle boolean values
    if (typeof value === "boolean") {
      url.searchParams.append(key, value ? "true" : "false");
      continue;
    }

    // Handle string values
    url.searchParams.append(key, value);
  }
  return url.toString();
}

export { buildUrlWithParams };
