import { useContext, useEffect } from "react";

import userContext from "@/contexts/userContext";

const PageLogout = () => {
  const { logout } = useContext(userContext);

  useEffect(function triggerLogoutRedirect() {
    logout();
  }, []);

  return null;
};

export default PageLogout;
