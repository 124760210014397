import { useQuery } from "@tanstack/react-query";

import CompetitionsApi from "@/domains/rugby/api/CompetitionsApi";
import { Competition } from "@/domains/rugby/types/Competitions";
import { cacheKeyBuilder } from "@/domains/rugby/api/apiCache";

interface UseRugbyCompetitionsOptions {
  skip?: boolean; // Deactivate the hook
}

// This hooks fetch all the API routes
function useCompetitions(useRugbyCompetitionsOptions: Partial<UseRugbyCompetitionsOptions> = {}): {
  competitions: Competition[];
  isLoading: boolean;
  isError: boolean;
} {
  const { skip = false } = useRugbyCompetitionsOptions;

  // Fetch all the competitions from the API
  // This fetch is automatically triggered at startup and when any parameter changes
  const {
    data: competitions = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: cacheKeyBuilder.competitions(),
    queryFn: () => CompetitionsApi.getAll(),
    enabled: !skip,
  });

  return {
    competitions,
    isLoading,
    isError,
  };
}

export default useCompetitions;
