import { useContext } from "react";

import userContext from "@/contexts/userContext";
import { Season } from "@pelote/types/Season";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";

function usePageAdminSeasonList({ page, pageSize }: { page: number; pageSize: number }): {
  sportPath: string | undefined;
  paginatedSeasons: PaginatedModel<Season> | undefined;
  isLoading: boolean;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page,
    pageSize,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Build the global isLoading flag
  const isLoading = isUserLoading || isSeasonsLoading;

  return {
    sportPath,
    paginatedSeasons: paginatedSeasons,
    isLoading,
  };
}

export default usePageAdminSeasonList;
