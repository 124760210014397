import { useQuery } from "@tanstack/react-query";

import StagesApi from "@pelote/api/StagesApi";
import { Stage } from "@pelote/types/Stage";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePaginatedStagesOptions {
  seasonId: string;
  competitionId: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedStages(options: Partial<usePaginatedStagesOptions> = {}): {
  paginatedStages: PaginatedModel<Stage> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { seasonId = "", competitionId = "", page = 1, pageSize = 10, skip = false } = options;

  // Fetch a list of stages based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexStages", seasonId, competitionId, page, pageSize],
    queryFn: () => StagesApi.index(seasonId, competitionId, page, pageSize),
    enabled: !skip,
  });

  return { paginatedStages: data, isLoading, isError };
}

export default usePaginatedStages;
