// XCO: need to discuss the statuses (mapping with Bunnny ?)
export enum VideoProcessingStatus {
  empty = "empty",
  queued = "queued",
  processing = "processing",
  encoding = "encoding",
  finished = "finished",
  resolution_finished = "resolution_finished",
  failed = "failed",
  presigned_upload_started = "presigned_upload_started",
  presigned_upload_finished = "presigned_upload_finished",
  presigned_upload_failed = "presigned_upload_failed",
  captions_generated = "captions_generated",
  title_or_description_generated = "title_or_description_generated",
}

export interface Video {
  id: string; // UUID v4
  name: string;
  upload_uri: string;
  uri: string;
  processing_status: VideoProcessingStatus;
  processing_id: string;
  game_id: string; // UUID v4
  sport_id: string; // UUID v4
  createdAt: string;
  updatedAt: string;
  updatedBy?: string; // UUID v4
}
