import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CodingStatus, Game, GameTeamFormat } from "@pelote/types/Game";
import { GamePlayerPosition } from "@pelote/types/GamePlayer";
import { Video } from "@pelote/types/Video";
import { PlaygroundNumberAreas, PlaygroundType } from "@pelote/types/Playground";

export type GameReducer = {
  game: Game;
  videos: Video[];
};

const initialState: GameReducer = {
  game: {
    id: "",
    name: "",
    description: "",
    start_time: "",
    team_format: GameTeamFormat.double,
    score_team_1: "",
    score_team_2: "",
    color_team_1: "",
    color_team_2: "",
    coding_status: CodingStatus.todo,
    players: [
      {
        id: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        nationality: "",
        size: 0,
        weight: 0,
        favorite_position: "",
        laterality: "",
        gender: "",
        photo_url: "",
        position: GamePlayerPosition.front,
        team_number: 1,
      },
      {
        id: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        nationality: "",
        size: 0,
        weight: 0,
        favorite_position: "",
        laterality: "",
        gender: "",
        photo_url: "",
        position: GamePlayerPosition.back,
        team_number: 1,
      },
      {
        id: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        nationality: "",
        size: 0,
        weight: 0,
        favorite_position: "",
        laterality: "",
        gender: "",
        photo_url: "",
        position: GamePlayerPosition.front,
        team_number: 2,
      },
      {
        id: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        nationality: "",
        size: 0,
        weight: 0,
        favorite_position: "",
        laterality: "",
        gender: "",
        photo_url: "",
        position: GamePlayerPosition.back,
        team_number: 2,
      },
    ],
    sport_id: "",
    season: {
      id: "",
      sport_id: "",
      name: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
    competition: {
      id: "",
      name: "",
      description: "",
      logo_url: "",
      sport_id: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
    stage: {
      id: "",
      name: "",
      display_order: 0,
      sport_id: "",
      season_id: "",
      competition_id: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
    pool: {
      id: "",
      name: "",
      display_order: 0,
      sport_id: "",
      season_id: "",
      competition_id: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
    round: {
      id: "",
      name: "",
      display_order: 0,
      sport_id: "",
      season_id: "",
      competition_id: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
    playground: {
      id: "",
      name: "",
      city: "",
      type: PlaygroundType.jai_alai_54m,
      number_areas: PlaygroundNumberAreas.nine,
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
    },
  },
  videos: [],
};

export const gameSlice = createSlice({
  name: "gameReducer",
  initialState,
  reducers: {
    setGame(state, action: PayloadAction<{ game: Game }>) {
      const { game } = action.payload;
      state.game = game;
    },
    setVideos(state, action: PayloadAction<{ uriVideo: Video[] }>) {
      state.videos = action.payload.uriVideo;
    },
  },
});

export const { setGame, setVideos } = gameSlice.actions;
export default gameSlice.reducer;
