import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiSportToSport,
  getDefaultHeaders,
} from "./utils";
import { buildUrlWithParams } from "@/utils/URL";
import { Sport } from "@user/types/Sport";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { APIPaginatedModel, APISport } from "./apiTypes";

export default class SportsApi {
  static domain: string = "user";
  static resource: string = "sports";

  static async index(
    page: number = 1,
    pageSize: number = 10,
    sortBy: string = "name:asc",
  ): Promise<PaginatedModel<Sport>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      page: page.toString(),
      page_size: pageSize.toString(),
      sort_by: sortBy,
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPaginatedSports: APIPaginatedModel<APISport> = response.data;

    const paginatedSports: PaginatedModel<Sport> = {
      items: apiPaginatedSports.items.map((apiSport) => castApiSportToSport(apiSport)),
      pagination: castApiPaginationInfoToPaginationInfo(apiPaginatedSports.pagination),
    };

    return paginatedSports;
  }

  static async getAll(): Promise<Sport[]> {
    const defaultHeaders = await getDefaultHeaders();

    const url = `${multisportsApiUrl}/${this.domain}/${this.resource}/all`;

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiSports: APISport[] = response.data;

    const sports: Sport[] = apiSports.map((apiSport) => castApiSportToSport(apiSport));

    return sports;
  }
}
