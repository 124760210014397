import { useContext, useEffect, useState } from "react";

import userContext from "@/contexts/userContext";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedRounds from "@pelote/hooks/usePaginatedRounds";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Round } from "@pelote/types/Round";

function usePageAdminRoundList(): {
  sportPath: string | undefined;
  isLoading: boolean;
  seasonSelectOptions: SelectOption[];
  competitionSelectOptions: SelectOption[];
  paginatedRounds: PaginatedModel<Round> | undefined;
  handleChangeSeasonOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeCompetitionOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  const [selectedSeasonOption, setSelectedSeasonOption] = useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] = useState<SelectOption | null>(
    null,
  );

  // Fetch a list of rounds
  const { paginatedRounds } = usePaginatedRounds({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons({
    page: 1,
    pageSize: 10,
    sortBy: "name:desc",
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } = usePaginatedCompetitions({
    page: 1,
    pageSize: 10,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
  });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );

  const handleChangeSeasonOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };
  const handleChangeCompetitionOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find((option) => option.value === event.target.value) || null,
    );
  };
  // Build the global isLoading flag
  const isLoading = isUserLoading || isSeasonsLoading || isCompetitionsLoading;

  useEffect(() => {
    const initialSeasonOption = seasonSelectOptions[0] || null;
    setSelectedSeasonOption(initialSeasonOption);
    const initialCompetitionOption = competitionSelectOptions[0] || null;
    setSelectedCompetitionOption(initialCompetitionOption);
  }, [isLoading]);

  return {
    sportPath,
    isLoading,
    seasonSelectOptions,
    competitionSelectOptions,
    paginatedRounds,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}
export default usePageAdminRoundList;
