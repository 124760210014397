import { RouteObject } from "react-router-dom";

import EnsureHasPermission from "@/auth/EnsureHasPermission";
import { PermissionKey } from "@user/types/Permission";
import PageAdminOrganizationList from "@user/pages/admin/organization/PageAdminOrganizationList";
import PageAdminOrganizationCreate from "@user/pages/admin/organization/PageAdminOrganizationCreate";
import LayoutAdminOrganizationDetails from "@user/pages/admin/organization/LayoutAdminOrganizationDetails";
import PageAdminOrganizationEdit from "@user/pages/admin/organization/PageAdminOrganizationEdit";
import PageAdminOrganizationPermissions from "@user/pages/admin/organization/pageAdminOrganizationPermissions";
import PageAdminOrganizationRoles from "@user/pages/admin/organization/pageAdminOrganizationRoles";
import PageAdminOrganizationUsers from "@user/pages/admin/organization/pageAdminOrganizationUsers";
import PageAdminUserList from "@user/pages/admin/user/PageAdminUserList";
import PageAdminUserCreate from "@user/pages/admin/user/PageAdminUserCreate";
import LayoutAdminUserDetails from "@user/pages/admin/user/LayoutAdminUserDetails";
import PageAdminUserEdit from "@user/pages/admin/user/PageAdminUserEdit";
import PageAdminRoleCreate from "@user/pages/admin/role/PageAdminRoleCreate";
import PageAdminRoleEdit from "@user/pages/admin/role/PageAdminRoleEdit";
import PageAdminUserSports from "@user/pages/admin/user/PageAdminUserSports";
import PageAdminRoleList from "@user/pages/admin/role/PageAdminRoleList";
import PageAdminSportList from "@user/pages/admin/sport/PageAdminSportList";
import LayoutAdminRoleDetails from "@user/pages/admin/role/LayoutAdminRoleDetails";
import PageAdminRolePermissions from "@user/pages/admin/role/pageAdminRolePermissions";
import PageAdminRoleUsers from "@user/pages/admin/role/pageAdminRoleUsers";
import PageAdminPermissionList from "@user/pages/admin/permission/PageAdminPermissionList";
import PageAdminApiRouteList from "@user/pages/admin/api-routes/PageAdminApiRouteList";
import PageAdminApiRouteCreate from "@user/pages/admin/api-routes/PageAdminApiRouteCreate";
import PageAdminApiRouteEdit from "@user/pages/admin/api-routes/PageAdminApiRouteEdit";
import PageAdminOrganizationApiAccesses from "@user/pages/admin/organization/pageAdminOrganizationApiAccesses";
import PageAdminOrganizationCompetitions from "@user/pages/admin/organization/pageAdminOrganizationCompetitions";
import PageAdminOrganizationTokens from "@user/pages/admin/organization/pageAdminOrganizationTokens";
import PageAdminOrganizationSports from "@user/pages/admin/organization/pageAdminOrganizationSports";

const routesAdminUser: RouteObject[] = [
  {
    path: "organizations",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminOrganizationList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "organizations/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminOrganizationCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "organizations/:id",
    element: <LayoutAdminOrganizationDetails />,
    children: [
      {
        index: true,
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationEdit />
          </EnsureHasPermission>
        ),
      },
      {
        path: "sports",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationSports />
          </EnsureHasPermission>
        ),
      },
      {
        path: "permissions",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationPermissions />
          </EnsureHasPermission>
        ),
      },
      {
        path: "roles",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationRoles />
          </EnsureHasPermission>
        ),
      },
      {
        path: "users",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationUsers />
          </EnsureHasPermission>
        ),
      },
      {
        path: "api-accesses",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationApiAccesses />
          </EnsureHasPermission>
        ),
      },
      {
        path: "competitions",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationCompetitions />
          </EnsureHasPermission>
        ),
      },
      {
        path: "tokens",
        element: (
          <EnsureHasPermission
            permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
          >
            <PageAdminOrganizationTokens />
          </EnsureHasPermission>
        ),
      },
    ],
  },
  {
    path: "roles",
    element: (
      <EnsureHasPermission
        permissionKeys={[
          PermissionKey.FULL_ACCESS,
          PermissionKey.USER_MANAGEMENT_FULL,
          PermissionKey.USER_MANAGEMENT_ORGANIZATION,
        ]}
      >
        <PageAdminRoleList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "roles/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[
          PermissionKey.FULL_ACCESS,
          PermissionKey.USER_MANAGEMENT_FULL,
          PermissionKey.USER_MANAGEMENT_ORGANIZATION,
        ]}
      >
        <PageAdminRoleCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "roles/:id",
    element: <LayoutAdminRoleDetails />,
    children: [
      {
        index: true,
        element: (
          <EnsureHasPermission
            permissionKeys={[
              PermissionKey.FULL_ACCESS,
              PermissionKey.USER_MANAGEMENT_FULL,
              PermissionKey.USER_MANAGEMENT_ORGANIZATION,
            ]}
          >
            <PageAdminRoleEdit />
          </EnsureHasPermission>
        ),
      },
      {
        path: "permissions",
        element: (
          <EnsureHasPermission
            permissionKeys={[
              PermissionKey.FULL_ACCESS,
              PermissionKey.USER_MANAGEMENT_FULL,
              PermissionKey.USER_MANAGEMENT_ORGANIZATION,
            ]}
          >
            <PageAdminRolePermissions />
          </EnsureHasPermission>
        ),
      },
      {
        path: "users",
        element: (
          <EnsureHasPermission
            permissionKeys={[
              PermissionKey.FULL_ACCESS,
              PermissionKey.USER_MANAGEMENT_FULL,
              PermissionKey.USER_MANAGEMENT_ORGANIZATION,
            ]}
          >
            <PageAdminRoleUsers />
          </EnsureHasPermission>
        ),
      },
    ],
  },
  {
    path: "users",
    element: (
      <EnsureHasPermission
        permissionKeys={[
          PermissionKey.FULL_ACCESS,
          PermissionKey.USER_MANAGEMENT_FULL,
          PermissionKey.USER_MANAGEMENT_ORGANIZATION,
        ]}
      >
        <PageAdminUserList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "users/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[
          PermissionKey.FULL_ACCESS,
          PermissionKey.USER_MANAGEMENT_FULL,
          PermissionKey.USER_MANAGEMENT_ORGANIZATION,
        ]}
      >
        <PageAdminUserCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "users/:id",
    element: <LayoutAdminUserDetails />,
    children: [
      {
        index: true,
        element: (
          <EnsureHasPermission
            permissionKeys={[
              PermissionKey.FULL_ACCESS,
              PermissionKey.USER_MANAGEMENT_FULL,
              PermissionKey.USER_MANAGEMENT_ORGANIZATION,
            ]}
          >
            <PageAdminUserEdit />
          </EnsureHasPermission>
        ),
      },
      {
        path: "sports",
        element: (
          <EnsureHasPermission
            permissionKeys={[
              PermissionKey.FULL_ACCESS,
              PermissionKey.USER_MANAGEMENT_FULL,
              PermissionKey.USER_MANAGEMENT_ORGANIZATION,
            ]}
          >
            <PageAdminUserSports />
          </EnsureHasPermission>
        ),
      },
    ],
  },
  {
    path: "permissions",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminPermissionList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "sports",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminSportList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "api-routes",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminApiRouteList />
      </EnsureHasPermission>
    ),
  },
  {
    path: "api-routes/create",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminApiRouteCreate />
      </EnsureHasPermission>
    ),
  },
  {
    path: "api-routes/:id",
    element: (
      <EnsureHasPermission
        permissionKeys={[PermissionKey.FULL_ACCESS, PermissionKey.USER_MANAGEMENT_FULL]}
      >
        <PageAdminApiRouteEdit />
      </EnsureHasPermission>
    ),
  },
];

export default routesAdminUser;
