import { RootState } from "../store";
import { useAppSelector } from "../hooks";

// CHANGE THIS COMPONENT TO USE REAL DATA FROM API
const UseTeamsData = () => {
  const gameData = useAppSelector((state: RootState) => state.game);

  const colorTeam1 = gameData.game.color_team_1;
  const colorTeam2 = gameData.game.color_team_2;

  const player_front_team_1 = gameData.game.players.findIndex(
    (p) => p.position === "front" && p.team_number === 1,
  );
  const player_front_team_2 = gameData.game.players.findIndex(
    (p) => p.position === "front" && p.team_number === 2,
  );
  const player_back_team_1 = gameData.game.players.findIndex(
    (p) => p.position === "back" && p.team_number === 1,
  );
  const player_back_team_2 = gameData.game.players.findIndex(
    (p) => p.position === "back" && p.team_number === 2,
  );

  const idPlayerAvTeam1 = gameData.game.players[player_front_team_1].id;
  const idPlayerArTeam1 = gameData.game.players[player_back_team_1].id;

  const idPlayerAvTeam2 = gameData.game.players[player_front_team_2].id;
  const idPlayerArTeam2 = gameData.game.players[player_back_team_2].id;

  const avantTeam1 = gameData.game.players[player_front_team_1];
  const arriereTeam1 = gameData.game.players[player_back_team_1];

  const avantTeam2 = gameData.game.players[player_front_team_2];
  const arriereTeam2 = gameData.game.players[player_back_team_2];

  const players = [avantTeam1, arriereTeam1, avantTeam2, arriereTeam2];

  return {
    colorTeam1,
    colorTeam2,
    idPlayerAvTeam1,
    idPlayerAvTeam2,
    idPlayerArTeam1,
    idPlayerArTeam2,
    avantTeam1,
    avantTeam2,
    arriereTeam1,
    arriereTeam2,
    players,
  };
};

export default UseTeamsData;
