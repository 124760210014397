import { useQuery } from "@tanstack/react-query";

import ApiRoutesApi from "@user/api/ApiRoutesApi";
import { ApiRoute } from "@user/types/ApiRoute";
import { cacheKeyBuilder } from "../api/apiCache";

interface useApiRouteOptions {
  skip?: boolean; // Deactivate the hook
}

function useApiRoute(
  routeId?: string,
  options: Partial<useApiRouteOptions> = {},
): {
  route?: ApiRoute;
  isLoading: boolean;
  isError: boolean;
} {
  const { skip = false } = options;

  // Fetch the route
  const {
    data: route,
    isLoading: isApiRouteLoading,
    isError: isApiRouteError,
  } = useQuery({
    queryKey: cacheKeyBuilder.apiRoute(routeId),
    queryFn: () => {
      if (!routeId) return;
      return ApiRoutesApi.show(routeId);
    },
    enabled: !!routeId && !skip,
  });

  const isLoading = isApiRouteLoading;
  const isError = isApiRouteError;

  return {
    route,
    isLoading,
    isError,
  };
}

export default useApiRoute;
