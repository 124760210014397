import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Option } from "@pelote/types/Option";
import Table from "@ui-lib/table/Table";
import Pagination from "@ui-lib/pagination/Pagination";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import FormButton from "@/ui-lib/form-button/FormButton";
import usePageAdminOptionList from "./usePageAdminOptionList";

import "./pageAdminOptionList.scss";

const PageAdminOptionList = () => {
  const {
    sportPath,
    paginatedOptions,
    handleDeleteOption,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  } = usePageAdminOptionList({
    // pageSize impacts the fetch of paginated options + the pagination component
    pageSize: 10,
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Option>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigate(`/admin/pelote/${sportPath}/options/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeleteOption(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <div className="options-list">
      <AdminHeader text={t("admin.pelote.option.options")} />
      <FormButton
        labelName={t("admin.pelote.option.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/options/create`)}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="options-list">
          <Table columns={columns} data={paginatedOptions?.items ?? []} hasGlobalFilter={true} />
          <div className="pagination">
            <Pagination
              totalItems={paginatedOptions?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminOptionList;
