import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import enTranslations from "@locales/en.json";
import euTranslations from "@locales/eu.json";
import frTranslations from "@locales/fr.json";
import spTranslations from "@locales/sp.json";

i18n.use(initReactI18next).init({
  lng: "fr", // Default language
  debug: false,
  fallbackLng: "fr", // Fallback language
  resources: {
    fr: {
      translation: frTranslations,
    },
    en: {
      translation: enTranslations,
    },
    eu: {
      translation: euTranslations,
    },
    sp: {
      translation: spTranslations,
    },
  },
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
