import React from "react";
import { useTranslation } from "react-i18next";

// CSS
import "@pelote/pages/cesta-punta/gamePdf/PageGamePdf.scss";

interface GamePdfFooterProps {
  reportTitle: string;
  pageNumber: number;
}
// Create Footer for PDF
const GamePdfFooter: React.FC<GamePdfFooterProps> = ({ reportTitle, pageNumber }) => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="report-title">
        <h1>{t(reportTitle)}</h1>
      </div>
      <div className="page-number">
        <h1>{pageNumber}</h1>
      </div>
    </div>
  );
};

export default GamePdfFooter;
