import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";

function RedirectAfterLogin({ children }: { children: React.JSX.Element }) {
  const navigate = useNavigate();
  const { isConnectionLoading, loginRedirectTo, setLoginRedirectTo } = useContext(userContext);

  useEffect(
    function navigateIfNeeded() {
      if (!loginRedirectTo) return;

      console.info("Login success. Redirecting to previous page:", loginRedirectTo);
      setLoginRedirectTo("");
      navigate(loginRedirectTo, { replace: true });
    },
    [loginRedirectTo],
  );

  if (isConnectionLoading) return null;

  return children;
}

export default RedirectAfterLogin;
