import { createSlice } from "@reduxjs/toolkit";
export type Score = {
  scoreTeam1Set1: number;
  scoreTeam1Set2: number;
  scoreTeam1Set3: number;
  scoreTeam2Set1: number;
  scoreTeam2Set2: number;
  scoreTeam2Set3: number;
  pasaTeam1: number;
  pasaTeam2: number;
};

const initialState: Score = {
  scoreTeam1Set1: 0,
  scoreTeam1Set2: 0,
  scoreTeam1Set3: 0,
  scoreTeam2Set1: 0,
  scoreTeam2Set2: 0,
  scoreTeam2Set3: 0,
  pasaTeam1: 0,
  pasaTeam2: 0,
};

export const scoreSlice = createSlice({
  name: "score",
  initialState,
  reducers: {
    setScoreTeam1Set1: (state, action) => {
      state.scoreTeam1Set1 = action.payload;
    },
    setScoreTeam1Set2: (state, action) => {
      state.scoreTeam1Set2 = action.payload;
    },
    setScoreTeam1Set3: (state, action) => {
      state.scoreTeam1Set3 = action.payload;
    },
    setScoreTeam2Set1: (state, action) => {
      state.scoreTeam2Set1 = action.payload;
    },
    setScoreTeam2Set2: (state, action) => {
      state.scoreTeam2Set2 = action.payload;
    },
    setScoreTeam2Set3: (state, action) => {
      state.scoreTeam2Set3 = action.payload;
    },
    setPasaTeam1: (state, action) => {
      state.pasaTeam1 = action.payload;
    },
    setPasaTeam2: (state, action) => {
      state.pasaTeam2 = action.payload;
    },
    resetInitalScores: () => {
      return initialState;
    },
  },
});

export const {
  setScoreTeam1Set1,
  setScoreTeam1Set2,
  setScoreTeam1Set3,
  setScoreTeam2Set1,
  setScoreTeam2Set2,
  setScoreTeam2Set3,
  setPasaTeam1,
  setPasaTeam2,
  resetInitalScores,
} = scoreSlice.actions;
export default scoreSlice.reducer;
