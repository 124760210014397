import { t } from "i18next";
import { toast } from "react-toastify";

/**
 * copyToClipboard copies the given text to the clipboard
 * a toast message is displayed to the user to inform them of the result
 * @param text The text to copy to the clipboard
 * @returns void
 *
 */
function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      toast.success(t("common.text-copied-to-clipboard"), {
        autoClose: 3000,
      });
    },
    function (err) {
      console.error("Error copying to clipboard: ", err);
      toast.error(t("common.text-copied-to-clipboard-failed"), {
        autoClose: 3000,
      });
    },
  );
}

export default copyToClipboard;
