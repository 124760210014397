import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UpdateActionTypeInputs } from "@pelote/api/ActionTypesApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminActionTypeEdit from "./usePageAdminActionTypeEdit";
import Checkbox from "@/ui-lib/checkbox-pelote-action-type/CheckboxPeloteActionType";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminActionType.scss";

const PageAdminActionTypeEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    actionType,
    isLoading,
    nonAttachedLabels,
    attachedLabels,
    handleEditActionType,
    handleAddLabelToActionType,
    handleRemoveLabelFromActionType,
  } = usePageAdminActionTypeEdit();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateActionTypeInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
    },
  });

  useEffect(() => {
    setValue("name", actionType?.name || "");
    setValue("display_order", actionType?.display_order || 0);
  }, [actionType]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="action-type-edition">
      <AdminHeader text={`${actionType?.name}`} />
      <form onSubmit={handleSubmit(handleEditActionType)}>
        <div className="page-actiontype-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
          <div className="actiontype-labels">
            <div className="attached-labels">
              {attachedLabels.map((label) => (
                <Checkbox
                  key={label.value}
                  label={label.label}
                  defaultChecked={true}
                  className="label"
                  onClick={() => handleRemoveLabelFromActionType(label)}
                />
              ))}
            </div>
            <div className="non-attached-labels">
              {nonAttachedLabels.map((label) => (
                <Checkbox
                  key={label.value}
                  label={label.label}
                  className="label"
                  onClick={() => handleAddLabelToActionType(label)}
                />
              ))}
            </div>
          </div>
          <div className="page-actiontype-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/action-types`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PageAdminActionTypeEdit;
