import "./formSubmit.scss";

type FormSubmitProps = {
  value: string;
  disabled?: boolean;
};

function FormSubmit({ value, disabled = false }: FormSubmitProps) {
  return (
    <div className="form-submit">
      <input type="submit" value={value} disabled={disabled} />
    </div>
  );
}

export default FormSubmit;
