import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
// Hook
import UseCurrentActionCestaPunta from "@pelote/pages/cesta-punta/user/codage/redux/hooks/currentActionCestaPuntaHook";
import UseGameDetails from "@pelote/pages/cesta-punta/user/codage/redux/hooks/gameDetailsHook";
// Redux
import { setCoordinates } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
// Utils
import { getCanchaVariables } from "@pelote/utils/getCanchaVariables";

import "./FieldXY.scss";

export function FieldXY() {
  const dispatch = useDispatch();
  const { currentAction, isSelected } = UseCurrentActionCestaPunta();

  const { playgroundNumberAreas } = UseGameDetails();
  // Here add the get from DB via hook and pass it to the Cancha component with canchaDBSize
  const {
    playgroundNumberLines,
    cellClassArray,
    shouldApplyRowBorderRedArray,
    colIndexFalta,
    colIndexPasa,
    colIndexBut,
  } = getCanchaVariables(playgroundNumberAreas);

  const createInitialData = () =>
    Array.from({ length: 5 }, () => Array(playgroundNumberAreas).fill("empty"));
  const [cellData] = useState<string[][]>(createInitialData);

  const renderTextOverlay = (colIndex: number, rowIndex: number) => {
    if (colIndex === colIndexFalta && rowIndex === 0) {
      return "FALTA";
    } else if (colIndex === colIndexPasa && rowIndex === 0) {
      return "PASA";
    } else if (colIndex === colIndexBut && rowIndex === 3) {
      return "BUT";
    }
    return null;
  };

  const handleXYClick = (rowIndex: number, colIndex: number) => {
    const invertedColIndex = colIndex;
    const invertedRowIndex = 4 - rowIndex;
    const x = invertedColIndex;
    const y = invertedRowIndex;
    const coordinates = { x, y };
    dispatch(setCoordinates({ coordinates: coordinates }));
  };
  useEffect(() => {
    getCanchaVariables(playgroundNumberAreas);
  }, []);

  return (
    <>
      <table className="field-container">
        <tbody>
          {cellData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((_, colIndex) => {
                const cellClass = cellClassArray.includes(colIndex) ? "zone special-zone" : "zone";

                const shouldApplyRowBorderRed =
                  (rowIndex === 0 || rowIndex === 3) &&
                  !shouldApplyRowBorderRedArray.includes(colIndex);

                const rowBorderRedClass = shouldApplyRowBorderRed ? "row-border-red" : "";

                const textOverlay = renderTextOverlay(colIndex, rowIndex);

                // Afficher les indices inversés dans la première ligne sauf dans les cases PASA et FALTA
                const reversedIndex =
                  rowIndex === 0 && !(textOverlay === "FALTA" || textOverlay === "PASA")
                    ? playgroundNumberLines - colIndex
                    : colIndex;

                return (
                  <td
                    key={colIndex}
                    className={`${cellClass} ${rowBorderRedClass}`}
                    rowSpan={1}
                    onClick={() => handleXYClick(rowIndex, colIndex)}
                    style={
                      isSelected.coordinates &&
                      4 - currentAction.coordinates.y === rowIndex &&
                      currentAction.coordinates.x === colIndex
                        ? { backgroundColor: "gold" }
                        : {}
                    }
                  >
                    {rowIndex === 0 &&
                      !(textOverlay === "FALTA" || textOverlay === "PASA") &&
                      colIndex !== playgroundNumberLines && <p>{reversedIndex}</p>}
                    {textOverlay && <p className="vertical-text">{textOverlay}</p>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
