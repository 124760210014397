import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateRoundInputs } from "@pelote/api/RoundsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminRoundCreate from "./usePageAdminRoundCreate";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminRound.scss";

const PageAdminRoundCreate = () => {
  const {
    sportPath,
    handleCreateRound,
    isCreationSuccess,
    isLoading,
    seasonSelectOptions,
    competitionSelectOptions,
    stageSelectOptions,
    poolSelectOptions,
    handleChangeCompetitionOption,
    handleChangeSeasonOption,
  } = usePageAdminRoundCreate();

  // Navigate to the list of playgrounds when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess) navigate(`/admin/pelote/${sportPath}/rounds`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRoundInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
      season_id: seasonSelectOptions?.[0]?.value as string,
      competition_id: competitionSelectOptions?.[0]?.value as string,
      stage_id: "",
      pool_id: "",
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.pelote.round.creation")} />
      <form onSubmit={handleSubmit(handleCreateRound)}>
        <div className="page-round-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          {seasonSelectOptions && (
            <div>
              <FormSelect
                id="season_id"
                labelName={t("season")}
                options={seasonSelectOptions}
                textToDisplayIfError={t("admin.necessary.season")}
                errorMessage={errors.season_id?.message}
                register={register}
                onChange={handleChangeSeasonOption}
              />
            </div>
          )}
          {competitionSelectOptions && (
            <div>
              <FormSelect
                id="competition_id"
                labelName={t("competition")}
                options={competitionSelectOptions}
                textToDisplayIfError={t("admin.necessary.competition")}
                errorMessage={errors.competition_id?.message}
                register={register}
                onChange={handleChangeCompetitionOption}
              />
            </div>
          )}
          {stageSelectOptions && stageSelectOptions.length > 1 && (
            <div>
              <FormSelect
                id="stage_id"
                labelName={t("stage")}
                options={stageSelectOptions}
                register={register}
              />
            </div>
          )}
          {poolSelectOptions && poolSelectOptions.length > 1 && (
            <div>
              <FormSelect
                id="pool_id"
                labelName={t("pool")}
                options={poolSelectOptions}
                register={register}
              />
            </div>
          )}
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
          <div className="page-round-buttons">
            <FormGoBackButton
              labelName={t("admin.cancel")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/rounds`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminRoundCreate;
