import { createBrowserRouter, Outlet } from "react-router-dom";

import EnsureAuth from "@/auth/EnsureAuth";
import PageHome from "@/pages/PageHome";
import PageLogin from "@/pages/PageLogin";
import PageLogout from "@/pages/PageLogout";
import EnsureCestaPuntaSport from "@/auth/EnsureCestaPuntaSport";
import LayoutPeloteUser from "@pelote/layout/LayoutPeloteUser/LayoutPeloteUser";
import PageNotFound from "@/pages/PageNotFound";
import RedirectAfterLogin from "@/auth/RedirectAfterLogin";
import LayoutAdmin from "@/layouts/LayoutAdmin/LayoutAdmin";
import routesAdminUser from "./RoutesAdminUser";
import routesAdminPeloteCestaPunta from "./RoutesAdminPeloteCestaPunta";
import routesCestaPunta from "./RoutesCestaPunta";
import PageUnauthorized from "@/pages/PageUnauthorized";
import PageAdmin from "@/pages/PageAdmin";
import ConnectionLoader from "@/auth/ConnectionLoader";

const router = createBrowserRouter(
  [
    {
      path: "login",
      element: <PageLogin />,
    },
    {
      path: "logout",
      element: <PageLogout />,
    },
    {
      path: "/",
      element: (
        <ConnectionLoader>
          <RedirectAfterLogin>
            <EnsureAuth>
              <Outlet />
            </EnsureAuth>
          </RedirectAfterLogin>
        </ConnectionLoader>
      ),
      children: [
        {
          index: true,
          element: <PageHome />,
        },
        {
          path: "/unauthorized",
          element: <PageUnauthorized />,
        },
        {
          path: "/cesta-punta",
          element: (
            <EnsureCestaPuntaSport>
              <LayoutPeloteUser />
            </EnsureCestaPuntaSport>
          ),
          children: [...routesCestaPunta],
        },
        {
          path: "/admin",
          element: <LayoutAdmin />,
          children: [
            {
              index: true,
              element: <PageAdmin />,
            },
            {
              path: "user",
              children: [...routesAdminUser],
            },
            {
              path: "pelote/cesta-punta",
              children: [...routesAdminPeloteCestaPunta],
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ],
  {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);

export default router;
