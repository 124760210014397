import { APIGame as APIGameRugby } from "@/domains/rugby/api/apiTypes";

export interface APIPaginatedModel<T> {
  items: T[];
  pagination: APIPaginationInfo;
}

export interface APIPaginationInfo {
  page: number;
  page_size: number;
  total_items: number;
}

// List of permission keys created in database
// To be updated each time a new permission is added or removed
export enum APIPermissionKey {
  FULL_ACCESS = "FULL_ACCESS",
  USER_MANAGEMENT_FULL = "USER_MANAGEMENT_FULL",
  USER_MANAGEMENT_ORGANIZATION = "USER_MANAGEMENT_ORGANIZATION",
  PELOTE_BASE = "PELOTE_BASE",
  PELOTE_ADMINISTRATION = "PELOTE_ADMINISTRATION",
  PELOTE_ANALYSIS = "PELOTE_ANALYSIS",
  PELOTE_CODING = "PELOTE_CODING",
  RUGBY_BASE = "RUGBY_BASE",
  RUGBY_PLAYER_PREMIUM = "RUGBY_PLAYER_PREMIUM",
  RUGBY_TEAM_PREMIUM = "RUGBY_TEAM_PREMIUM",
  RUGBY_VIDEO_PREMIUM = "RUGBY_VIDEO_PREMIUM",
}

export interface APIPermission {
  id: string; // UUID
  key: APIPermissionKey;
  name: string;
  description?: string;
  domain: APIDomain;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIDomain {
  id: string; // UUID
  name: string;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export enum APISportKey {
  USER_MANAGEMENT = "USER_MANAGEMENT",
  CESTA_PUNTA = "CESTA_PUNTA",
  MAIN_NUE = "MAIN_NUE",
  RUGBY_XV = "RUGBY_XV",
  RUGBY_VII = "RUGBY_VII",
}

export enum APISportUrlSegment {
  USER_MANAGEMENT = "user-management",
  CESTA_PUNTA = "cesta-punta",
  MAIN_NUE = "main-nue",
  RUGBY_XV = "rugby-xv",
  RUGBY_VII = "rugby-vii",
}
export interface APISport {
  id: string; // UUID
  key: APISportKey;
  name: string;
  url_segment: APISportUrlSegment;
  domain: APIDomain;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export interface APIOrganization {
  id: string; // UUID
  name: string;
  number_of_tokens: number;
  api_key?: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIOrganizationApiRoute {
  id: string; // UUID
  organization: APIOrganization;
  route: APIApiRoute;
  filter?: string;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIOrganizationRugbyCompetition {
  id: string; // UUID
  organization_id: string; // UUID
  competition_id: number;
  has_full_access: boolean;
  has_dynamic_report_access: boolean;
  has_games_purchase_blocked: boolean;
  has_free_access_to_games: boolean;
  has_competition_downloads_blocked: boolean;
  has_games_downloads_blocked: boolean;
  has_free_access_to_teams: boolean;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIOrganizationRugbyGame {
  id: string; // UUID
  organization: APIOrganization;
  game: APIGameRugby;
  competition_id: number;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export enum APIOrganizationSettingEntityType {
  FAVORITE_COMPETITION = "favorite competition",
}

export interface APIOrganizationSetting {
  id: string; // UUID
  organization_id: string; // UUID
  sport_id: string; // UUID
  entity_type: APIOrganizationSettingEntityType;
  entity_id?: string;
  setting_value: string;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export interface APIRole {
  id: string; // UUID
  name: string;
  description?: string;
  organization: APIOrganization;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIRugbyGame {
  id: string; // UUID
  organization: APIOrganization;
  game?: APIGameRugby;
  competition_id: number;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export enum APITokenOperationType {
  REFILL = "REFILL",
  BUY_RUGBY_GAME = "BUY_RUGBY_GAME",
  BUY_RUGBY_PLAYER = "BUY_RUGBY_PLAYER",
  BUY_RUGBY_TEAM = "BUY_RUGBY_TEAM",
}

export interface APITokenOperation {
  id: string; // UUID
  organization: APIOrganization;
  user: APIUser;
  operation_date: string;
  operation_type: APITokenOperationType;
  entity_id?: string;
  token_variation: number;
  game_rugby?: APIGameRugby;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export interface APIUser {
  id: string; // UUID
  first_name: string;
  last_name: string;
  email: string;
  provider_id: string; // UUID
  is_active: boolean;
  welcome_email_sent_at?: string;
  organization: APIOrganization;
  role: APIRole;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}

export interface APIUserFull extends APIUser {
  permissions: APIPermission[];
  sports: APISport[];
  rugby_competitions: APIOrganizationRugbyCompetition[];
  rugby_games: APIOrganizationRugbyGame[];
}

export enum APIUserSettingEntityType {
  FAVORITE_COMPETITION = "favorite competition",
  FAVORITE_TEAM = "favorite team",
  FAVORITE_PLAYER = "favorite player",
  DISPLAY_PLAYERS_IN_DASHBOARD = "display players in dashboard",
  DISPLAY_REFEREE_IN_DASHBOARD = "display referees in dashboard",
}

export interface APIUserSetting {
  id: string; // UUID
  user_id: string; // UUID
  sport_id: string; // UUID
  entity_type: APIUserSettingEntityType;
  entity_id?: string;
  setting_value: string;
  created_at: string;
  updated_at: string;
  created_by: string; // UUID
  updated_by: string; // UUID
}

export enum APIHttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface APIApiRoute {
  id: string; // UUID
  domain: APIDomain;
  method: APIHttpMethod;
  path: string;
  description?: string;
  created_at: string;
  updated_at: string;
  created_by?: string; // UUID
  updated_by?: string; // UUID
}
