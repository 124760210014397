import flagFrench from "../../../assets/images/flags/french_flag.png";
import flagSpanish from "../../../assets/images/flags/spanish_flag.png";
import flagAmerican from "../../../assets/images/flags/american_flag.png";
import flagArgentinian from "../../../assets/images/flags/argentinian_flag.png";
import flagPlanet from "../../../assets/images/flags/planet.png";

export function getFlag(nationality: string | undefined) {
  switch (nationality) {
    case "french":
      return flagFrench;
    case "spanish":
      return flagSpanish;
    case "american":
      return flagAmerican;
    case "argentinian":
      return flagArgentinian;
    default:
      return flagPlanet;
  }
}
