import { GamePlayer } from "@pelote/types/GamePlayer";

export function getTeamSizesAndSubstitutes(players: GamePlayer[]) {
  const team_1_size = players.filter((player) => player.team_number === 1).length;
  const team_2_size = players.filter((player) => player.team_number === 2).length;
  const subs_team1 =
    team_1_size > 2
      ? players.filter((p) => p.position === "substitute" && p.team_number === 1)
      : undefined;
  const subs_team2 =
    team_2_size > 2
      ? players.filter((p) => p.position === "substitute" && p.team_number === 2)
      : undefined;

  return { team_1_size, team_2_size, subs_team1, subs_team2 };
}
