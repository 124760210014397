import { useQuery } from "@tanstack/react-query";

import VideosApi from "../api/VideosApi";
import { Video } from "@pelote/types/Video";

function useVideo(videoId: string): {
  video?: Video;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch a Video (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showVideo", videoId],
    queryFn: () => VideosApi.show(videoId),
  });

  return { video: data, isLoading, isError };
}

export default useVideo;
