import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { LuFilterX } from "react-icons/lu";
import { PiEraserFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { Game } from "@pelote/types/Game";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminGameList from "./usePageAdminGameList";
import FormButton from "@/ui-lib/form-button/FormButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import StringInput from "@/ui-lib/input/StringInput";
import Pagination from "@/ui-lib/pagination/Pagination";

import "./pageAdminGameList.scss";

const PageAdminGameList = () => {
  const {
    sportPath,
    handleDeleteGame,
    seasonSelectOptions,
    selectedSeasonOption,
    competitionSelectOptions,
    selectedCompetitionOption,
    stageSelectOptions,
    selectedStageOption,
    poolSelectOptions,
    selectedPoolOption,
    roundSelectOptions,
    selectedRoundOption,
    paginatedGames,
    searchGameInput,
    pageSize,
    currentPage,
    setCurrentPage,
    handleSearchChange,
    handleChangeCompetitionOption,
    handleChangeSeasonOption,
    handleChangeStageOption,
    handleChangePoolOption,
    handleChangeRoundOption,
    handleResetFilters,
    handleResetSearchInput,
  } = usePageAdminGameList({ pageSize: 10 });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register } = useForm({
    defaultValues: {
      season_id: seasonSelectOptions?.[0]?.value,
      competition_id: competitionSelectOptions?.[0]?.value,
      stage_id: stageSelectOptions?.[0]?.value,
      pool_id: poolSelectOptions?.[0]?.value,
      round_id: roundSelectOptions?.[0]?.value,
    },
  });

  const columlHelper = createColumnHelper<Game>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("description"),
    }),
    columlHelper.accessor("start_time", {
      cell: (info) => {
        const date = new Date(info.getValue());
        return date.toLocaleDateString("fr-FR");
      },
      header: () => t("date"),
    }),
    columlHelper.accessor("coding_status", {
      cell: (info) => t(`codagePelote.status.${info.getValue()}`),
      header: () => t("codage"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() => navigate(`/admin/pelote/${sportPath}/games/${info.row.original.id}`)}
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeleteGame(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <>
      <AdminHeader text={t("admin.pelote.game.games")} />
      <FormButton
        labelName={t("admin.pelote.game.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/games/create`)}
      />
      <br />
      <div className="search-game-input">
        <StringInput
          placeholder={t("admin.pelote.game.search")}
          value={searchGameInput}
          onChange={handleSearchChange}
        />
        <button type="button" onClick={handleResetSearchInput}>
          <PiEraserFill size={25} />
        </button>
      </div>
      <div className="filters-grid">
        {seasonSelectOptions && (
          <FormSelect
            id="season_id"
            options={seasonSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeSeasonOption}
            selectedValue={selectedSeasonOption?.value.toString()}
          />
        )}
        {competitionSelectOptions && (
          <FormSelect
            id="competition_id"
            options={competitionSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeCompetitionOption}
            selectedValue={selectedCompetitionOption?.value.toString()}
          />
        )}
        {roundSelectOptions && (
          <FormSelect
            id="round_id"
            options={roundSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeRoundOption}
            selectedValue={selectedRoundOption?.value.toString()}
          />
        )}
        {stageSelectOptions && (
          <FormSelect
            id="stage_id"
            options={stageSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangeStageOption}
            selectedValue={selectedStageOption?.value.toString()}
          />
        )}
        {poolSelectOptions && (
          <FormSelect
            id="pool_id"
            options={poolSelectOptions?.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            register={register}
            onChange={handleChangePoolOption}
            selectedValue={selectedPoolOption?.value.toString()}
          />
        )}
        <div className="reset-filters">
          <button type="button" onClick={handleResetFilters}>
            <LuFilterX size={25} />
          </button>
        </div>
      </div>

      <div className="games">
        <Table columns={columns} data={paginatedGames?.items ?? []} hasGlobalFilter={true} />
        <div className="pagination">
          <Pagination
            totalItems={paginatedGames?.pagination.total_items ?? 0}
            itemsPerPage={pageSize}
            currentPage={currentPage}
            onClick={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default PageAdminGameList;
