const cacheKeyBuilder = {
  // Competitions
  competitions: () => ["competitions"],
};

const cacheKeyInvalidator = {
    // Competitions
    competitions: () => ["competitions"],
};

export { cacheKeyBuilder, cacheKeyInvalidator };
