export enum SetsPatterns {
  /* TODO: Decide about the naming of the enum values
  If more patterns come in, it could be necessary to rename those to something like "fifteen_five", "fifteen_ten" etc. 
  When adding a new Pattern, make sure to add it to the ScoreDisplay component too
  */
  five = "15;15;5",
  ten = "15;15;10",
  fifteen = "15;15;15",
  forty = "40",
}

export interface Competition {
  id: string; // UUID v4
  name: string;
  description?: string;
  logo_url?: string;
  sets_pattern?: SetsPatterns;
  sport_id: string; // UUID v4
  createdAt: string;
  updatedAt: string;
  updatedBy?: string; // UUID v4
}
