import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const generatePDF = async (reportTitle: string) => {
  // Créer une instance JsPDF
  const pdf = new jsPDF("p", "mm", "a4");

  // Sélectionner toutes les pages que vous souhaitez inclure dans le PDF
  const pages = document.querySelectorAll(".page");

  // Parcourir chaque page et générer le PDF
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i] as HTMLElement; // Ajoutez cette conversion de type

    // Utiliser html2canvas pour capturer le contenu de chaque page
    const canvas = await html2canvas(page, {
      useCORS: true,
      allowTaint: false,
      logging: true,
    });

    // Ajouter le canvas généré au PDF
    pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297);

    // Ajouter une nouvelle page au PDF si ce n'est pas la dernière page
    if (i < pages.length - 1) {
      pdf.addPage();
    }
  }

  // Télécharger le PDF
  pdf.save(`${reportTitle}.pdf`);
};

export default generatePDF;
