import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UpdateSeasonInputs } from "@pelote/api/SeasonsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminSeasonEdit from "./usePageAdminSeasonEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import DeleteButton from "@/ui-lib/delete-button/DeleteButton";

import "./PageAdminSeason.scss";

const PageAdminSeasonEdit = () => {
  const { season, handleEditSeason, isLoading, handleDeleteSeason, disableDelete } =
    usePageAdminSeasonEdit();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateSeasonInputs>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    setValue("name", season?.name || "");
  }, [season]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("season")} ${season?.name}`} />
      <form onSubmit={handleSubmit(handleEditSeason)}>
        <div className="page-season-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
        </div>
        <div className="page-season-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/seasons`)}
          />
          {season && (
            <DeleteButton
              labelName={t("admin.delete")}
              onClick={() => handleDeleteSeason(season.id)}
              disabled={disableDelete}
            />
          )}
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminSeasonEdit;
