import ReactPlayer from "react-player";
import { Fragment } from "react";

import { ScorePanel } from "@pelote/components/interface-codage/ScorePanel/ScorePanel";
import { TeamPositions } from "@pelote/components/interface-codage/TeamPosition/TeamPositions";
import { ActionsPanels } from "@pelote/components/interface-codage/ActionsPanel/ActionsPanel";
import UseTeamsData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/teamsCestaPuntaHook";
import { Sets } from "@pelote/components/interface-codage/Sets/Sets";

import "./CodagePanel.scss";

export function CodagePanel({ videoPlayerRef }: { videoPlayerRef: React.RefObject<ReactPlayer> }) {
  const { colorTeam1, colorTeam2 } = UseTeamsData();

  return (
    <Fragment>
      <div className="team-panel">
        <div className="team-positions-div">
          <TeamPositions colorTeam={colorTeam1} videoPlayerRef={videoPlayerRef} />
          <TeamPositions colorTeam={colorTeam2} videoPlayerRef={videoPlayerRef} />
        </div>
        <div className="score-panel">
          <ScorePanel />
          <Sets />
        </div>
      </div>
      <ActionsPanels />
    </Fragment>
  );
}
