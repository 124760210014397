import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateApiRouteInputs } from "@user/api/ApiRoutesApi";
import Loader from "@pelote/components/loader/Loader";
import usePageAdminApiRouteEdit from "./usePageAdminApiRouteEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";

import "./pageAdminApiRouteEdit.scss";

const PageAdminApiRouteEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { route, domainOptions, methodOptions, updateApiRoute, isLoading } =
    usePageAdminApiRouteEdit();

  // Set the form values when the api-route is fetched
  useEffect(() => {
    setValue("domainId", route?.domain.id);
    setValue("method", route?.method);
    setValue("path", route?.path);
    setValue("description", route?.description);
  }, [route]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateApiRouteInputs>({
    defaultValues: {},
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-api-route-edit">
      <AdminHeader text={`${t("Route")} ${route?.method} ${route?.path} (${route?.domain.name})`} />
      <section className="form-section">
        <form className="form" onSubmit={handleSubmit(updateApiRoute)}>
          <div className="form-fields">
            <FormSelect
              id="domainId"
              className="form-select"
              labelName={t("admin.user-management.domain.domain")}
              options={domainOptions}
              textToDisplayIfError={`${t("admin.user-management.domain.domain")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.domainId?.message}
              register={register}
            />
            <FormSelect
              id="method"
              className="form-select"
              labelName={t("admin.user-management.api-route.method")}
              options={methodOptions}
              textToDisplayIfError={`${t("admin.user-management.api-routes.method")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.method?.message}
              register={register}
            />
            <FormInput
              id="path"
              className="form-input"
              labelName={t("admin.user-management.api-route.path")}
              type={InputType.text}
              textToDisplayIfError={`${t("admin.user-management.api-route.path")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.path?.message}
              register={register}
            />
            <FormInput
              id="description"
              className="form-input"
              labelName={t("admin.user-management.api-route.description")}
              type={InputType.text}
              textToDisplayIfError={`${t("admin.user-management.api-route.description")} ${t("admin.is-mandatory")}`}
              errorMessage={errors.description?.message}
              register={register}
            />
          </div>
          <div className="form-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/user/api-routes`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </form>
      </section>
    </div>
  );
};

export default PageAdminApiRouteEdit;
