import { useContext } from "react";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import Loader from "@/ui-lib/loader/Loader";

function ConnectionLoader({ children }: { children: React.JSX.Element }) {
  const { t } = useTranslation();
  const { isConnectionLoading, isUserLoading } = useContext(userContext);

  if (isConnectionLoading || isUserLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div style={{ fontSize: "2rem", color: "white" }}>
          {t("common.connection-in-progress")}.
        </div>
        <div style={{ fontSize: "2rem", color: "white" }}>{t("common.please-wait")}...</div>
        <Loader
          size="large"
          rootStyle={{
            marginTop: "2rem",
          }}
        />
      </div>
    );
  }

  return children;
}

export default ConnectionLoader;
