import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Video, VideoProcessingStatus } from "@pelote/types/Video";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminVideoList from "./usePageAdminVideoList";
import Pagination from "@/ui-lib/pagination/Pagination";

import "./pageAdminVideoList.scss";
import { FaFileDownload } from "react-icons/fa";

const PageAdminVideoList = () => {
  const { paginatedVideos, setCurrentPage, currentPage, pageSize, isLoading } =
    usePageAdminVideoList({
      pageSize: 25,
    });
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Video>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("processing_status", {
      cell: (info) => t(info.getValue()),
      header: () => t("admin.pelote.video.status"),
    }),
    columlHelper.display({
      id: "download",
      cell: (info) =>
        info.row.original.processing_status === VideoProcessingStatus.finished && (
          <FaFileDownload
            onClick={() => {
              window.open(info.row.original.uri, "_blank");
            }}
            size={24}
            cursor="pointer"
            fill="green"
          />
        ),
      header: () => t("admin.pelote.game.video"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <AdminHeader text={t("admin.pelote.video.videos")} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="videos-list-content">
          <Table columns={columns} data={paginatedVideos?.items ?? []} hasGlobalFilter={true} />
          <div className="pagination">
            <Pagination
              totalItems={paginatedVideos?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminVideoList;
