export enum PlaygroundType {
  jai_alai_54m = "Jai alai 54m",
  mur_a_gauche_36m = "Mur à gauche 36m",
}

export enum PlaygroundNumberAreas {
  nine = 9,
  thirteen = 13,
  forteen = 14,
  fifteen = 15,
}

export interface Playground {
  id: string; // UUID v4
  name: string;
  city: string;
  type: PlaygroundType;
  number_areas: PlaygroundNumberAreas;
  createdAt: string;
  updatedAt: string;
  updatedBy?: string; // UUID v4
}
