import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UseScores from "@pelote/pages/cesta-punta/user/codage/redux/hooks/scoreHook";
import ModalValidation from "@pelote/components/interface-codage/Modal/ModalValidation";

import "./ScorePanel.scss";

export function ScorePanel() {
  const { t } = useTranslation();
  const { gameId } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function validateCodageButton() {
    if (gameId) {
      setModalIsOpen(true);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const { scores } = UseScores();

  return (
    <Fragment>
      <div className="score-panel-top-container">
        <div>
          <table className="score-table">
            <tbody>
              {/* EQUIPE 1 SCORE */}
              <tr>
                {/* MANCHE 1 */}
                <td>{scores.scoreTeam1Set1}</td>
                {/* MANCHE 2 */}
                <td>{scores.scoreTeam1Set2}</td>
                {/* MANCHE 3 */}
                <td>{scores.scoreTeam1Set3}</td>
              </tr>
              {/* EQUIPE 2 SCORE */}
              <tr>
                {/* MANCHE 1 */}
                <td>{scores.scoreTeam2Set1}</td>
                {/* MANCHE 2 */}
                <td>{scores.scoreTeam2Set2}</td>
                {/* MANCHE 3 */}
                <td>{scores.scoreTeam2Set3}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rotated-text">PASA</div>
        <div>
          <table className="score-table">
            <tbody>
              <tr>
                {/* EQUIPE 1 PASA */}
                <td>{scores.pasaTeam1}</td>
              </tr>
              <tr>
                {/* EQUIPE 2 PASA */}
                <td>{scores.pasaTeam2}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <button onClick={() => validateCodageButton()}>
            {t("valider")} <br />
            {t("codage")}
          </button>
        </div>
      </div>
      {modalIsOpen && <ModalValidation gameId={gameId as string} closeModal={closeModal} />}
    </Fragment>
  );
}
