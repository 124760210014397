import { useQuery } from "@tanstack/react-query";

import OptionsApi from "@pelote/api/OptionsApi";
import { Option } from "@pelote/types/Option";

function useOption(optionId: string): {
  option?: Option;
  isLoading: boolean;
  isError: boolean;
} {
  // Fetch an option (triggered at the opening of the hook)
  const { data, isLoading, isError } = useQuery({
    queryKey: ["showOption", optionId],
    queryFn: () => OptionsApi.show(optionId),
  });

  return { option: data, isLoading, isError };
}

export default useOption;
