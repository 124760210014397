import axios from "axios";

import { multisportsApiUrl } from "@/config";
import {
  castApiPaginationInfoToPaginationInfo,
  castApiPermissionToPermission,
  getDefaultHeaders,
} from "./utils";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Permission } from "@user/types/Permission";
import { buildUrlWithParams } from "@/utils/URL";
import { APIPermission } from "./apiTypes";

export interface IndexPermissionOptions {
  domain_ids?: string[];
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

export default class PermissionsApi {
  static domain: string = "user";
  static resource: string = "permissions";

  static async index(
    options: Partial<IndexPermissionOptions> = {},
  ): Promise<PaginatedModel<Permission>> {
    const defaultHeaders = await getDefaultHeaders();

    const baseUrl = `${multisportsApiUrl}/${this.domain}/${this.resource}`;
    const queryParams = {
      domain_ids: options.domain_ids,
      page: options.page ? options.page.toString() : "1",
      page_size: options.pageSize ? options.pageSize.toString() : "10",
      sort_by: options.sortBy ? options.sortBy : "name:asc",
    };
    const url = buildUrlWithParams(baseUrl, queryParams);

    const response = await axios(url, {
      method: "GET",
      headers: { ...defaultHeaders },
    });

    const apiPermissions: PaginatedModel<APIPermission> = response.data;

    const permissions: PaginatedModel<Permission> = {
      items: apiPermissions.items.map((apiPermission) =>
        castApiPermissionToPermission(apiPermission),
      ),
      pagination: castApiPaginationInfoToPaginationInfo(response.data.pagination),
    };

    return permissions;
  }
}
