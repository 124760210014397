import { useQuery } from "@tanstack/react-query";

import { Player } from "@pelote/types/Player";
import PlayersApi from "@pelote/api/PlayersApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface usePlayersOptions {
  page?: number;
  pageSize?: number;
  sportId?: string; // UUID v4
  skip?: boolean;
  excludeIds?: string[]; // UUID v4
  lastname?: string;
  position?: string;
}

function usePaginatedPlayers(options: Partial<usePlayersOptions> = {}): {
  paginatedPlayers: PaginatedModel<Player> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, sportId, skip, excludeIds, lastname, position } = options;

  // Fetch a list of players based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexPlayers", page, pageSize, sportId, excludeIds, lastname, position],
    queryFn: () => PlayersApi.index(page, pageSize, sportId, excludeIds, lastname, position),
    enabled: !skip,
  });

  return { paginatedPlayers: data, isLoading, isError };
}

export default usePaginatedPlayers;
