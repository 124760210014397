import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateLabelInputs } from "@pelote/api/LabelsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminLabelEdit from "./usePageAdminLabelEdit";
import Checkbox from "@/ui-lib/checkbox-pelote-action-type/CheckboxPeloteActionType";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminLabel.scss";

const PageAdminLabelEdit = () => {
  const {
    label,
    isLoading,
    nonAttachedOptions,
    attachedOptions,
    handleEditLabel,
    handleAddOptionToLabel,
    handleRemoveOptionFromLabel,
  } = usePageAdminLabelEdit();

  useEffect(() => {
    setValue("name", label?.name || "");
    setValue("is_optional", label?.is_optional as boolean);
  }, [label]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateLabelInputs>({
    defaultValues: {
      name: "",
      is_optional: true,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="label-edition">
      <AdminHeader text={`Label ${label?.name}`} />
      <form id="label-edition" onSubmit={handleSubmit(handleEditLabel)}>
        <div className="page-labels-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="is_optional"
              labelName={t("admin.optional")}
              type={InputType.checkbox}
              register={register}
            />
          </div>
          <div className="label-options">
            <div className="attached-options">
              {attachedOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  defaultChecked={true}
                  className="option"
                  onClick={() => handleRemoveOptionFromLabel(option)}
                />
              ))}
            </div>
            <div className="non-attached-options">
              {nonAttachedOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  defaultChecked={false}
                  className="option"
                  onClick={() => handleAddOptionToLabel(option)}
                />
              ))}
            </div>
          </div>
          <div className="page-labels-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/labels`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default PageAdminLabelEdit;
