import { createContext } from "react";

import { User } from "@user/types/User";
import { Sport } from "@user/types/Sport";
import { Role } from "@user/types/Role";
import { Organization } from "@user/types/Organization";
import { Permission } from "@user/types/Permission";

interface UserContext {
  user?: User;
  organization?: Organization;
  role?: Role;
  permissions: Permission[];
  sports: Sport[];
  currentSport?: Sport;
  isConnected: boolean;
  isLoading: boolean;
  isConnectionLoading: boolean;
  isUserLoading: boolean;
  isUserFetching: boolean;
  changeSport: (sporId: string) => void;
  login: (callbackUrl: string) => void;
  loginRedirectTo: string;
  setLoginRedirectTo: (redirectUrl: string) => void;
  logout: () => void;
}

const userContext = createContext<UserContext>({
  user: undefined,
  organization: undefined,
  role: undefined,
  permissions: [],
  sports: [],
  currentSport: undefined,
  isConnected: false,
  isLoading: false,
  isConnectionLoading: false,
  isUserLoading: false,
  isUserFetching: false,
  changeSport: () => null,
  login: () => null,
  loginRedirectTo: "",
  setLoginRedirectTo: () => null,
  logout: () => null,
});

export default userContext;
export type { UserContext };
