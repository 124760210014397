import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { AdminTabs, AdminTabsProps } from "@/ui-lib/admin-tabs/AdminTabs";
import userContext from "@/contexts/userContext";
import useGame from "../../hooks/useGame";

import "./layoutPeloteAdminGameDetails.scss";

const LayoutPeloteAdminGameDetails = () => {
  // Get the game id from the route params
  const { id: gameId } = useParams();

  // Get the current sport from the user context
  const { currentSport } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the game
  const { game } = useGame(gameId || "");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  const adminGametabs: AdminTabsProps = {
    buttons: [
      {
        label: t("general"),
        onClick: () => handleButtonClick(`/admin/pelote/${sportPath}/games/${game?.id}`),
        isActive: !location.pathname.includes("players") && !location.pathname.includes("videos"),
      },
      {
        label: t("players"),
        onClick: () => handleButtonClick(`/admin/pelote/${sportPath}/games/${game?.id}/players`),
        isActive: location.pathname.includes("players"),
      },
      {
        label: t("admin.pelote.video.videos"),
        onClick: () => handleButtonClick(`/admin/pelote/${sportPath}/games/${game?.id}/videos`),
        isActive: location.pathname.includes("videos"),
      },
    ],
  };

  return (
    <div>
      <AdminHeader
        text={`${game?.id ? t("admin.pelote.game.game") : t("games")} "${game?.name}"`}
      />
      <AdminTabs buttons={adminGametabs.buttons} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutPeloteAdminGameDetails;
