import { useParams } from "react-router-dom";

import useOrganization from "@user/hooks/useOrganization";
import { Sport } from "@user/types/Sport";
import useSports from "@user/hooks/useSports";

/**
 * This interface is used to extend the Sport with data from the organizationSport if any
 */
export interface ExtendedSport extends Sport {
  isAssigned: boolean;
}

function usePageAdminOrganizationSports(): {
  extendedSports: ExtendedSport[];
  addSportToOrganization: (domainId: string, sportId: string) => void;
  removeSportFromOrganization: (domainId: string, sportId: string) => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();

  // Get the organization domains, sports and utils functions
  const {
    sports: organizationSports,
    addSportToOrganization,
    removeSportFromOrganization,
    isLoading: isOrganizationLoading,
  } = useOrganization({
    organizationId,
    fetchDomains: true,
    fetchSports: true,
  });

  // Get the list of sports
  const { sports: allSports, isLoading: isAllSportsLoading } = useSports();

  // Build the extended sports
  const extendedSports = allSports
    .map((sport) => {
      const isAssigned = organizationSports.some(
        (organizationSport) => organizationSport.id === sport.id,
      );
      return { ...sport, isAssigned };
    })
    .sort((a, b) => (a.domain.name > b.domain.name || a.name > b.name ? 1 : -1));

  // Build the global isLoading flag
  const isLoading = isOrganizationLoading || isAllSportsLoading;

  return {
    extendedSports,
    addSportToOrganization,
    removeSportFromOrganization,
    isLoading,
  };
}

export default usePageAdminOrganizationSports;
