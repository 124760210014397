import { SetsPatterns } from "../../types/Competition";
import { Game } from "../../types/Game";
import { getParsedScores } from "../../utils/getParsedScores";

interface ScoreDisplayProps {
  game: Game;
  score: string | undefined;
}

const ScoreDisplay = ({ score, game }: ScoreDisplayProps) => {
  const patternToScoreType: Record<SetsPatterns, string> = {
    [SetsPatterns.five]: "0;0;0",
    [SetsPatterns.ten]: "0;0;0",
    [SetsPatterns.fifteen]: "0;0;0",
    [SetsPatterns.forty]: "0",
  };
  let formattedScore;
  if (score == undefined && game.competition.sets_pattern) {
    score = patternToScoreType[game.competition.sets_pattern];
  }
  formattedScore = getParsedScores(score || "");
  return (
    <div className="score-set-team-1">
      <p>{formattedScore[0]}</p>
      <p>{formattedScore[1]}</p>
      <p>{formattedScore[2]}</p>
    </div>
  );
};
export default ScoreDisplay;
