import { Competition } from "./Competition";
import { GamePlayer } from "./GamePlayer";
import { Playground } from "./Playground";
import { Pool } from "./Pool";
import { Round } from "./Round";
import { Season } from "./Season";
import { Stage } from "./Stage";

export enum GameTeamFormat {
  single = "single",
  double = "double",
  triple = "triple",
}

export enum CodingStatus {
  todo = "todo",
  in_progress = "in progress",
  done = "done",
}

export type Game = {
  id: string;
  name: string;
  description?: string;
  start_time: string;
  team_format: GameTeamFormat;
  score_team_1?: string;
  score_team_2?: string;
  color_team_1?: string;
  color_team_2?: string;
  sport_id: string;
  season: Season;
  competition: Competition;
  stage?: Stage;
  pool?: Pool;
  round: Round;
  playground: Playground;
  players: GamePlayer[];
  coding_status: CodingStatus;
};
