import { Player } from "../../../../../types/Player";
import { useContext, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import GamesApi, { AddPlayerToGameInputs } from "@pelote/api/GamesApi";
import useGame from "@pelote/hooks/useGame";
import { APIError } from "@pelote/types/ApiError";
import { Game, GameTeamFormat } from "@pelote/types/Game";
import usePaginatedPlayers from "@pelote/hooks/usePaginatedPlayers";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { useDebounce } from "@/hooks/useDebounce";
import { getTeamSizesAndSubstitutes } from "@/domains/pelote/utils/getTeamSizesAndSubstitutes";

function usePageAdminGameAddPlayers({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  game: Game | undefined;
  paginatedPlayers: PaginatedModel<Player> | undefined;
  handleAddPlayerToGame: (playerDataToAdd: AddPlayerToGameInputs) => void;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  searchPlayerInput: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Get the game id from the route params
  const { id: gameId } = useParams<{
    seasonId: string;
    competitionId: string;
    id: string;
  }>();

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Set the search input state - only lastname for now
  const [searchPlayerInput, setSearchPlayerInput] = useState("");
  const debouncedSearch = useDebounce(searchPlayerInput, 300);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setSearchPlayerInput(event.target.value);
  };

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Fetch the game
  const { game, isLoading: isGameLoading } = useGame(gameId || "");

  // Handle the add of a player to a game
  const queryClientAddPlayerToGame = useQueryClient();
  const { mutate: addPlayerToGame } = useMutation({
    mutationFn: game
      ? (playerToAdd: AddPlayerToGameInputs) => GamesApi.addPlayer(game.id, playerToAdd)
      : undefined,
    onSuccess: () => {
      queryClientAddPlayerToGame.refetchQueries({
        queryKey: ["showGame", gameId],
      });
      queryClientAddPlayerToGame.invalidateQueries({
        queryKey: ["indexGames"],
      });
      toast.success(t("admin.pelote.game.playerAdded"), {
        autoClose: 3000,
      });
      if (game?.team_format == GameTeamFormat.single && game?.players != undefined) {
        const { team_1_size, team_2_size } = getTeamSizesAndSubstitutes(game?.players);
        if (team_1_size + team_2_size > 0)
          navigate(`/admin/pelote/${sportPath}/games/${game.id}/players`);
      }
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.adding"), {
        autoClose: 3000,
      });
    },
  });

  // Get the players attached to the game
  const attachedPlayers = game?.players || [];
  const exclude_ids = attachedPlayers.map((player) => player.id);

  // Fetch all paginated players except the ones attached to the game (exclude_ids)
  const { paginatedPlayers, isLoading: isPlayersLoading } = usePaginatedPlayers({
    page: currentPage,
    pageSize,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
    excludeIds: exclude_ids,
    lastname: debouncedSearch,
  });

  function handleAddPlayerToGame(playerDataToAdd: AddPlayerToGameInputs) {
    addPlayerToGame(playerDataToAdd);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isGameLoading || isPlayersLoading;

  return {
    sportPath,
    game,
    paginatedPlayers,
    handleAddPlayerToGame,
    setCurrentPage,
    currentPage,
    searchPlayerInput,
    handleSearchChange,
    pageSize,
    isLoading,
  };
}

export default usePageAdminGameAddPlayers;
