import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { Player } from "@pelote/types/Player";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedPlayers from "@pelote/hooks/usePaginatedPlayers";
import PlayersApi from "@pelote/api/PlayersApi";
import { APIError } from "@pelote/types/ApiError";
import { useDebounce } from "@/hooks/useDebounce";

function usePageAdminPlayerList({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedPlayers: PaginatedModel<Player> | undefined;
  isLoading: boolean;
  handleDeletePlayer: (id: string) => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  searchPlayerInput: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.urlSegment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Set the search input state - only lastname for now
  const [searchPlayerInput, setSearchPlayerInput] = useState("");
  const debouncedSearch = useDebounce(searchPlayerInput, 300);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setSearchPlayerInput(event.target.value);
  };

  // Get the paginated players
  const { paginatedPlayers, isLoading: isPlayersLoading } = usePaginatedPlayers({
    page: currentPage,
    pageSize,
    sportId: currentSport?.id,
    skip: currentSport === undefined,
    lastname: debouncedSearch,
  });

  // Handle the deletion of a player
  const queryClient = useQueryClient();
  const { mutate: deletePlayer } = useMutation({
    mutationFn: (id: string) => PlayersApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showPlayer", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexPlayers"],
      });

      toast.success(t("admin.pelote.player.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeletePlayer(id: string) {
    if (confirm(t("admin.pelote.player.confirmDeletion"))) {
      deletePlayer(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isUserLoading || isPlayersLoading;

  return {
    sportPath,
    paginatedPlayers: paginatedPlayers,
    handleDeletePlayer,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
    searchPlayerInput,
    handleSearchChange,
  };
}

export default usePageAdminPlayerList;
